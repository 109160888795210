import { Grid, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Paymentcard from "./Paymentcard";
import axios from "axios";

const PaymentMethods = () => {
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  const [cardData, setCardData] = useState(null);
  const [loading, setLoading] = useState(true); // New state for loading
  const [deleteApiCall, setDeleteApiCall] = useState(false);

  useEffect(() => {
    setLoading(true); // Set loading to true when starting API call
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/list-payment-method/${loggedInUserId}`)
        .then((response) => {
          setCardData(response.data);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when API call is complete
        });
    });
  }, [deleteApiCall]);

  const handleEdit = () => {
    // Implement edit functionality
    console.log("Edit card");
  };

  const handleDelete = (id) => {
    setLoading(true); // Set loading to true when delete API is called
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .delete(`/api/delete-card/${loggedInUserId}/${id}`)
        .then((response) => {
          setDeleteApiCall(true);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after delete operation
        });
    });
  };

  return (
    <div>
      {loading ? ( // Show loader when loading
        <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {cardData && cardData.length > 0 ? ( // Check if there are payment methods
            <Grid container spacing={2}>
              {cardData?.map((cardItem, i) => (
                <Grid item xs={4} key={i}>
                  <Paymentcard
                    cardNumber={`**** **** **** ${cardItem?.card.last4}`}
                    cardBrand={cardItem?.card.brand}
                    expiryDate={
                      cardItem?.card?.exp_month + "/" + cardItem?.card?.exp_year
                    }
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    cardId={cardItem?.id}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
              No Payment Methods Found
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentMethods;
