import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const header=[
  {key:"Name", value:'NAME'},
  {key:"Mobile No.", value:'Mobile No.'},
  {key:"Email", value:'Email'},
  {key:"Plan Type", value:'Plan Type'},
  {key:"Transaction Id", value:'Transaction Id'},
  {key:"Message", value:'Message'},
  {key:"Expiry Date", value:'Expiry Date'},
  {key:"Refunded Amount", value:'Refunded Amount'},
  {key:"Plan Amount", value:'Plan Amount'},
]

export default function TransactionTable({tableData}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          {header?.map((data, i) => (
                <TableCell
                  // sx={{ minWidth: 230, padding: "1rem", whiteSpace: "nowrap" }}
                >
                  {data?.key}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.username}
              </TableCell>
              <TableCell align="right">{row.mobile_number}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.plan_type==1 ? 'Sponsored' : "Bulletin"}</TableCell>
              <TableCell align="right">{row.transaction_id}</TableCell>
              <TableCell align="right">{row.message}</TableCell>
              <TableCell align="right">{row.expiry_date}</TableCell>
              <TableCell align="right">{row.refund}</TableCell>
              <TableCell align="right">{row.plan_amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
