import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { MenuOutline, LockClosedOutline, AccessibilitySharp, PencilOutline, CloseSharp } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Userleftmenu from './Userleftmenu';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import DataTable from "react-data-table-component";

const ArrangeMeeting = (props) => {
	/*useEffect(()=> {
			document.body.classList.remove('disable_load_content');
    }, []);*/
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	const [updateMeetingSchedule, setUpdateMeetingSchedule] = useState(false);
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	//console.log("Lang: "+language);
	let languageContent = props.languageContent;
	/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			// console.log(response.data);
		});
		});
	}, [language]);*/
	const [meetingState, meetingSetState] = React.useState([]);
	const [isLoadingMeeting, setisLoadingMeeting] = useState(false);
	const [meetingFormErrors, setMeetingFormErrors] = useState([]);
	const [meetingPopupShow, setMeetingPopupShow] = useState(false);
	let ismeetingSubmit = false;
	
	const handleMeetingChange = ({ target })=>{
		meetingSetState({ ...meetingState, [target.name]: target.value});
	};
	const onMeetingSubmit = (e) => {
		const mData = new FormData();
		mData.append("meeting_date", meetingState.meeting_date);
		mData.append("meeting_time", meetingState.meeting_time);
		
		
		//console.log(meetingState);
			e.preventDefault();
			meetingValidate(meetingState);
			if(ismeetingSubmit){
				setisLoadingMeeting(true);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/meeting-schedule-update/${loggedInUserId}/${meetingRowId}`, mData).then(response => {
						//console.log(response.data);
						if(response.data.status==200){
							//console.log(response);
						const errors = {};
						errors.meetingError = <div className="alert alert-success alert-dismissible fade show" role="alert">
							{response.data.message}
						</div>;
						toast(response.data.message);
						setMeetingFormErrors(errors);
							//setMeetingPopupShow(false);
						}else{
							toast('Meeting not saved, Please try again');
						}
						setisLoadingMeeting(false);
					});
				});
	        }
		};
		const meetingValidate = (values) => {
			const errors = {};
			
			if (!values.meeting_date) {
				ismeetingSubmit = false;
				errors.date = "Date is required!";
			}else{
				ismeetingSubmit = true;
			}
			if (!values.meeting_time) {
				ismeetingSubmit = false;
				errors.time = "Time is required!";
			}else{
				ismeetingSubmit = true;
			}
			setMeetingFormErrors(errors);
			return ismeetingSubmit;
		}	
//Language change end
	//----------- data table start ------------
	const [search, setSearch] = React.useState('');
	const [blockUsers, setBlockUsers] = React.useState([]);
	const [filteredUsers, setFilteredUsers] = React.useState([]);
	//get all blocked users
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-arrange-metting/${loggedInUserId}`).then((response)=>{
			// console.log(response);
			setBlockUsers(response.data);
			setFilteredUsers(response.data);
			//document.body.classList.remove('disable_load_content');
		});
		});
    }, []);
	//Unblock the blocked user
	const handleCancelMeeting = (indexUser,mid) => {
		var user_id = filteredUsers[indexUser].user_id;
		//alert(indexUser);alert(mid);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/cancel-meeting/${loggedInUserId}/${mid}`).then((response)=>{
				//remove from filtered data array start
				let userRemSet = [ ...filteredUsers ];
				userRemSet.splice(indexUser, 1);
				//remove from filtered data array start
				
				//remove from main data array start
				let blockUsersRemSet = [ ...blockUsers ];
				blockUsers.map((val, i)=>{
					if(val.user_id === user_id){
						blockUsersRemSet.splice(i, 1);
					}
				})
				//remove from main data array start
				toast('You are successfully cancel the meeting');
				setBlockUsers(blockUsersRemSet);
				setFilteredUsers(userRemSet);
			});
		});
	};
	const [meetingRowId, setMeetingRowId] = React.useState([]);
	const meetingUpdate = value => {
		setMeetingRowId(value);
		setUpdateMeetingSchedule(true);
	};
	//for filter data with search box
	useEffect(()=> {
		const result = blockUsers.filter((user) => {
			return user.name.toLowerCase().match(search.toLowerCase()) || user.date.toLowerCase().match(search.toLowerCase()) || user.time.toLowerCase().match(search.toLowerCase()) || user.locations.toLowerCase().match(search.toLowerCase()) || user.contact.toLowerCase().match(search.toLowerCase());
		});
		setFilteredUsers(result);
    }, [search]);
	//datatable column
	const columns = [
		{
			name: 'Seller Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Date',
			selector: (row) => row.date,
			sortable: true,
		},
		{
			name: 'Time',
			selector: (row) => row.time,
			sortable: true,
		},
		{
			name: 'Location',
			selector: (row) => row.locations,
			sortable: true,
			width: 400,
		},
		{
			name: 'Contact',
			selector: (row) => row.contact,
			sortable: true,
		},
		{
			name: 'Cancel',
			cell: (row, i) => <span id={i} className="unBlockIcon" onClick={() => handleCancelMeeting(i,row.mid)} title="Cancel Meeting"><CloseSharp/></span>,
		},
		{
			name: 'Edit Schedule',
			cell: (row, i) => <span id={i} className="unBlockIcon" onClick={() => meetingUpdate(row.mid)} title="Schedule Update"><PencilOutline/></span>,
		},
		
		
	]
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Arrange Meeting</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}</Link>/Arrange Meeting</div>
									</div>
								</div>
							</div>
							<div className="nInfo">
								<div className="App datatable_parent_mobile_for_header">
									<DataTable
										columns={columns} 
										data={filteredUsers} 
										pagination 
										fixedHeader
										// selectableRows
										// selectableRowsHighlight
										highlightOnHover
										subHeader
										pointerOnHover
										striped
										responsive
										subHeaderComponent={
											<input 
												type="text" 
												placeholder={languageContent.search_users} 
												className="w-25 full_width_input_mobile form-control"
												value={search}
												onChange={(e) => setSearch(e.target.value)}
											/>
										}
									/>
								</div>
							{/*-----table end---*/}	
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<Modal show={updateMeetingSchedule} onHide={() => setUpdateMeetingSchedule(false)}>
			<Modal.Header closeButton>
				<Modal.Title className="w-100 text-center">Update Date And Time Meeting</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{meetingFormErrors.meetingError}
				<form id="meetingArgForm" method="post" onSubmit={onMeetingSubmit}>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label>Date</label>
								<input type="date" name="meeting_date" id="meeting_date" DatePicker className="form-control" placeholderText="Select a Date" onChange={handleMeetingChange}/>
								<div className="errorMesg">{meetingFormErrors.date}</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>Time</label>
								<input type="time" className="form-control" name="meeting_time"  id="meeting_time" placeholder="Time" onChange={handleMeetingChange}/>
								<div className="errorMesg">{meetingFormErrors.time}</div>
							</div>
						</div>
					</div>
					<div className="submitBtn"><button type="submit" className="btn" disabled={isLoadingMeeting}>{isLoadingMeeting ? <div className="btnloader"></div> : "Submit"}</button></div>
				</form>
			</Modal.Body>
		</Modal>
	</>;
}

export default ArrangeMeeting;