import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, TrashOutline, StarOutline, Star, PencilSharp, LogOutOutline,ThumbsUp, ThumbsUpOutline, MenuOutline, CloseCircleOutline } from 'react-ionicons';

import Userleftmenu from './Userleftmenu';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
//const Mybulletin = () => {
function Mybulletin(props) {
	
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	
	let languageContent = props.languageContent;
	
	/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			console.log(response.data);
		});
		});
	}, [language]);*/
	
	
	//--- get all item for userid
	const [itemList, setItemList] = useState([]);
	const [getitemsPerPage, setGetItemsPerPage] = useState();
	const [itemsPerPage, setItemsPerPage] = useState();
	const [pagiLoop, setpagiLoop] = useState();
	
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-mybulletinitems/${loggedInUserId}/${props.language}`).then((response)=>{
			// console.log(response.data);
			setItemList(response.data.itemData);
			setItemsPerPage(response.data.per_page_list);
			setGetItemsPerPage(response.data.per_page_list);
			setpagiLoop(response.data.per_page_pagination_loop);
		});
		});
	}, [props.language]);
	
	const delete_item = value => () => {
		let expvalue        = value.split("/");
		let Id              = expvalue[0];
		
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/delete-bulletin/${loggedInUserId}/${Id}`).then((response)=>{	
				//console.log(response.data);
				if(response.data.status==200){
					setItemList(response.data.itemData);
					setItemsPerPage(response.data.per_page_list);
					setGetItemsPerPage(response.data.per_page_list);
					setpagiLoop(response.data.per_page_pagination_loop);
					toast(response.data.message);
				}else{
					toast(response.data.message);
				}
			});
		 });
	};
	//console.log(itemList);
	
	//--- pagination start
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const handleShowChange = ({ target })=>{
		setItemsPerPage(target.value);
	};
	
	useEffect(() => {
		const endOffset = Number(itemOffset) + Number(itemsPerPage);
		setCurrentItems(itemList.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(itemList.length / itemsPerPage));
		
		//document.body.classList.remove('disable_load_content');
	}, [itemOffset, itemsPerPage, itemList]);
	
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % itemList.length;
		setItemOffset(newOffset);
	};
	//--- pagination end
	var lis = [];
	for (var i=1; i<pagiLoop+1; i++) {
		lis.push(<option value={getitemsPerPage*i} >{getitemsPerPage*i}</option>);
	}
	//----------------------------
	const editClassStyle = {width:'15px', height:'15px',marginRight: '5px'}
	const deleteClassStyle = {width:'20px', height:'20px',marginRight: '5px'}
	
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>My Bulletin</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}</Link>/ My Bulletin</div>
									</div>
								</div>
							</div>
							<div className="reviewPart">
								<div className="row">
								{currentItems.length ? (
									<div className="col-md-12">
										<div className="row">
										{currentItems.map((val) => {
											return <>
												<div className="col-md-4 myItemCat">
													<Link to="/">
														<div className="favItem sponsoredBadge">
															<div className="imgPar">
															<img src={process.env.REACT_APP_SERVER_API_URL +`images/bulletin/${val.id}/${val.advertisement_image}`} alt="Ferrari" />
															
															<span className="sponsored_item">AD</span>
															
															<Link to={"/edit-bulletin/" +`${val.id}`}><PencilSharp style={editClassStyle}/>Edit</Link>
															</div>
															<div className="rFav myDelete">
																<Link to="#" onClick={delete_item(`${val.id}`)}><TrashOutline style={deleteClassStyle}/>Delete</Link>
															</div>
														</div>
													</Link>
												</div>
											</>;
										})}
										</div>
										<div className="pagination">
											<div className="paginationLeft">
												<ReactPaginate
													breakLabel="..."
													nextLabel="next"
													onPageChange={handlePageClick}
													pageRangeDisplayed={2}
													pageCount={pageCount}
													previousLabel="prev"
													nextClassName={""}
													renderOnZeroPageCount={null}
													activeClassName={"active"}
													previousClassName={""}
												/>
											</div>
											<div className="showRight">
												<div className="form-group mySelect"><small>show</small>
													<select className="form-control" onChange={handleShowChange}>
														{lis} 
													</select>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className="noRecord">
										<h6>No Record Found</h6>
										<Link to="/add-bulletin">Add Bulletin</Link>
									</div>
								)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Mybulletin;