import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { ThumbsUpOutline, HeartOutline, GridOutline, ListOutline, Location, OptionsSharp, MenuOutline, CloseCircleOutline } from 'react-ionicons';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

const Separator = styled('div')(
	({ theme }) => `
	height: ${theme.spacing(3)};
`,
);
const marks = [
  {
	value: 0,
    label: '0km',
  },
  {
    value: 20,
    label: '20km',
  },
  {
    value: 50,
    label: '50km',
  },
  {
    value: 100,
    label: '100km',
  },
];
function valuetext(value) {
	return `${value}°C`;
}

const Categorilist = () => {
	/*useEffect(()=> {
		document.body.classList.add('disable_load_content');
	}, []);*/
	
	const [value, setValue] = React.useState([0, 55]);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	const [show, setShow] = React.useState();
	
	return <>
		<section className="page-title">
			<div className="container">
				<div className="row">
					<h1>Category List</h1>
					<div className="breadcrumb"><Link to="/">Home  </Link>/ Category List</div>
				</div>
			</div>
		</section>
		<section>
			<div className="products-section-innerpage">
				<div className="container cListContainer">
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 categoris-column categoriesLeft" style={{display: isOpen ? 'inline' : 'none'}}>
							<div className="cListClose"><CloseCircleOutline onClick={onClickMenu} /></div>
						    <h6 className="filter_title"><OptionsSharp /> Select Filter</h6>
							<div className="form-group mySelect">
								<label className="form-label">Location</label>
								<select className="form-control">
									<option value="0" selected>Please Select</option>
									<option value="1" >Kolkata</option>
									<option value="2" >London</option>
									<option value="4" >America</option>
								</select>
							</div>
							<div className="priceRange">
								<h6>Price Range</h6>
								<p>Choose a range below</p>
								<Box sx={{ width: 211 }}>
								<Slider
									getAriaLabel={() => 'Temperature range'}
									value={value}
									onChange={handleChange}
									valueLabelDisplay="auto"
									getAriaValueText={valuetext}
								/>
								</Box>
							</div>
							<div className="brandPart">
								<h6>Brand</h6>
								<p>Popular Brands</p>
								<div className="brandType">
									<div>
										<label className="myCheckbox">iPhone (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Samsung (2,022)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Vivo (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Mi (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Oppo (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Realme (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Techno (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">BlackBerry (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Google Pixel (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Sony (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">HTC (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Honor (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Huawei (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Infinix (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Intex (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Karbonn (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Lava (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Lenovo (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div>
										<label className="myCheckbox">Sony (6,655)
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
							</div>
							<div className="distanceRange">								
								<Box sx={{ width: 211 }}>
									<Separator />
									<Typography id="track-false-range-slider" gutterBottom>
										<h6>Distance</h6>
									</Typography>
									<Slider
										track={false}
										aria-labelledby="track-false-range-slider"
										getAriaValueText={valuetext}
										defaultValue={[0, 50]}
										marks={marks}
									/>
								</Box>
							</div>
						</div>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 products-column categoriesRight">
							<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
							<div className="row">
								<div className="col-lg-10 catSort">
									<div className="sortPrice">
										<div className="form-group mySelect">
											<label className="form-label">Sort By</label>
											<select className="form-control">
												<option value="0">Date Published</option>
												<option value="1" >Kolkata</option>
												<option value="2" >London</option>
												<option value="4" >America</option>
											</select>
										</div>
										<div className="form-group mySelect">
											<label className="form-label priceLabel">Price</label>
											<select className="form-control">
												<option value="0">Price Low to High</option>
												<option value="1" >Kolkata</option>
												<option value="2" >London</option>
												<option value="4" >America</option>
											</select>
										</div>
									</div>
								</div>
								<div className="col-lg-2 catView">
									<div className="btn-group d-none-mob list-grid viewOnly">
										<small>View</small>
										<Link to="#" id="grid"><GridOutline className="md hydrated" onClick={() => setShow(false)}/></Link>
										<Link to="#" id="list"><ListOutline className="md hydrated" onClick={() => setShow(true)}/></Link>
									</div>
								</div>
							</div>
							<div className={`row grid-container ${show ? "listview" : ""}`}>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
											<Link to="/category-details">
											<img src="./upload/items/item_1621332067_3.jpg" alt="Ferrari" />
											</Link>
										</div>								
										<div className="product-info">
											<div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
											</div>
											<p className="product-price">$ 25</p>
											<h3 className="product-title">
												<Link to="item/Ferrari.html">
												Ferrari</Link>
											</h3>
											<ul>
												<li>
													Appliances					
												</li>
											</ul>
											<p className="location d-flex">
												<Location />
												<span className="location-text-limit"> Mohali, Punjab, India </span>
											</p>
										</div>
										
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/cat.html">
										   <img src="./upload/items/item_1621331936_3.jpg" alt="Cat" />
										   </Link>
										</div>
										<div className="product-info">
											<div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
											</div>
											<p className="product-price">$ 3885</p>
											<h3 className="product-title">
												<Link to="item/cat.html">
												Cat</Link>
											</h3>
											<ul>
												<li>
												 Pet					
												</li>
										   </ul>
										   <p className="location d-flex">
												<Location />
												<span className="location-text-limit"> Mohammed Ali Road
												Mohammed Ali Rd, Mumbadevi Area, Bhuleshwar, Mumbai, Maharashtra, India </span>
										   </p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/cow.html">
										   <img src="./upload/items/item_1621332176_2.jpg" alt="Cow" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
											<p className="product-price">$ 454646</p>
											<h3 className="product-title">
												<Link to="item/cow.html">
												Cow</Link>
											</h3>
											<ul>
												<li>
													Pet					
												</li>
											</ul>
											<p className="location d-flex">
												<Location />
												<span className="location-text-limit"> Mohamed Sultan Road
												Mohamed Sultan Rd, Singapore </span>
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/now.html">
										   <img src="./upload/items/item_1621331936_4.jpg" alt="Now" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 4644</p>
										   <h3 className="product-title">
											  <Link to="item/now.html">
											  Now</Link>
										   </h3>
										   <ul>
											  <li>
												 Pet					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Mohammed Ali Road
											  Mohammed Ali Rd, Mumbadevi Area, Bhuleshwar, Mumbai, Maharashtra, India </span>
										   </p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/3imgs.html">
										   <img src="./upload/items/item_1621332116_3.jpg" alt="3imgs" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 2100</p>
										   <h3 className="product-title">
											  <Link to="item/3imgs.html">
											  3imgs</Link>
										   </h3>
										   <ul>
											  <li>
												 Pet					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Noah Close
											  Noah Cl, Mornington VIC 3931, Australia </span>
										   </p>
										</div>
									 </div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/one.html">
										   <img src="./upload/items/item_1621332116_5.jpg" alt="One" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 564</p>
										   <h3 className="product-title">
											  <Link to="item/one.html">
											  One</Link>
										   </h3>
										   <ul>
											  <li>
												 Pet					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Jalan Mohammad Toha
											  Jl. Moh. Toha, Karasak, Kec. Astanaanyar, Kota Bandung, Jawa Barat, Indonesia </span>
										   </p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/pok.html">
										   <img src="./upload/items/item_1621332067_5.jpg" alt="Pok" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 3000</p>
										   <h3 className="product-title">
											  <Link to="item/pok.html">
											  Pok</Link>
										   </h3>
										   <ul>
											  <li>
												 Pet					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Mountain Street
											  Mountain St, Ultimo NSW 2007, Australia </span>
										   </p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/three.html">
										   <img src="./upload/items/item_1621332116_1.jpg" alt="Three" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 999</p>
										   <h3 className="product-title">
											  <Link to="item/three.html">
											  Three</Link>
										   </h3>
										   <ul>
											  <li>
												 Pet					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Mohammed Ali Road
											  Mohammed Ali Rd, Mumbadevi Area, Bhuleshwar, Mumbai, Maharashtra, India </span>
										   </p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/goat.html">
										   <img src="./upload/items/item_1621332116_4.jpg" alt="Goat" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 2000</p>
										   <h3 className="product-title">
											  <Link to="item/goat.html">
											  Goat</Link>
										   </h3>
										   <ul>
											  <li>
												 Pet					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Mohali Stadium Road
											  Mohali Stadium Rd, Phase 3B-1, Sector 60, Sahibzada Ajit Singh Nagar, Punjab, India </span>
										   </p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/Audi-1621331936.html">
										   <img src="./upload/items/item_1621332067_2.jpg" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 10</p>
										   <h3 className="product-title">
											  <Link to="item/Audi-1621331936.html">
											  Audi</Link>
										   </h3>
										   <ul>
											  <li>
												 Car & Truck					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Pehowa, Haryana, India </span>
										   </p>
										</div>
									 </div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/C300.html">
										   <img src="./upload/items/item_1621331936_1.jpg" alt="C300" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 15</p>
										   <h3 className="product-title">
											  <Link to="item/C300.html">
											  C300</Link>
										   </h3>
										   <ul>
											  <li>
												 Car & Truck					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Pehowa, Haryana, India </span>
										   </p>
										</div>
									 </div>
								</div>
								<div className="col-md-4">
									<div className="product-card">
										<div className="product-image">
										   <Link to="item/Cayman.html">
										   <img src="./upload/items/item_1621332176_1.jpg" alt="Cayman" />
										   </Link>
										</div>
										<div className="product-info">
										   <div className="like-fav d-flex">
												<Link to="#" className="like-save"><ThumbsUpOutline /></Link>
												<Link to="#" className="fav-save" data-id="296"><HeartOutline /></Link>
										   </div>
										   <p className="product-price">$ 20</p>
										   <h3 className="product-title">
											  <Link to="item/Cayman.html">
											  Cayman</Link>
										   </h3>
										   <ul>
											  <li>
												 Car & Truck					
											  </li>
										   </ul>
										   <p className="location d-flex">
											  <Location />
											  <span className="location-text-limit"> Pehowa, Haryana, India </span>
										   </p>
										</div>
									 </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Categorilist;
