import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Close, CarOutline, Location, MenuOutline, CloseCircleOutline } from 'react-ionicons';
import ReactStars from "react-rating-stars-component";

import axios from "axios";

const Userdetailsleftmenu = (props) => {
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			//console.log(response.data);
		});
		});
	}, [language]);
	
	const { id } = useParams();
	let blockedId      = id;
	
	const [verifiedUsers, setVerifiedUsers] = React.useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/verified-users-status/${blockedId}`).then((response)=>{	  //console.log("verify_sta: "+response.data);
			setVerifiedUsers(response.data);
		});
		});
	}, [id]);
	
	const [isBlock, setIsBlock] = useState();
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/block-user-status/${loggedInUserId}/${blockedId}`).then((response)=>{
					//console.log("blocked "+response.data);
					setIsBlock(response.data);
				});
			});
		}
	}, [loggedInUserId]);
	
	/*const location = useLocation();
	const { userContent } = location.state;*/
	//const userContent = props.userContent;
	
	//console.log(loggedInUserId);
	
	const [userContent, setUserContent] = useState([]);
	const [singleUserContent, setSingleUserContent] = useState([]);
	//const [profileState, profileSetState] = useState([]);
	const [profileSince, profileSetSince] = useState();
	const [profileRating, profileSetRating] = useState([]);
	// console.log("blclogout"+blockedId);
	useEffect(()=> {
		//if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user/${blockedId}`).then((response)=>{
					// console.log(response.data);
					setUserContent(response.data);
					//setSingleUserContent(response.data);
					profileSetRating(response.data.star_rating)
					profileSetSince(response.data.sinceYear)
					//profileSetState(response.data);
					//this.setState({ myArray: [...this.state.myArray, 'new value'] })
				});
			});
		//}
	}, [id]);
	//console.log(useLocation());
	//console.log(props.userContent);
	const [isOpen, setisOpen] = useState(true);
	useEffect(() => {
		setisOpen(!isOpen);		
	}, [props.isOpen]);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//Logout Start
	const handleLogout = () => {
		localStorage.clear();
		window.location.pathname = "/";
	};
	
	
	const blockUnblockUser = value => () => {
		let blockUserId = value;
		// console.log("id:"+id);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/block-user/${loggedInUserId}/${blockUserId}`).then((response)=>{
					setIsBlock(response.data);
				});
			});
	};
	//Logout End
	return <>
		<div className="col-lg-2 col-md-2 col-sm-3 col-12 categoris-column categoriesLeft leftNavMenu" style={{display: isOpen ? 'inline' : 'none'}}>
			<div className="cListClose"><CloseCircleOutline onClick={onClickMenu} /></div>
			<div className="userProfile">
				<div className="profileImg">
					{userContent.profile_picture ? <img src={userContent.profile_picture} alt="Avatar" /> : <img src="../../../public/upload/user/avatar.png" alt="Avatar" />}
				</div>
				<h6>{userContent.name}</h6>
				<p className="leftMenuStart rating-star-disable"><ReactStars count={5} key={profileRating} value={profileRating} size={24} 
									activeColor="#3498DB" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/></p>
				
				<p>Member Since {profileSince}</p>
				{loggedInUserId != null ? (
				<div className="userMesgBtn">
				   {isBlock == 1 ? (
					<div><button className="btn btn-info btn-block btn-round w-100 mb-2" id="btn" onClick={blockUnblockUser(`${blockedId}`)}>unBlocked</button></div>
				   ) : (
					<div><button className="btn btn-info btn-block btn-round w-100 mb-2"  onClick={blockUnblockUser(`${blockedId}`)}>Blocked</button></div>
				   ) }
				
				</div>
				) : ''}
				
				{verifiedUsers == 1 ? (
				<div className="userMesgBtn">
					<div><button className="btn btn-info btn-block btn-round w-100 mb-2">Verified</button></div>
				</div>
				) : ''}
				
				
			</div>
			<ul className="leftMenu text-left"></ul>
		</div>
	</>;
}

export default Userdetailsleftmenu;
