import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, ThumbsUpOutline, StarOutline, Star, PencilSharp, LogOutOutline, Close, CarOutline, Location, MenuOutline, CloseCircleOutline, ContractOutline } from 'react-ionicons';
import ReactStars from "react-rating-stars-component";  

import axios from "axios";
import StripeVerifyUserContainer from '../stripe/StripeVerifyUserContainer';

const Userleftmenu = (props) => {
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	let languageStoredInLocalStorage = localStorage.getItem("language");
	
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	let languageContent = props.languageContent;
	
	
	// let c_height = document.getElementById('site-header').clientHeight;
	// let c_height = document.getElementsByClassName('categoriesLeft')[0].innerHeight;
	// console.log(c_height);
	
	const [lmenuHeight, setlmenuHeight] = React.useState([]);
	let calculate_height = window.innerHeight - 66;
	// console.log(window.innerWidth);
	// let lmenuHeight = {maxHeight:calculate_height+'px', minHeight:calculate_height+'px'}
	useEffect(()=> {
		if(window.innerWidth < 494){
			setlmenuHeight({maxHeight:calculate_height+'px', minHeight:calculate_height+'px'});
		}else{
			setlmenuHeight({});
		}
	}, []);	
	/*
	let lmenuHeight = '';
	if(window.innerWidth > 494){
		let lmenuHeight = {maxHeight:calculate_height+'px', minHeight:calculate_height+'px'}
	}else{
		let lmenuHeight = '';
	}*/
	
	// const [lmenuHeight, setLmenuHeight] = React.useState([]);
	// let header_height = document.getElementById('site-header').clientHeight;
	// console.log(header_height);
	/*let calculate_height = (window.innerHeight);
	useEffect(()=> {
		setLmenuHeight({maxHeight:'300px', minHeight:'300px'});
	}, []);*/
	
	
	
	const [publicKey, setPublicKey] = React.useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/stripe-public-key`).then((response)=>{
			// console.log(response.data);
			setPublicKey(response.data);
		});
		});
	}, []);
	{/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			//console.log(response.data);
		});
		});
	}, [language]);*/}
	
	
	const [showVerifyPayment, setVerifyShowPayment] = useState(false);
	const [verifiedUsers, setVerifiedUsers] = React.useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/verified-users-status/${loggedInUserId}`).then((response)=>{
			// console.log("verify_sta: "+response.data);
			setVerifiedUsers(response.data?.data);
		});
		});
	}, [showVerifyPayment]);

	console.log(verifiedUsers,"verifiedUsers")
	
	
	let userdetails = "userdetails";
	const { id } = useParams();
	//console.log("hiiiii.."+id);
	/*const location = useLocation();
	const { userContent } = location.state;*/
	//const userContent = props.userContent;
	
	//console.log(loggedInUserId);
	const [userContent, setUserContent] = useState([]);
	//const [profileState, profileSetState] = useState([]);
	const [profileSince, profileSetSince] = useState();
	const [profileRating, profileSetRating] = useState([]);
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user/${loggedInUserId}`).then((response)=>{
					// console.log(response.data);
					setUserContent(response.data);
					profileSetRating(response.data.star_rating)
					profileSetSince(response.data.sinceYear)
					//profileSetState(response.data);
					//this.setState({ myArray: [...this.state.myArray, 'new value'] })
				});
			});
		}
	}, [loggedInUserId,props.userContent]);
	//console.log(useLocation());
	// console.log(props.isOpen);
	//console.log("user-reating:"+profileRating);
	
	const [isOpen, setisOpen] = useState(true);
	useEffect(() => {
		setisOpen(!isOpen);		
	}, [props.isOpen]);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//Logout Start
	const handleLogout = () => {
		localStorage.clear();
		window.location.pathname = "/";
	};
	
	const [verifyAmount, setVerifyAmount] = useState('');
	const [verifyUserId, setverifyUserId] = useState('');
	const handleverify = (user_id) => () => {
		var price = 1;
		setverifyUserId(user_id);
		setVerifyAmount(price);
		setVerifyShowPayment(true);
	};
	
	const editProfileStyle = {marginRight:'5px', height:'20px'}
	const logoutClassStyle = {marginRight:'5px', height:'20px', color:'#fff'}
	//Logout End
	return <>
		<div className="col-lg-2 col-md-2 col-sm-3 col-12 categoris-column categoriesLeft leftNavMenu" style={{display: isOpen ? 'inline' : 'none'}}>
			<div className="cListClose"><CloseCircleOutline onClick={onClickMenu} /></div>
			<div className="userProfile" style={lmenuHeight}>
				<div className="profileImg">
					{userContent.profile_picture ? <img src={userContent.profile_picture} alt="Avatar" /> : <img src="./upload/user/avatar.png" alt="Avatar" />}
				</div>
				<h6>{userContent.name}</h6>
				<h6>{userContent.user_id}</h6>
				<p className="leftMenuStart rating-star-disable"><ReactStars count={5} key={profileRating} value={profileRating} size={24} 
									activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/></p>
				
				<p>Member Since {profileSince}</p>
				
				<div className="userMesgBtn">
					<div><NavLink to="/my-profile"><button className="btn btn-secondary btn-block btn-round w-100"><PencilSharp style={editProfileStyle}/> {languageContent.edit_profile}</button></NavLink></div>
					
					<div className="userMesgBtn">
					{verifiedUsers?.is_verified ==0 ? (
					<button className="btn btn-info btn-block btn-round w-100" onClick={handleverify(`${loggedInUserId}`)}>verify</button>
					) : (<button className="btn btn-info btn-block btn-round w-100">verified</button>) }
					</div>
					<div><button className="btn btn-info btn-block btn-round w-100" onClick={handleLogout}><LogOutOutline style={logoutClassStyle}/>{languageContent.logout}</button></div>
					{showVerifyPayment ? (
						<StripeVerifyUserContainer publicKey={publicKey} verifyAmount={verifyAmount} verifyUserId={verifyUserId} showVerifyPayment={showVerifyPayment} onPaymentModalClose={showVerifyPayment => setVerifyShowPayment(false)}/>
					) : ''}
				</div>
				<hr></hr>
				<ul className="leftMenu text-left">
					<li><NavLink to="/user-dashboard" userContent={userContent} state={{userContent: userContent }}><QrCodeOutline />Dashboard</NavLink></li>
					<li><NavLink to="/my-profile" userContent={userContent} state={{userContent: userContent }}><PersonOutline />My Profile</NavLink></li>
					<li><NavLink to="/add-item" userContent={userContent} state={{userContent: userContent }}><ListOutline />Add An Item</NavLink></li>
					{/*<li><NavLink to="/edit-item" state={{userContent: userContent }}><ChatbubblesOutline />Edit Item</NavLink></li>*/}
					<li><NavLink to="/my-item"><AppsOutline />{languageContent.my_items}</NavLink></li>
					<li><NavLink to="/my-item-sold"><AppsOutline />My Items Sold</NavLink></li>
					<li><NavLink to="/add-bulletin" userContent={userContent} state={{userContent: userContent }}><ListOutline />Add Bulletin</NavLink></li>
					<li><NavLink to="/my-bulletin"><EllipsisHorizontalOutline />My Bulletin</NavLink></li>
					<li><NavLink to="/bulletin"><EllipsisHorizontalOutline />{languageContent.bulletin}</NavLink></li>
					<li><NavLink to="/notification"><NotificationsOutline />{languageContent.notifications}</NavLink></li>
					<li><NavLink to="/help"><HelpCircleOutline />{languageContent.help_center}</NavLink></li>
					<li><NavLink to="/customer-supports"><ChatboxEllipsesOutline />Customer Support</NavLink></li>
					{/*<li><NavLink to="/sponsored-plans"><TicketOutline />Sponsored Plan</NavLink></li>*/}
					<li><NavLink to="/emergency-contact"><ChatboxOutline />Emergency Contact</NavLink></li>
					<li><NavLink to="/like-item"><ThumbsUpOutline />Like Item</NavLink></li>
					<li><NavLink to="/favourite-item"><HeartOutline />Favourite Item</NavLink></li>
					<li><NavLink to="/report-item"><NotificationsOutline />Report Item</NavLink></li>
					<li><NavLink to="/arrange-meeting"><ContractOutline />Arrange Meeting</NavLink></li>
					<li><NavLink to="/review"><StarOutline />{languageContent.reviews}</NavLink></li>
					<li><NavLink to="/block-users"><StarOutline />{languageContent.block_user}</NavLink></li>
					<li><NavLink to="/payment-details"><StarOutline />Payment Details</NavLink></li>

				</ul>
               
			</div>
		</div>
	</>;
}

export default Userleftmenu;
