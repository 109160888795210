import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, CloudUploadOutline, DesktopOutline, CarOutline, HomeOutline, FlaskOutline, BodyOutline, BonfireOutline, BusinessOutline, KeypadOutline, NewspaperOutline, BicycleOutline, BookOutline, AirplaneOutline, ServerOutline, FlowerOutline, MenuOutline, CloseCircleOutline, Close } from 'react-ionicons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useGeoLocation from "../../hooks/useGeoLocation";
import PhoneInput from 'react-phone-input-2';

import Userleftmenu from './Userleftmenu';
import axios from "axios";
import StripeBulletinContainer from '../stripe/StripeBulletinContainer';


function Addbulletin(props) {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	//console.log("Lang: "+language);
	let languageContent = props.languageContent;
	//Language change end
	
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	
	const getLocation = useGeoLocation();
	const [publicKey, setPublicKey] = React.useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/stripe-public-key`).then((response)=>{
			console.log(response.data, "publicKey-------");
			setPublicKey(response.data);
		});
		});
	}, []);
	//For Ip address
	const [useIp, setUseIp] = useState();
	useEffect(() => {
		fetch('https://ipapi.co/json/')
		// fetch('https://geolocation-db.com/json/')
			.then((response) => response.json())
			.then((responseJson) => {
				//console.log(responseJson.IPv4);
				setUseIp(responseJson.ip);
		})
	}, [useIp]);
	//console.log(useAddress);
	//console.log(useLocation);
		
	const [isLoading, setisLoading] = useState(false);
	const [ItemFormErrors, setItemFormErrors] = useState([]);
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//let loggedInUserId = 8;
	//const [itemImage, setItemImage] = React.useState();
		
	//----- Remove image ----
	//----- multiple file upload
		const [multipleFiles, setmultIpleFiles] = React.useState();
		const [multipleReadImgFiles, setmultIpleReadImgFiles] = useState([]);
		let vidFlag = '';
		const multiplefileschange = (e)=> {
			setmultIpleFiles(e.target.files);
			//preview start
			const selectedImgFIles = [];
			const selectedVidFIles = [];
			const targetFiles = e.target.files;
			const targetFilesObject = [...targetFiles]
			targetFilesObject.map((file, i)=>{
				if(file.type==='image/gif' || file.type==='image/jpeg' || file.type==='image/png' || file.type==='image/webp'){
					return selectedImgFIles.push(URL.createObjectURL(file));
				}
			})
			setmultIpleReadImgFiles(selectedImgFIles);
			//preview end
		}
		const handleUploadImageDelete = arrImg => {
			var updatedUploadPhotos = [...multipleFiles]; // make a separate copy of the array
			//var index = updatedUploadPhotos.indexOf(arrImg);
			//console.log(arrImg);
			if (arrImg !== -1) {
				updatedUploadPhotos.splice(arrImg, 1);
				setmultIpleFiles(updatedUploadPhotos);
				//console.log(updatedUploadPhotos);
				
				//preview start
				const selectedImgFIles = [];
				const targetFiles = updatedUploadPhotos;
				const targetFilesObject = [...targetFiles]
				targetFilesObject.map((file, i)=>{
					return selectedImgFIles.push(URL.createObjectURL(file));
				})
				setmultIpleReadImgFiles(selectedImgFIles);
			}
		};
	//---------end----------
	
	
	let isItemSubmit = false;
	
	const [itemState, itemSetState] = React.useState({
		first_name: "",
		last_name: "",
		business_name: "",
		email_id: "",
		mobile_number: "",
		web_url: "",
		user_location: "",
		package: "",
		country_id: props.countryId,
		advertisement_duration: "",
		message: ""
	});
	// console.log(props.countryId);
	// console.log(props);
	// get country
	let [bulletinPackage,setBulletinPackage] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-bulletin-package`).then((response)=>{
			// console.log(response.data);
			setBulletinPackage(response.data);
		});
		});
	}, []);
	let [countryName,setCountryName] = useState([]);
	let [currencyName,setCurrencyName] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/getcountry`).then((response)=>{
			// console.log(response.data);
			setCountryName(response.data);
		});
		});
	}, []);
	const [showPayment, setShowPayment] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState('');
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/get-bulletin-currency-price/${itemState.country_id}/${itemState.package}`).then((response)=>{
			// console.log(response.data);
			setCurrencyName(response.data.country.currency_symbol);
			setPaymentAmount(response.data.package.price);
		});
		});
	}, [itemState.country_id, itemState.package]);
	//console.log("country name:-"+countryName);
		
	const handleItemChange = ({ target })=>{
		itemSetState({ ...itemState, [target.name]: target.value});
	};
	
	const [bulletinId, setBulletinId] = useState([]);
	const [getPlan, setGetPlan] = useState([]);
	const onItemSubmit = e => {
		
		let arrFile = [];
		e.preventDefault();
		itemValidate(itemState);
		if(isItemSubmit){
			setisLoading(true);
			const rData = new FormData();
			for(let i=0;i<=multipleFiles.length ;i++)
			{
				rData.append("image_data[]", multipleFiles[i]);
			}
			rData.append("user_id", loggedInUserId);
			rData.append("first_name", itemState.first_name);
			rData.append("last_name", itemState.last_name);
			rData.append("business_name", itemState.business_name);
			rData.append("email_id", itemState.email_id);
			rData.append("mobile_number", itemState.mobile_number);
			rData.append("web_url", itemState.web_url);
			rData.append("user_location", itemState.user_location);
			rData.append("advertisement_duration", itemState.advertisement_duration);
			rData.append("country_id", itemState.country_id);
			rData.append("package", itemState.package);
			rData.append("message", itemState.message);
			rData.append("paymentAmount", paymentAmount);
			rData.append("ip_address", useIp);
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/add-bulletin`, rData).then(response => {
					// console.log(response);
					if(response.data.status==200){
						itemSetState([]);
						setmultIpleReadImgFiles([]);
						//console.log(setmultIpleReadImgFiles.length);
						setBulletinId(response.data.id);
						setGetPlan(itemState.package);
						toast(response.data.message);
						setShowPayment(true);
					}else if(response.data.status==600){
						const errors = {};
						errors.first_name = response.data.errors.first_name;
						errors.last_name = response.data.errors.last_name;
						errors.business_name = response.data.errors.business_name;
						errors.email_id = response.data.errors.email_id;
						errors.mobile_number = response.data.errors.mobile_number;
						errors.web_url = response.data.errors.web_url;
						errors.user_location = response.data.errors.user_location;
						errors.advertisement_duration = response.data.errors.advertisement_duration;
						errors.country_id = response.data.errors.country_id;
						errors.package = response.data.errors.package;
						errors.message = response.data.errors.message;
						setItemFormErrors(errors);
					}
					setisLoading(false);
				});
			});
		}
	};
	
	const itemValidate = (values) => {
		const errors = {};
		if (!values.first_name) {
			isItemSubmit = false;
			errors.first_name = "First name is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.last_name) {
			isItemSubmit = false;
			errors.last_name = "Last name is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.business_name) {
			isItemSubmit = false;
			errors.business_name = "Business name is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.email_id) {
			isItemSubmit = false;
			errors.email_id = "Email is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.mobile_number) {
			isItemSubmit = false;
			errors.mobile_number = "Mobile number is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.web_url) {
			isItemSubmit = false;
			errors.web_url = "Website is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.user_location) {
			isItemSubmit = false;
			errors.user_location = "Location is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.advertisement_duration) {
			isItemSubmit = false;
			errors.advertisement_duration = "Advertisement duration is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.country_id) {
			isItemSubmit = false;
			errors.country_id = "Country is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.package) {
			isItemSubmit = false;
			errors.package = "Package is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.message) {
			isItemSubmit = false;
			errors.message = "Message is required!";
		}else{
			isItemSubmit = true;
		}
		/*if (!values.location) {
			isItemSubmit = false;
			errors.location = "Location is required!";
		}else{
			isItemSubmit = true;
		}*/
		/*if(!values.description){
			isItemSubmit = false;
			errors.description = "Description is required!";
		}else{
			isItemSubmit = true;
		}*/
		setItemFormErrors(errors);
		return isItemSubmit;
	}
	// console.log(ItemFormErrors);
	/*const removeSelectedImage = () => {
		setItemImage();
	};	*/
	
	/*useEffect(()=> {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address=22.6159285,88.4020592&key='+process.env.REACT_APP_GOOGLE_MAP_API_KEY)
			.then((response) => response.json())
			.then((responseJson) => {
				// console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));
		})
	}, []);*/

	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage bulletin_page">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Add Bulletin</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}  </Link>/ Add Bulletin</div>
									</div>
								</div>
							</div>
							{ItemFormErrors.itemError}
							<form id="itemForm" onSubmit={onItemSubmit} method="post" enctype="multipart/form-data">
							<div className="videoUpload">
								<h6>Upload Bulletin Image<small></small></h6>
								<div className="mediaUpload clearfix">
									<div className="imageLoad">
										<div className="uploadFile">
											<CloudUploadOutline />
											<p>Drag and drop file here<small>or</small></p>
											<button className="btn btn-info">Browse for file</button>
											<input accept="image/*" type="file" onChange={(e) => multiplefileschange(e)} name="itemImage" id="itemImage"/>
										</div>
									</div>
								</div>
								<div className="mediaUpload clearfix">
								{multipleReadImgFiles ? (
									<div className="imageUploaded">
										{multipleReadImgFiles.map((url, j)=>{
											return <>
												<div>
													<img src={url} alt="Avatar"/>
													<span ><Close/></span>
													<span onClick={() => handleUploadImageDelete(j)}><Close/></span>
												</div>
											</>;
										})}
									</div>
								) : (
									null
								)}	
								</div>
								<div className="mt-4 mb-4">
									<div className="row">
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">First Name</label>
												<input type="text" onChange={handleItemChange} className="form-control" name="first_name" />
												<div className="errorMesg">{ItemFormErrors.first_name}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Last Name</label>
												<input type="text" onChange={handleItemChange} className="form-control" name="last_name" />
												<div className="errorMesg">{ItemFormErrors.last_name}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Business Name</label>
												<input type="text" onChange={handleItemChange} name="business_name" className="form-control" placeholder="Business Name" />
												<div className="errorMesg">{ItemFormErrors.business_name}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Email</label>
												<input type="text" onChange={handleItemChange} name="email_id" className="form-control" placeholder="Email" />
												<div className="errorMesg">{ItemFormErrors.email_id}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3 countryInput">
												<label for="" className="form-label">{languageContent.mobile_number}<small>(With country code)</small></label>
												<PhoneInput enableSearch={true} countryCodeEditable={false} onChange={phone => itemSetState({ ...itemState, ['mobile_number']: phone})} value={itemState.mobile_number} inputProps={{ name: 'mobile_number', className: 'form-control mobileInput' }}/>
												<div className="errorMesg">{ItemFormErrors.mobile_number}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Website</label>
												<input type="text" onChange={handleItemChange} name="web_url" className="form-control" placeholder="Website" />
												<div className="errorMesg">{ItemFormErrors.web_url}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="dName" className="form-label">Select Country</label>
												<select className="form-control" id="country_id" name="country_id" onChange={handleItemChange} value={itemState.country_id}>
													<option value=''>-select_country-</option>
													{countryName.map((val) => {
															return <>	
															<option value={val.id}>{val.country_name}</option>
														</>;
													})}
												</select>
												<div className="errorMesg">{ItemFormErrors.country_id}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="dName" className="form-label">Select Package</label>
												<select className="form-control" id="package" name="package" onChange={handleItemChange} value={itemState.package}>
													<option value=''>-select_package</option>
													{bulletinPackage.map((val) => {
														return <>	
															<option value={val.id}>{val.package_name}</option>
														</>;
													})}
												</select>
												<div className="errorMesg">{ItemFormErrors.package}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Location</label>
												<input type="text" onChange={handleItemChange} name="user_location" className="form-control" placeholder="Location" />
												<div className="errorMesg">{ItemFormErrors.user_location}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Duration of Advertisement <small>(Months)</small></label>
												<input type="text" onChange={handleItemChange} name="advertisement_duration" className="form-control" placeholder="Duration of Advertisement" />
												<div className="errorMesg">{ItemFormErrors.advertisement_duration}</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-3">
												<label for="emailType" className="form-label">Message</label>
												<div className="form-group myTextarea">
													<textarea name="message"className="form-control" rows="5" onChange={handleItemChange}></textarea>
													<div className="errorMesg">{ItemFormErrors.message}</div>
												</div>
											</div>
										</div>
										<div className="col-md-12 mt-3">
											<button type="submit" className="btn btn-primary" disabled={isLoading}>
												{isLoading ? <div className="btnloader"></div> : 'Submit'}
											</button>
										</div>
									</div>
								</div>
							</div>
							</form>
							<>
							{showPayment ? (
								<StripeBulletinContainer publicKey={publicKey} paymentAmount={paymentAmount} paymentCurrency={currencyName} getPlan={getPlan} bulletinId={bulletinId} showPayment={showPayment} onPaymentModalClose={showPayment => setShowPayment(false)}/>
							) : ''}
							</>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Addbulletin;
