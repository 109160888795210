import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, Typography, CardActions, Button, Grid, IconButton } from '@mui/material';
import { CenterFocusStrong } from '@mui/icons-material';

const Paymentcard = ({ cardBrand,cardId, cardNumber, cardHolder, expiryDate, onEdit, onDelete }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Grid container spacing={2} justifyContent={'center'} alignItems="center">
          <Grid item >
            {cardBrand === 'visa' ? <img height="100"
              width="100" alt="VISA" src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#visa" />
              : cardBrand === 'masterCard' ? <img height="100" width="100"
                alt="MasterCard" src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#mastercard" /> :
                cardBrand === 'amex' ? <img height="100" width="100" alt="AMEX"
                  src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#amex" /> :
                  <img height="100" width="100"
                    alt="Rupay" src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#rupay" />}
          </Grid>
          <Grid item >
            <Typography variant="h6" component="div">
              {cardBrand}
            </Typography>
            <Typography variant="h6" component="div">
              {cardNumber}
            </Typography>
            <Typography color="text.secondary" component={'div'}>
              {cardHolder}
            </Typography>
            <Typography color="text.secondary" component={'div'}>
              Expiry: {expiryDate}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {/* <Button size="small" onClick={onEdit}>
          <EditIcon fontSize="small" /> Edit
        </Button> */}
        <Grid container justifyContent={'center'}>
          <Button variant="contained" onClick={()=>onDelete(cardId)} color='error'>Delete</Button>
        </Grid>
        {/* <IconButton size="small" color="secondary" onClick={onDelete}>
          <DeleteIcon />
        </IconButton> */}
      </CardActions>
    </Card>
  );
};

export default Paymentcard;


{/* <div>
   @if($card_val['card']->brand=='visa')
  <span style=" padding-right: 5px;"><img height="100" 
  width="100" alt="VISA" src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#visa">
    </span>  @elseif($card_val['card']->brand=='mastercard')

  <span style="padding-right: 5px;"><img height="100" width="100"
   alt="MasterCard" src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#mastercard">
</span>@elseif($card_val['card']->brand=='amex')

  <span style="padding-right: 5px;">
    <img height="100" width="100" alt="AMEX" 
    src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#amex"></span>
  @else
  <span style="padding-right: 5px;"><img height="100" width="100" 
  alt="Rupay" src="https://img1.wsimg.com/fos/react/icons/115/gd/sprite.svg#rupay"></span>
  </span>
@endif </div > */}