import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React, {Component, useEffect} from 'react';
function MapContainer(props) {
	//console.log(props.useLocation);
	const [getLatLocation, setGetLatLocation] = React.useState(props.useLocation.lat);
	const [getLngLocation, setGetLngLocation] = React.useState(props.useLocation.lng);
	useEffect(()=> {
		setGetLatLocation(props.useLocation.lat);
		setGetLngLocation(props.useLocation.lng);
	}, [props.useLocation, getLatLocation, getLngLocation]);
	const onMapClick = (t, map, coord) => {
		const { latLng } = coord;
		const lat = latLng.lat();
		const lng = latLng.lng();
		setGetLatLocation(lat);
		setGetLngLocation(lng);
		props.chgUseLocation({lat: lat, lng: lng});
	}
    return <>
		{props.isEditable!=0 ? (
				<Map 
					google={props.google} 
					onClick={onMapClick} 
					zoom={props.useZoom}
					center={{ lat: parseFloat(getLatLocation), lng: parseFloat(getLngLocation)}}
				>
					<Marker 
						name={'Current location'} 
						position={{ lat: parseFloat(getLatLocation), lng: parseFloat(getLngLocation)}}
					/>
			 
					<InfoWindow></InfoWindow>
				</Map>
		) : (
			<Map 
					google={props.google} 
					onClick={onMapClick} 
					zoom={props.useZoom}
					center={{ lat: getLatLocation, lng: getLngLocation}}
				>
					<Marker 
						name={'Current location'} 
						position={{ lat: getLatLocation, lng: getLngLocation }}
					/>
			 
					<InfoWindow></InfoWindow>
				</Map>	
		)}
    </>;
};

export default GoogleApiWrapper({
	apiKey: (process.env.REACT_APP_GOOGLE_MAP_API_KEY)
})(MapContainer)