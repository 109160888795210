import React, { useState, useEffect } from "react";
import axios from "axios";
import { SearchOutline } from 'react-ionicons';
//import Autocomplete from 'react-autocomplete';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNavigate } from "react-router-dom"
function TopSearch(props) {
	let language = localStorage.getItem("language");
	//console.log(language);
	let countrydata = (props.countryId!='') ? props.countryId: '';
	
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId=='')
	{
		navigate(`/`)
	}
	
	// GET COUNTRY ID
	/*let [countryName,setCountryName] = useState([]);
	useEffect(()=> {
		//if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/getcountry`).then((response)=>{
					console.log(response.data);
					setCountryName(response.data);
				});
			});
		//}
	}, []);*/
	
	//console.log("country_id:-"+countryName);
	//window.location.toString().includes("/item/")
	
	// if(window.location.toString().includes("/item/"))
	// {
		
	// }
	// else
	// {
		useEffect(() => { 
			if(window.location.toString().includes("/item/")){
				
			}else{
				if(loggedInUserId!=null){
					let ItemId   = localStorage.getItem("view_item_id");
					let sellerId = localStorage.getItem("view_seller_id");
					axios.get(`/sanctum/csrf-cookie`).then(response => {
						axios.post(`/api/item-view-by-buyer-delete/${loggedInUserId}/${ItemId}/${sellerId}`).then((response)=>{		
							// console.log(response.data);
						});
					});
				}
			}
		}, []);
	// }
	
	const [searchData, setSearchData] = useState([]);
	const onSearchChange = (text )=>{
		const cData = new FormData();
		cData.append("search", text);
		cData.append("country_id", countrydata);
		//console.log("text"+countrydata);
		if(text!=''){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/search/${language}`, cData).then(response => {
					//console.log(response.data);
					setSearchData(response.data);
				});
			});
		}else{
			setSearchData([]);
		}
	};
	
	//---- search by name-------
	const [searchNameData, setSearchNameData] = useState([]);
	const onSearchUserName = (text )=>{
		const cData = new FormData();
		cData.append("usersearch", text);
		//cData.append("usersearch", 'yasm');
		//console.log("username: "+text);
		if(text!=''){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/search-by-username/${loggedInUserId}`, cData).then(response => {
					//console.log(response.data);
					setSearchNameData(response.data);
				});
			});
		}else{
			setSearchNameData([]);
		}
	};
	//console.log(searchNameData);
	//console.log("countryDatass"+countrydata);
	const onSearchSelect = (event, values) => {
		// console.log(values.type);
		
			if(values.type==='Item'){
				navigate(`/item/${values.slug}-id-${values.id}`);
			}else{
				navigate(`/category/${values.slug}`);
			}
		
	};
	
	const onSearchUserSelect = (event, values) => {
		//console.log('values:-'+values);
		navigate(`/user-details/${values.id}`);
	};
	
	{/*const [searchBlockData, setSearchBlockData] = useState([]);
	const onSearchUserSelect = (event, values) => {
		console.log(values);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/home-search-url-block/${loggedInUserId}/${values.id}`).then(response => {
				setSearchBlockData(response.data);
			});
		});
		
		if(searchBlockData == 2)
		{
			navigate(`/user-details/${values.id}`);
		}
     };*/}
	
	
	return <>
	<div className="input-group searchBox">
		<Autocomplete
			freeSolo
			autoComplete
			autoHighlight
			options={searchData}
			getOptionLabel={(option) => option.name}
			// onChange={(event, value) => console.log(value)}
			onChange={onSearchSelect}
			groupBy={(option) => String(option.type)}
			renderInput={(params) => {
				return (
					<TextField {...params}
					onChange={e => onSearchChange(e.target.value)}
					variant="outlined"
					placeholder="Search Item or Categories"
					/>
				)
			}}
		/>
		<button className="searchBtn"><SearchOutline /></button>
	</div>
	<div className="input-group searchBox pBox desktopView">
		<Autocomplete
        freeSolo
        autoComplete
        autoHighlight
		options={searchNameData}
		//options={searchData}
		getOptionLabel={(option) => option.name}
		//groupBy={(option) => String(option.type)}
		onChange={onSearchUserSelect}
		renderInput={(params) => {
        return (
            <TextField {...params}
            onChange={e => onSearchUserName(e.target.value)}
            variant="outlined"
            placeholder="Search Users"
          />
		)
		}}
		/>
		<button className="searchBtn"><SearchOutline /></button>
	</div>
	
	</>;
}
export default TopSearch;
