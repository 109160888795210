import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, TimeOutline, TrashOutline, BagHandleOutline, CheckmarkSharp, MenuOutline, Create, CloseCircleOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Userleftmenu from './Userleftmenu';
import axios from "axios";

const Customersupports = (props) => {
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	const [show, setShow] = React.useState();

	const [userContent, setUserContent] = useState([]);
	const [profileRating, profileSetRating] = useState([]);
	useEffect(()=> {
		if(loggedInUserId!=null){
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/user/${loggedInUserId}`).then((response)=>{
				//console.log(response.data);
				setUserContent(response.data);
				//profileSetState(response.data);
				//this.setState({ myArray: [...this.state.myArray, 'new value'] })
				});
			});
		}
	}, [loggedInUserId]);
	
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	//console.log("Lang: "+language);
	let languageContent = props.languageContent;
	
	
	const [getGetUserMsg, setGetUserData] = useState([]);
	useEffect(()=> {
		if(loggedInUserId!=null){
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/get-customer-message/${loggedInUserId}`).then((response)=>{
					// console.log(response.data);
					//profileSetState(response.data);
					setGetUserData(response.data);
					//document.body.classList.remove('disable_load_content');
				});
			});
		}
	}, [loggedInUserId]);
//Language change end
	
	const [profileState, profileSetState] = useState([]);
	const [getEmgData, setGetEmgData] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/get-emergency-contact/${loggedInUserId}`).then((response)=>{
					// console.log("customer-response");
					//console.log(response);
					//setGetEmgData(response);
					//document.body.classList.remove('disable_load_content');
				});
			});
		
	}, []);
	//console.log("fdata "+getEmgData.user_id);
	const [profileImage, setProfileImage] = useState();
	
	const [profileFormErrors, setProfileFormErrors] = useState([]);
	let isProfileSubmit = false;
	const [isLoading, setisLoading] = useState(false);	
	const handleProfileChange = ({ target })=>{
			profileSetState({ ...profileState, [target.name]: target.value});
			//console.log(profileState);
	};	
	const removeSelectedImage = () => {
			setProfileImage();
	};
	const onCustomerSubmit = (e) => {
			e.preventDefault();
			profileValidate(profileState);
			if(isProfileSubmit){
			// console.log('test1');
				setisLoading(true);
				const pData = new FormData();
				pData.append("id", localStorage.getItem("loggedin_user_id"));
				pData.append("message", profileState.message);
				pData.append("customer_file", profileImage);
				//console.log(pData);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/update-customer-support/${props.language}`, pData).then(response => {
						// console.log(response);
						if(response.data.status==200){
							//console.log(response);
							/*localStorage.setItem("loggedin_user_id",response.data.user.id);
							localStorage.setItem("loggedin_user_name",response.data.user.name);
							localStorage.setItem("loggedin_user_email",response.data.user.email);*/
							//userContent.push(response.data.user);
							//console.log(userContent);
							//props.chgUserContent(response.data.user)
							//setUserContent(response.data.user);
							//profileSetState(response.data.user);
							setProfileImage();
							toast(response.data.message);
						}else if(response.data.status==400){
							//console.log(response.data.message);
							const errors = {};
							errors.editProfileError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
													{response.data.message}
												</div>;
							setProfileFormErrors(errors);
						}else if(response.data.status==600){
							// console.log(response.data);
							const errors = {};
							errors.message = response.data.errors.message;
							//errors.email = response.data.errors.email;
							//errors.mobile_number = response.data.errors.mobile_number;
							setProfileFormErrors(errors);
						}
						setisLoading(false);
					});
				});
			}
	};	
		
	const profileValidate = (values) => {
			// console.log(values);
			const errors = {};
			if (!values.message) {
				errors.message = "Message is required!";
			}else{
				isProfileSubmit = true;
			}
			setProfileFormErrors(errors);
			// console.log(errors);
			return isProfileSubmit;
		}	
		
	//--- fetch userdata-----
	//const [userEmergencyData, setuserEmergencyData] = useState([]);
		const messages = {
				// width 		: "800px",
				height		: "auto",
				marginTop	: "8px",
				marginLeft	: "35px",
				backgroundColor : "#F0E68C",
				borderRadius    : "5px",
		}
		const customerFile = {
			marginTop	: "8px",
			height  	: "75px", 
			width		: "75px",
		}
        /*const userMsg = {
				
				backgroundColor : "#DCDCDC",
		}
		const adminMsg = {
				
				backgroundColor : "#F0E68C",
		}*/		
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage customer_support_parent">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Customer Supports</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home} </Link>/ Customer Supports</div>
									</div>
								</div>
							</div>
							<div className="nInfo">
							{/*<div className="row sPlans">
							</div>*/}
							{profileFormErrors.editProfileError}
								<form onSubmit={onCustomerSubmit} method="post" enctype="multipart/form-data">
									<div className="cPassword">
										<h4>Customer Support</h4>
										<div className="mb-3">
											<label for="cupassword" className="form-label">Share Details</label>
											<textarea name="message" className="form-control" id="ename"  onChange={handleProfileChange} rows="5"></textarea>
											<div className="errorMesg">{profileFormErrors.message}</div>
										</div>
										
										<div className="mb-3">
											<label for="cupassword" className="form-label">File Upload</label>
											<input type="file" className="form-control upload" onChange={(e) => setProfileImage(e.target.files[0])} name="customer_file"/>
										</div>
										<div className="saveBtn"><button type="submit" className="btn btn-info" disabled={isLoading}>{ isLoading ? (<div className="btnloader"></div>) : "SUBMIT" }</button></div>
									</div>
								</form>
							</div> 
							{getGetUserMsg.map((val) => {
								return <>
							<div className="row customerSMessage">
								<div className="col-md-12">
									<div className="customerSMessageText">
										<div>
											<b>Customer</b> : { (val.customer_file!= '') ? <img src={process.env.REACT_APP_SERVER_API_URL + `images/customer_support/${loggedInUserId}/thumbs/${val.customer_file}`} style={customerFile}/> : '' }
											{ (val.customer_file!= '') ? <br/> : '' }
											{val.message}
										</div>
										<div>
											<b>Admin</b> : { (val.reply_image!= '') ? <img src={process.env.REACT_APP_SERVER_API_URL + `images/customer_support/${loggedInUserId}/thumbs/${val.reply_image}`} style={customerFile}/> : '' }
											{ (val.reply_image!= '') ? <br/> : '' }
											{val.admin_reply}
										</div>
									</div>
								</div>
							</div>
							</>;
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Customersupports;