import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Create, MenuOutline, CloseCircleOutline, Location, MailOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Userleftmenu from './Userleftmenu';
import axios from "axios";

function ContactUs(props) {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	//if(loggedInUserId==null)
	//{
	   //navigate(`/`)
	//}
	let languageContent = props.languageContent;
	//console.log("la "+props.languageContent);
	/*const location = useLocation();
	const { userContent } = location.state;*/
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	
	//console.log(loggedInUserId);
	const [userContent, setUserContent] = useState([]);
	const [profileState, profileSetState] = useState([]);
	const [profileRating, profileSetRating] = useState([]);
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user/${loggedInUserId}`).then((response)=>{
					//console.log(response.data);
					setUserContent(response.data);
					//profileSetState(response.data);
					//document.body.classList.remove('disable_load_content');
					//this.setState({ myArray: [...this.state.myArray, 'new value'] })
				});
			});
		}
	}, [loggedInUserId]);
	
	//console.log(props.userContent);
	//let userContent = props.userContent;
	//edit my profile start
		const [profileFormErrors, setProfileFormErrors] = useState([]);
		let isContactSubmit = false;
		//let loading = false;
		const [isLoading, setisLoading] = useState(false);
		/*const [profileState, profileSetState] = useState({
			profile_picture: '',
			name: userContent.name,
			email: userContent.email,
			mobile_number: userContent.mobile_number,
			current_password: "",
			password: "",
			password_confirmation: ""
		});*/
		const [profileImage, setProfileImage] = useState();
		/*const phoneChange = ({ target })=>{
			console.log(target.value);
		};*/
		const handleContactChange = ({ target })=>{
			profileSetState({ ...profileState, [target.name]: target.value});
			//console.log(profileState);
		};
		// This function will be triggered when the "Remove This Image" button is clicked
		const removeSelectedImage = () => {
			setProfileImage();
		};
		//console.log(profileState);
		const onContactUsSubmit = (e) => {
			e.preventDefault();
			contactValidate(profileState);
			if(isContactSubmit){
				setisLoading(true);
				const pData = new FormData();
				//pData.append("id", localStorage.getItem("loggedin_user_id"));
				//pData.append("profile_picture", profileImage);
				pData.append("name", profileState.name);
				pData.append("email", profileState.email);
				pData.append("mobile_number", profileState.mobile_number);
				pData.append("contact_message", profileState.contact_message);
				//pData.append("current_password", profileState.current_password);
				//pData.append("password", profileState.password);
				//pData.append("password_confirmation", profileState.password_confirmation);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/contacts-us/${props.language}`, pData).then(response => {
						// console.log(response);
						if(response.data.status==200){
							//console.log(response);
							toast(response.data.message);
						}else if(response.data.status==400){
							//console.log(response.data.message);
							const errors = {};
							errors.editProfileError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
													{response.data.message}
												</div>;
							setProfileFormErrors(errors);
						}
						setisLoading(false);
					});
				});
			}
		};
	
		const contactValidate = (values) => {
			//console.log(values);
			const errors = {};
			//const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
			if (!values.name != '') {
				errors.name = "Name is required!";
			}else if (!values.mobile_number != '') {
				errors.mobile_number = "Mobile number is required!";
			}else if (!values.email != '') {
				errors.email = "Email is required!";
			}else if (!values.contact_message != '') {
				errors.contact_message = "Message is required!";
			}
			else{
				isContactSubmit = true;
			}
			setProfileFormErrors(errors);
			//console.log(profileFormErrors);
			return isContactSubmit;
		}
	//Edit my profile end
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//console.log("edit Profile: "+languageContent.edit_profile);
	//const styleHeight = {titleHeight: '30', storyHeight: 1}; 
	
	const [textAreaHeight, setTextAreaHeight] = useState(100);
	const textAreaStyle: CSSProperties = {
		height: textAreaHeight,
	};
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<div className="col-lg-12">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
								<div className="row">
										<h1>{languageContent.contact_us}</h1>
										<div className="breadcrumb">{languageContent.contact_us}</div>
								</div>
								</div>
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 ">
							
							<div className="pInfo">
								<h4>{languageContent.send_request}</h4>
								{profileFormErrors.editProfileError}
								<form onSubmit={onContactUsSubmit} method="post" enctype="multipart/form-data">
									<div className="profileD">
										
										<div className="pTxtContact">
											<div className="mb-3">
												<label for="dName" className="form-label">Name</label>
												<input type="text" className="form-control" id="dName" name="name" onChange={handleContactChange} />
												<div className="errorMesg">{profileFormErrors.name}</div>
											</div>
											<div className="mb-3 countryInput">
												<label for="mNumber" className="form-label">{languageContent.mobile_number}<small>(With country code)</small></label>
												<PhoneInput enableSearch={true} countryCodeEditable={false} onChange={phone => profileSetState({ ...profileState, ['mobile_number']: phone})}  inputProps={{ name: 'mobile_number', className: 'form-control mobileInput' }}/>
												<div className="errorMesg">{profileFormErrors.mobile_number}</div>
											</div>
											<div className="mb-3">
												<label for="emailType" className="form-label">Email address</label>
												<input type="email" className="form-control" id="emailType" name="email" onChange={handleContactChange}/>
												<div className="errorMesg">{profileFormErrors.email}</div>
											</div>
											<div className="mb-3">
												<label for="emailType" className="form-label">{languageContent.messages}</label>
												<textarea name="contact_message" className="form-control" style={textAreaStyle} onChange={handleContactChange}/>
												<div className="errorMesg">{profileFormErrors.contact_message}</div>
											</div>
											<div className="saveBtn"><button type="submit" className="btn btn-info" disabled={isLoading}>{ isLoading ? (<div className="btnloader"></div> ):languageContent.send}</button></div>
										</div>
									</div>
									
								</form>
								
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12">
							<div className="pInfo contactInfo">
								<h4>Headquarters Location</h4>
								<h6 className="contactTitle">Glendale CA</h6>
								<div className="contactLocation"><Location/> 100 North Brand Blvd Suite 200 Glendale Ca, 91203</div>
								<div className="contactMail"><MailOutline/> support@snapsells.com</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default ContactUs;
