import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useParams} from 'react-router-dom';
import { styled } from '@mui/material/styles';
//import { Link } from 'react-router-dom';
import { Star, StarOutline, Heart, HeartOutline, GridOutline, ListOutline, Location, OptionsSharp, FunnelOutline, CloseCircleOutline } from 'react-ionicons';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import axios from "axios";
import Loginmodal from '../Loginmodal';

const Separator = styled('div')(
	({ theme }) => `
	height: ${theme.spacing(3)};
`,
);
const marks = [
  {
	value: 0,
    label: '0km',
  },
  {
    value: 20,
    label: '20km',
  },
  {
    value: 50,
    label: '50km',
  },
  {
    value: 100,
    label: '100km',
  },
];
function valuetext(value) {
	return `${value}°C`;
}

// const CategoryWiselist = () => {
function CategoryWiselist(props) {	
	/*useEffect(()=> {
		document.body.classList.add('disable_load_content');
	}, []);*/
		
	let [userContent, setUserContent] = useState(props.userContent);
	
	//console.log(props.language);
	const { slug } = useParams(); // category slug
    // console.log("category-slug: "+slug);
	//---get category name as change of url
	//let currentCategory      = window.location.href.split('/');
	//let categorySlug    	 = currentCategory[4];
	//console.log("slug_value"+currentCategory[4]);
	const [categoryIcon,setCategoryIcon] = useState([]);
	useEffect(()=> {
		//if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/get-category-Icon/${slug}`).then((response)=>{
					//console.log(response.data);
					setCategoryIcon(response.data);
				});
			});
		//}
	}, [slug]);
	//------
	
	useEffect(()=> {
		props.chgUserContent(userContent);
	}, [userContent]);
	const [loginPopupShow, setLoginPopupShow] = useState(false);
	const [registerShow, setRegisterShow] = useState(false);
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	
	let languageContent = props.languageContent;
	//console.log("language-Content"+props.language);
	
	
	const [value, setValue] = React.useState([0, 55]);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	const [show, setShow] = React.useState();
	
	// get country data
	let [countryName,setCountryName] = useState([]);
	useEffect(()=> {
		//if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/getcountry`).then((response)=>{
					//console.log(response.data);
					setCountryName(response.data);
				});
			});
		//}
	}, []);
	
	/*let [itemPriceRange,setItemPriceRange]  = useState([]);
	useEffect(()=> {
		//if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/category-item-price-range`).then((response)=>{
					// console.log(response.data);
					//setItemPriceRange(response.data);
				});
			});
		//}
	}, []);*/
	
	
	let [countrySId, setCountryId] = useState(props.countryId);
	const handleCountryChange = (e) => {
      //this.setState({selectedValue: e.target.value})
	  var country_id  = e.target.value;
	  setCountryId(country_id);
	   //alert(country_id);
	   // console.log(country_id);
    }
	
	//let countrydata = (countryId!='') ? countryId: null;
	let countrydata = (props.countryId!='null') ? props.countryId: 'null';
	// console.log("category-country_id:"+countrySId);
	//---------- get them with respect to category --------
	const [likeClases, setLkClass]   = useState([]); 
	const [favsClases, setFavClass] = useState([]);  
	
	const [categoryList, setCategoryList] = useState([]);
	const [cuisines, setCuisines] = useState([]);
	//if(loggedInUserId===null){
	useEffect(()=> {
		setCuisines([]);
		// console.log(countrydata);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-categories/${props.language}`).then((response)=>{
			setCategoryList(response.data);
			//setCuisines(response.data);
			//console.log('category');
			//console.log(response.data);
			const setgetcuisines = response.data.map((item) =>
				//console.log(slug)
				item.slug === slug ? { ...item, checked: !item.checked } : item
			);
			setCuisines(setgetcuisines);
			// console.log(setgetcuisines); // get all the categories for left side
		});
		});
	}, [slug, props.language, props.countryId]);
	//console.log(cuisines);
	const [currencySymb, setCurrencySymb] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [getitemList, setgetItemList] = useState([]);
	const [getminPrice, setgetminPrice] = useState();
	const [getmaxPrice, setgetmaxPrice] = useState();
	const [selectedPrice, setSelectedPrice] = useState([getminPrice, getmaxPrice]);
	
	//-----------------------------------------------------------------------
	
		const [isLoading, setisLoading] = useState(false);
		const [priceFormErrors, setPriceFormErrors] = useState([]);
		let isPriceRangeSubmit = false;
		const [priceRState, priceRSetState] = React.useState({
			minpriceRange: getminPrice,
			maxpriceRange: getmaxPrice,
			priceslug: slug,
			loginUserid: loggedInUserId,
			pricelanguage: props.language,
			countrydata: countrydata
			
		});
		const handlePriceChange = ({ target })=>{
			priceRSetState({ ...priceRState, [target.name]: target.value});
		};
		
		const onPriceRangeSubmit = (e) => {
			
			localStorage.setItem("storeminPrice", priceRState.minpriceRange);
			localStorage.setItem("storemaxPrice", priceRState.maxpriceRange);
			e.preventDefault();
			priceValidate(priceRState);
			//console.log('testtest');
			//console.log(loginState);
			if(isPriceRangeSubmit){
				setisLoading(true);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/category-price-range-search`, priceRState).then(response => {
						//console.log("get-prices");
						//console.log(response.data);
						//console.log("get-prices-end");
						if(response.data.status==200){
							window.location.reload();
							applyFilters();
							//localStorage.setItem("verify_id",response.data.user.id);
							setItemList(response.data.itemData);
							setgetItemList(response.data.itemData);
							setgetminPrice(response.data.minPrice);
							setgetmaxPrice(response.data.maxPrice);
							setSelectedPrice(response.data.minmaxPrice);
							setCurrencySymb(response.data.currency);
							
							//applyFilters();
							/*setTimeout(() => {
								window.location.pathname = "/verify-user";	
							}, 2000)*/
							/*//console.log(response);
							localStorage.setItem("loggedin_user_id",response.data.user.id);
							localStorage.setItem("loggedin_user_name",response.data.user.name);
							localStorage.setItem("loggedin_user_email",response.data.user.email);
							localStorage.setItem("loggedin_user_pic",response.data.user.profile_picture);
							//window.location.reload(false);
							
							toast(response.data.message);
							setLoginPopupShow(false);*/
						}else if(response.data.status==400){
							//console.log(response.data.message);
							const errors = {};
							errors.loginError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
								{response.data.message}
							</div>;
							setPriceFormErrors(errors);
						}else if(response.data.status==600){
							const errors = {};
							errors.minpriceRange = response.data.errors.minpriceRange;
							errors.maxpriceRange = response.data.errors.maxpriceRange;
							setPriceFormErrors(errors);
						}
						setisLoading(false);
					});
				});
			}
		};
		
		const priceValidate = (values) => {
			const errors = {};
			
			if (!values.minpriceRange) {
				isPriceRangeSubmit = false;
				errors.minpriceRange = "req.!";
			}else{
				isPriceRangeSubmit = true;
			}
			if (!values.maxpriceRange) {
				isPriceRangeSubmit = false;
				errors.maxpriceRange = "req.!";
			}else{
				isPriceRangeSubmit = true;
			}
			setPriceFormErrors(errors);
			return isPriceRangeSubmit;
		}
	
	// console.log("after submit-prices:-"+priceRState.minpriceRange);
	// console.log("after submit-prices:-"+priceRState.maxpriceRange);
	// console.log("item-length1"+itemList.length);
	
	
	let storeMinPrice   =  (localStorage.getItem("storeminPrice")!=null) ? localStorage.getItem("storeminPrice") : 0;
	let storeMaxPrice   = (localStorage.getItem("storemaxPrice")!=null) ? localStorage.getItem("storemaxPrice") : 0;
	//console.log("store-Min-Price "+storeMinPrice);
	//console.log("store-Max-Price "+storeMaxPrice);
	//----------------------------------------------------------------------
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/category-wise-item-list/${slug}/${loggedInUserId}/${props.language}/${countrySId}/${storeMinPrice}/${storeMaxPrice}`).then((response)=>{		
			// console.log(response.data.itemData);
			setItemList(response.data.itemData);
			setgetItemList(response.data.itemData);
			setgetminPrice(response.data.minPrice);
			setgetmaxPrice(response.data.maxPrice);
			setSelectedPrice(response.data.minmaxPrice);
			
			setCurrencySymb(response.data.currency);
			//console.log(response.data.itemData);
			//applyFilters();
			
			//document.body.classList.remove('disable_load_content');
		});
		});
	}, [props.language,props.countryId,slug]);
	
	//console.log("item-length2"+itemList.length);
	//----- after on click------------------
	
	const likeMeClick = indexLike => {
		var id = itemList[indexLike].id;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/category-item-like/${loggedInUserId}/${id}`).then((response)=>{	
				let itemListSet = [ ...itemList ];
				itemListSet[indexLike] = {...itemListSet[indexLike], likeclass: response.data.likeclass};
				//console.log(itemListSet);
				setItemList(itemListSet);
			});
		 });
	};
	const favouriteMeClick = indexFav => {
		var id = itemList[indexFav].id;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/category-item-favourite/${loggedInUserId}/${id}`).then((response)=>{
				let itemListSet = [ ...itemList ];
				itemListSet[indexFav] = {...itemListSet[indexFav], favouriteclass: response.data.favouriteclass};
				//console.log(itemListSet);
				setItemList(itemListSet);
			});
		 });
	};
	//Filter data start
	const [resultsFound, setResultsFound] = useState(true);
	const handleChangePrice = (event, newValue) => {
		setSelectedPrice(newValue);
	};
	
	/*const handlePriceChk = ({ target })=>{	
		let priceR = target.value;
		 alert(priceR);
	}*/
	
	const handleChangeChk = ({ target })=>{	
		let id = target.value;
		//alert(id);
		const cusinesStateList = cuisines;
		
		const changeCheckedCuisines = cusinesStateList.map((item) =>
			item.id == id ? { ...item, checked: !item.checked } : item
		);
		//console.log(item.id);
		setCuisines(changeCheckedCuisines);
	};
	
	const applyFilters = () => {
		let updatedList = getitemList;
		
		// Category Filter start
		const cuisinesChecked = cuisines
		  .filter((item) => item.checked)
		  .map((item) => item.slug);
		// console.log("checked-data "+cuisinesChecked);
		// console.log("countrydata "+countrydata);
		if (cuisinesChecked.length) {
			//alert(storeMinPrice);alert(storeMaxPrice);
			//alert('hello');
			axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/multi-category-wise-item-list/${loggedInUserId}/${props.language}/${countrydata}/${storeMinPrice}/${storeMaxPrice}`, cuisinesChecked).then(response => {
				//setSelectedPrice(response.data.minmaxPrice);
				// console.log(response.data.itemData);
				updatedList = response.data.itemData;
				updatedList = updatedList.filter((items) =>
					cuisinesChecked.includes(items.cslug)
				);
				
				localStorage.removeItem('storeminPrice');  // search by price min
				localStorage.removeItem('storemaxPrice');  // search by price max
				
				const minPrice = response.data.minPrice;
				const maxPrice = response.data.maxPrice;

				updatedList = updatedList.filter(
					(item) => item.price >= minPrice && item.price <= maxPrice
				);
				// Price Filter end
				setItemList(updatedList);
				// console.log(updatedList);
				!updatedList.length ? setResultsFound(false) : setResultsFound(true);
			});
			});
		}
		// Category Filter end
		
		// Price Filter start
		const minPrice = selectedPrice[0];
		const maxPrice = selectedPrice[1];

		updatedList = updatedList.filter(
			(item) => item.price >= minPrice && item.price <= maxPrice
		);
		// Price Filter end
		
		setItemList(updatedList);
		//console.log(updatedList);
		!updatedList.length ? setResultsFound(false) : setResultsFound(true);
		
	};
	
		useEffect(() => {
			//console.log("hello");
		 //if(countrydata == 0)
			applyFilters();
		}, [selectedPrice, cuisines, props.language, countrydata]);
	
	//Filter data end
	//Sort data by date start
	const handleDateSort = ({ target })=>{
		if(target.value==1){
			const sortByDate = [...itemList];
			let sortedData = sortByDate.sort((a, b) => new Date(...a.created_at.split('/').reverse()) - new Date(...b.created_at.split('/').reverse()));
			setItemList(sortedData);
				//console.log(sortedData);
		}
		if(target.value==2){
			const sortByDate = [...itemList];
			let sortedData = sortByDate.sort((a, b) => new Date(...b.created_at.split('/').reverse()) - new Date(...a.created_at.split('/').reverse()));
			setItemList(sortedData);
				//console.log(sortedData);
		}
	};
	const handlePriceSort = ({ target })=>{
		if(target.value==1){
			const sortByDate = [...itemList];
			let sortedData = sortByDate.sort((a, b) => a['price'] - b['price']);
			setItemList(sortedData);
				//console.log(sortedData);
		}
		if(target.value==2){
			const sortByDate = [...itemList];
			let sortedData = sortByDate.sort((a, b) => b['price'] - a['price']);
			setItemList(sortedData);
				//console.log(sortedData);
		}
	};
	// console.log(itemList);
	//Sort data by date end
	const likeClassStyle = {padding: '4px', borderRadius: '50%', marginRight: '10px',  width:'28px', height:'28px'}
	const unLikeClassStyle = {color: '#3498db', padding: '4px',  borderRadius: '50%', marginRight: '10px', width:'28px', height:'28px'}
	const favourClassStyle ={padding: '4px', borderRadius: '50%', marginRight: '10px',  width:'28px', height:'28px'}
	const unFavourClassStyle = {color: '#3498db', padding: '4px', borderRadius: '50%', marginRight: '10px', width:'28px', height:'28px'}

	
	
	
	const pricetext1 		= {width:'80px', height:'28px'}
	const pricebox 	 		= { display: "flex",  alignItems: "center"}
	const priceRbutton 	 	= { width:'30px', height:'28px', marginTop:'5px', borderRadius: '15%', background:'#C0C0C0'}
	const priceGo           = {borderRadius: '15%',height: "auto"}
	
	return <>
		<section className="page-title desktopView">
			<div className="container">
				<div className="row">
				
					<div className="col-lg-4 col-md-4 col-sm-4 col-12 cDetailsImg">
					{ /*<img className="icon-svg" src={process.env.REACT_APP_SERVER_API_URL + `images/category/${categoryIcon.parent_id}/${categoryIcon.category_icon}`}/>*/ }
					</div>
					<div className="col-lg-4 col-md-4 col-sm-4 col-12">
					<h1>Category List</h1>
					<div className="breadcrumb"><Link to="/">{languageContent.home}</Link>/ {categoryIcon.categoryname}</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div className="products-section-innerpage category_search_parent">
				<div className="container cListContainer">
					{/* <div className="background_left_menu_image_parent"></div> */}
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 categoris-column mobileModalFixed categoriesLeft" style={{display: isOpen ? 'block' : 'none'}}>
						<div className="mobileModalCenter">
						<form id="registerForm" method="post" onSubmit={onPriceRangeSubmit}>
							<div className="filterpopHeader justify-content-between mobileView">
								<div className="cListClose2"><CloseCircleOutline onClick={onClickMenu} /></div>
								<div>Filter</div>
								<label className="mobileView filterMobileSubmit"><button type="submit" className="btn btn-info" disabled={isLoading}>{ isLoading ? (<div className="btnloader"></div> ):('Apply')}</button></label>
							</div>
							<div className="catFilterBody">
						    <h6 className="filter_title mobile_left_menu_title desktopView"><OptionsSharp /> Select Filter</h6>
							<div className="form-group mySelect filterSelect">
								<label className="form-label">Location</label>
								<div className="filterSelectDiv">
								<select className="form-control mobile_input" id="country_id" name="country_id" value={countrySId} onChange={(e) => handleCountryChange(e)}>
									<option value=''>-select_country-</option>
									{countryName.map((val) => {
											return <>	
											<option value={val.id}>{val.country_name}</option>
										</>;
									})}
								</select>
								</div>
							</div>
							<div className="priceRange">
								<h6 className="mobileView">Price Range</h6>
								<h6 className="desktopView">Price Range in {currencySymb}</h6>
								<p className="desktopView">Choose a range below</p>
								{/*<Box sx={{ width: 211 }}>
									{/*<Slider
									getAriaLabel={() => 'Temperature range'}
									value={selectedPrice}
									onChange={handleChangePrice}
									valueLabelDisplay="auto"
									getAriaValueText={valuetext}
									/>*/}
								
								{/*<Slider
										value={selectedPrice}
										onChange={handleChangePrice}
										valueLabelDisplay='on'
										min={getminPrice}
										max={getmaxPrice}
									/>
								</Box>*/}
								{priceFormErrors.loginError}
								<div style={pricebox} className="filterPriceDiv">
								 
								<input type="text" name="minpriceRange" className="form-control mobile_input" style={pricetext1}   onChange={handlePriceChange}/>
								<div className="errorMesg">{priceFormErrors.minpriceRange}</div>&nbsp; To &nbsp;
								<input type="text" name="maxpriceRange" className="form-control mobile_input" style={pricetext1} onChange={handlePriceChange} value={priceRState.maxpriceRange}/>
								<div className="errorMesg">{priceFormErrors.maxpriceRange}</div>
								</div>
								<input type="hidden" name="priceslug" value={slug} onChange={handlePriceChange}/>
								<input type="hidden" name="loginUserid" value={loggedInUserId} onChange={handlePriceChange}/>
								<input type="hidden" name="pricelanguage" value={props.language} onChange={handlePriceChange}/>
								<input type="hidden" name="countrydata" value={countrydata} onChange={handlePriceChange}/>
								<div style={priceRbutton} className="left_mobile_menu_btn_div">
								<button type="submit" className="btn btn-primary left_mobile_menu_btn" style={priceGo}>Go</button>
								</div>
							</div>
							<div className="brandPart">
								<h6>{languageContent.categories}</h6>
							</div>
							<div className="brandPart mobileCatHeight">
								<p className="desktopView">Popular Categories</p>
								<div className="brandType filter_section_category">
								<section>
									{cuisines.map((val) => {
									return <>
										<div key={slug} className="mobileView text-center">
											<input type="checkbox" value={val.id} id={`tab${val.id}`} defaultChecked={slug===val.slug ? true:false} onChange={handleChangeChk} />
											<label className="doubleLine_radioBtn" for={`tab${val.id}`}>
												{/*<DesktopOutline />*/}
												<img src={process.env.REACT_APP_SERVER_API_URL +`images/category/${val.id}/${val.category_icon}`} />
												<span className="tabName filterCatList" >{val.categoryname}</span>
											</label>
										</div>
										<div key={slug} className="desktopView">
											<label className="myCheckbox">{val.categoryname}
												<input type="checkbox" value={val.id} defaultChecked={slug===val.slug ? true:false} onChange={handleChangeChk} />
												<span className="checkmark"></span>
											</label>
										</div>
									</>;
									})}
								</section>
								</div>
							</div>
							</div>
							{/*<div className="distanceRange">								
								<Box sx={{ width: 211 }}>
									<Separator />
									<Typography id="track-false-range-slider" gutterBottom>
										<h6>Distance</h6>
									</Typography>
									<Slider
										track={false}
										aria-labelledby="track-false-range-slider"
										getAriaValueText={valuetext}
										defaultValue={[0, 50]}
										marks={marks}
									/>
								</Box>
							</div>*/}
						</form>
						</div>
						</div>
						{itemList.length ? (
							<div className="col-lg-10 col-md-10 col-sm-9 col-12 products-column categoriesRight categoryPage">
								<div className="mobCat mobileView"><FunnelOutline onClick={onClickMenu}/></div>
								<div className="row">
									<div className="col-lg-10 catSort">
										<div className="sortPrice">
											<div className="form-group mySelect">
												<label className="form-label">{languageContent.sort_by}</label>
												<select className="form-control" onChange={handleDateSort}>
													<option value="0">Date Published</option>
													<option value="1" >Dates in Ascending</option>
													<option value="2" >Dates in Descending</option>
												</select>
											</div>
											<div className="form-group mySelect">
												<label className="form-label priceLabel">{languageContent.price}</label>
												<select className="form-control" onChange={handlePriceSort}>
													<option value="0">Price</option>
													<option value="1" >Low to High</option>
													<option value="2" >High to Low</option>
												</select>
											</div>
										</div>
									</div>
									<div className="col-lg-2 catView">
										<div className="btn-group d-none-mob list-grid viewOnly">
											<small>View</small>
											<Link to="#" id="grid"><GridOutline className="md hydrated" onClick={() => setShow(false)}/></Link>
											<Link to="#" id="list"><ListOutline className="md hydrated" onClick={() => setShow(true)}/></Link>
										</div>
									</div>
								</div>
								{localStorage.getItem("loggedin_user_id") === null ? (
								<Loginmodal languageContent={languageContent} loginPopupShow={loginPopupShow} registerShow={registerShow} onLoginPopupCloseModal={() => setLoginPopupShow(false)} onRegisterPopupCloseModal={() => setRegisterShow(false)} chgUserContent={userContent => setUserContent(userContent)}/>
								) : (
									null
								)}
								<div className={` grid-container ${show ? "listview" : ""}`}>
								{itemList.map((val, i) => {
									return <>
									<div className="col-md-4 wid_50">
										<div className="product-card">
											<Link to={"/item/"+`${val.slug}-id-${val.id}`} className="HomeItemImage">
												<div className="product-image sponsoredBadge">
													<img src={process.env.REACT_APP_SERVER_API_URL + `images/items/${val.id}/${val.image}`} alt="Ferrari" />
													{val.is_sponsered == 1 ? (
													 <span className="sponsored_item">Sponsored</span>) : ''
													}
													<span className="new_price_label">{val.currency_symbol} {val.price}</span>
												</div>	
													
											</Link>
											<div className="product-info">
												{localStorage.getItem("loggedin_user_id") === null ? (
													<div className="like-fav d-flex">
														<Link to value={val.id} onClick={() => setLoginPopupShow(true)} className=""><HeartOutline color={'#3498db'} /></Link>
														<Link to onClick={() => setLoginPopupShow(true)} className=""><StarOutline color={'#3498db'} /></Link>
													</div>
												) : (
												
												<div className="like-fav d-flex">
													<Link to onClick={() => likeMeClick(i)}  className={`catItem_${val.id}`}>
													{val.likeclass==1 ? <Heart color={'#3498db'}/>:<HeartOutline color={'#3498db'}/>}
													
													</Link>
													<Link to value={val.id} onClick={() => favouriteMeClick(i)} data-id="296">
													{val.favouriteclass==1 ? <Star color={'#3498db'}/>:<StarOutline color={'#3498db'}/>}
													</Link>
												</div>
												)}
												<p className="product-price">{val.currency_symbol} {val.price}</p>
												<h3 className="product-title">
													<Link to={"/item/"+`${val.slug}-id-${val.id}`}>
													{val.itemname}</Link>
												</h3>
												<ul>
													<li>
														{val.categoryname}				
													</li>
												</ul>
												<p className="location d-flex">
													<Location />
													<span className="location-text-limit"> {val.location}</span>
												</p>
												<div className="d-flex justify-content-between like_and_fav">
												{localStorage.getItem("loggedin_user_id") === null ? (
													<>
													<div>
														<div className="w-100">
															<Link to value={val.id} onClick={() => setLoginPopupShow(true)} className=""><HeartOutline style={{ color: '#3498db', fontSize: '20px' }} /></Link>&nbsp;<span>Likes</span>

														</div>
													</div>
													<div>
														<Link to onClick={() => setLoginPopupShow(true)} className=""><StarOutline color={'#3498db'} /></Link><span className="likeFavText">&nbsp;Add to favourites</span>
													</div>
													</>
												) : (
													<>
													<div>
														<div className="w-100">
															<Link to value={val.id} onClick={() => likeMeClick(i)} className="">
																{val.likeclass == 1 ? <Heart color={'#3498db'} /> : <HeartOutline color={'#3498db'} />}<span className="likeFavText">likes</span>
															</Link>
														</div>
													</div>
													<div>
														<Link to value={val.id} onClick={() => favouriteMeClick(i)} className="" data-id="296">
														{val.favouriteclass == 1 ? <Star color={'#3498db'} className="likeFavText" /> : <StarOutline color={'#3498db'} />}<span className="likeFavText">add to Favourite</span>
														</Link>
													</div>
													</>
												)}
												</div>
											</div>
											
										</div>
									</div>
									</>;
								})}
								</div>
							</div>
						) : (
							<div className="col-lg-10 col-md-10 col-sm-9 col-12 products-column categoriesRight categoryPage">
							<div className="mobCat"><FunnelOutline onClick={onClickMenu}/></div>
							<div className="noRecord">
								<h6>No Record Found</h6>
							</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default CategoryWiselist;
