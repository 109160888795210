import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Create, MenuOutline, CloseCircleOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FontAwesome from 'react-fontawesome';
import DOMPurify from "dompurify";
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'

import Userleftmenu from './Userleftmenu';
import axios from "axios";

function TermCondition(props) {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	//if(loggedInUserId==null)
	//{
	   //navigate(`/`)
	//}
	let languageContent = props.languageContent;
	//console.log("langu select :-"+props.language);
	const [sponsorItem, setSponsorItem] = useState([]);
	
	useEffect(()=> {
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/aboutus/${props.language}`).then((response)=>{
					// console.log(response.data);
					setSponsorItem(response.data);
					//profileSetState(response.data);
					//document.body.classList.remove('disable_load_content');
				});
			});
		
	}, []);
	
	const [videoPopupShow, setVideoPopupShow] = useState(false);	
	/*const videourl   =  "https://www.youtube.com/watch?v=HTCLGgt1nak";*/
	
	
	function onVideoModalClose() {
		setVideoPopupShow(false);
	}
	
	
	/*function onVideoModalClose() {
		onLoginPopupCloseModal(false);
	}*/	
	//Edit my profile end
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	
	/*let  Background  = "https://about.offerup.com/wp-content/uploads/2017/12/how-it-top.jpg";*/
	
	/*const styleUpperLeftContent = {
		backgroundColor : "#19AA81",
		backgroundImage	: `linear-gradient(-200deg,#19AA81 0,#127770 83%,#106D6D 100%)`,
		height          :  "650px",
		
	}*/
	/*const styleUpperRightContent = {
		height          :  "650px",
		backgroundImage	: `url(${Background})`,
		backgroundSize	: 'cover',
        backgroundRepeat: 'no-repeat',
	}*/
	
	/*const styleUpperLeftSpan1 = {
		height          :  "80px",
		
	}*/
	/*const  styleFont1     = {
		paddingTop		: "20px",
		paddingLeft		: "22px",
		fontSize        : "18px",
		color           : 'white',
	}*/
	/*const styleUnderLine = {
		height  : "1px",
		width   : "380px",
		backgroundColor : "white",
		
	}*/
	/*const  styleUpperLeftSpan2     = {
		height          :  "270px",
	}*/
	/*const  styleFont2     = {
		paddingTop		: "20px",
		paddingLeft		: "22px",
		fontSize        : "32px",
		color           : 'white',
		fontWeight		: "bold",
	}*/
	/*const  styleUpperLeftSpan3     = {
		height          :  "80px",
	}*/
	/*const videoButton   = {
		paddingTop		: "20px",
		paddingLeft		: "40px",
	}*/
	/*const videoButtonSize = {
		backgroundColor : "#127770",
		borderRadius    : "5px",
		borderColor    	: "white",
		fontSize        : "14px",
		fontWeight		: "bold",
	}*/
	
	
	/*const styleUpperLeftSpan4 = {
		paddingTop		: "60px",
	}*/
	
	/*const styleFontFreeApp = {
		fontSize        : "16px",
		color           : 'white',
		paddingLeft		: "18px",
		
	}*/
	/*const styleMiddleRow1 = {
		paddingTop		: "40px",
	}*/
	/*const styleMiddleRow2 = {
		paddingTop		: "10px",
	}*/
	/*const styleMiddleFont1 = {
		fontSize        : "30px",
		color           : "#107869",
		fontWeight		: "bold",
	}*/
	/*const styleMiddleFont2 = {
		fontSize        : "20px",
		
	}*/
	/*const styleMiddleRow3 = {
		paddingTop		: "40px",
		height          : "auto",
	}*/
	const gridContainer = {
		
		display			: "flex",
		flexWrap		: "wrap",
		paddingLeft		: "250px",
		paddingRight	: "-8px",
	}
	/*const styleItemSize = {
		height          : "200px",     
		width           : "205px", 
		borderRadius    : "5px",		
	}*/ 
	/*const productPrice = {
		fontSize        : "20px",
		fontWeight		: "bold",
	}*/
	/*const productViews = {
		fontSize        : "14px",
		fontWeight		: "normal",
	}*/
	
	/*const styleModal = {
		
		height 			: "350px",
		paddingTop		: "15px",
		paddingLeft	    : "1px",
	}*/
	/*const styleModalVideo = {
		width 			: "200px",
		height          : "280",
		paddingButtom		: "50px",
		paddingRight		: "130px",
	}*/
	/*const sanitize = DOMPurify.sanitize;

	const body = {
	  id: 5,
	  name: "shahiraja",
	  title:
		"text below as a natural lead-in to additional content. This content is a little bit l text below as a natural lead-in to ad",
	  image: "/media/resources/Screenshot_from_2021-09-14_19-13-25.png",
	  created_at: "2022-01-03T08:07:44.451476Z",
	  body:
		'<p><iframe frameborder="0" src="//www.youtube.com/embed/LNkdmyHp5Co" width="640" height="360" className="note-video-clip"></iframe><a href="https://www.youtube.com/watch?v=LNkdmyHp5Co"></a><br></p>',
	  category: 1
	};*/
	
	
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
			{/*<div className="container myLeftContainer">*/}
				<div className="col-md-12">
					<div className="row">
					{/*<div className="col-lg-1 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">*/}
						<div className="col-md-12">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
								<div className="row">
										<h1>Term And Condition</h1>
										<div className="breadcrumb"></div>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col-md-12">
					<div className="row">
						1. What is Snapsell?
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum feugiat orci dui, et dignissim lacus auctor vitae. Phasellus tempor ac enim eu lobortis. Vestibulum sagittis consectetur arcu vel sodales. Praesent sed metus ultrices, iaculis purus dignissim, tincidunt quam. Integer non consectetur velit, porttitor tristique arcu. Donec facilisis enim lectus. Donec vestibulum massa nisl, sit amet vehicula mi pharetra at. Cras rutrum cursus sem eu mollis. Praesent condimentum aliquam faucibus. Nunc nec orci nulla. Integer imperdiet maximus erat, eu vehicula lectus accumsan ut. Aenean erat orci, dapibus at orci id, dictum accumsan nisl. In ut vulputate leo. Duis pellentesque est sit amet ornare pellentesque.
					</div>
				</div>
				
				<div className="col-md-12">
					
				</div>
				<div className="col-md-12">
					
				</div>
				<div className="col-md-12">
					
				</div>
				<div className="col-md-12">
					
				</div>
				
			</div>
			
			
		</section>
	</>;
}

export default TermCondition;
