import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { MenuOutline, LockClosedOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Userleftmenu from './Userleftmenu';
import axios from "axios";

import DataTable from "react-data-table-component";
import PaymentDetailsTabs from "./PaymentDetailsTabs";

const PaymentDetails = (props) => {
	/*useEffect(()=> {
			//document.body.classList.add('disable_load_content');
    }, []);*/
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	//console.log("Lang: "+language);
	let languageContent = props.languageContent;
	/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			// console.log(response.data);
		});
		});
	}, [language]);*/
//Language change end
	//----------- data table start ------------
	const [search, setSearch] = React.useState('');
	const [blockUsers, setBlockUsers] = React.useState([]);
	const [filteredUsers, setFilteredUsers] = React.useState([]);
	//get all blocked users
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/get-blocked-users/${loggedInUserId}`).then((response)=>{
			setBlockUsers(response.data);
			setFilteredUsers(response.data);
			//document.body.classList.remove('disable_load_content');
		});
		});
    }, []);
	//Unblock the blocked user
	const handleUnBlock = indexUser => {
		var user_id = filteredUsers[indexUser].user_id;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/block-user/${loggedInUserId}/${user_id}`).then((response)=>{
				
				//remove from filtered data array start
				let userRemSet = [ ...filteredUsers ];
				userRemSet.splice(indexUser, 1);
				//remove from filtered data array start
				
				//remove from main data array start
				let blockUsersRemSet = [ ...blockUsers ];
				blockUsers.map((val, i)=>{
					if(val.user_id === user_id){
						blockUsersRemSet.splice(i, 1);
					}
				})
				//remove from main data array start
				toast('You are successfully unblock this user');
				setBlockUsers(blockUsersRemSet);
				setFilteredUsers(userRemSet);
			});
		});
	};
	//for filter data with search box
	useEffect(()=> {
		const result = blockUsers.filter((user) => {
			return user.name.toLowerCase().match(search.toLowerCase()) || user.mobile.toLowerCase().match(search.toLowerCase()) || user.email.toLowerCase().match(search.toLowerCase()) || user.create_date.toLowerCase().match(search.toLowerCase());
		});
		setFilteredUsers(result);
    }, [search]);
	//datatable column
	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Mobile',
			selector: (row) => row.mobile,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Blocked Date',
			selector: (row) => row.create_date,
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row, i) => <span id={i} className="unBlockIcon" onClick={() => handleUnBlock(i)} title="Unblock"><LockClosedOutline/></span>,
		},
	]
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
						<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Payment Details</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}  </Link>/Payment Details</div>
									</div>
								</div>
							</div>
                            <PaymentDetailsTabs props={props}/>
							{/* <div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>{languageContent.block_user}</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}</Link>/ {languageContent.block_user}</div>
									</div>
								</div>
							</div> */}
							{/* <div className="nInfo">
								<div className="App datatable_parent_mobile_for_header">
									<DataTable
										columns={columns} 
										data={filteredUsers} 
										pagination 
										fixedHeader
										// selectableRows
										// selectableRowsHighlight
										highlightOnHover
										subHeader
										pointerOnHover
										striped
										responsive
										subHeaderComponent={
											<input 
												type="text" 
												placeholder={languageContent.search_users} 
												className="w-25 full_width_input_mobile form-control"
												value={search}
												onChange={(e) => setSearch(e.target.value)}
											/>
										}
									/>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;

}

export default PaymentDetails;