import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import PaymentVerifyUserForm from "./PaymentVerifyUserForm"

export default function StripeVerifyUserContainer(props) {
const PUBLIC_KEY = props.publicKey;
console.log(PUBLIC_KEY,'PUBLIC_KEY')

const stripeTestPromise = loadStripe(PUBLIC_KEY)

	// console.log(props.showVerifyPayment);
	function onPaymentClose() {
		props.onPaymentModalClose(false);
	}
	return (
		<Modal className="PaymentverifyUserForm PaymentModal verify_modal_parent" show={props.showVerifyPayment} onHide={e => onPaymentClose(e)}>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<Elements stripe={stripeTestPromise}>
					<PaymentVerifyUserForm verifyAmount={props.verifyAmount} verifyUserId={props.verifyUserId}/>
				</Elements>
			</Modal.Body>
		</Modal>
	)
}