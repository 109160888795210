import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { MenuOutline, LockClosedOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Userleftmenu from './Userleftmenu';
import axios from "axios";

import DataTable from "react-data-table-component";

const Myitemsold = (props) => {
	/*useEffect(()=> {
			document.body.classList.remove('disable_load_content');
    }, []);*/
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	//console.log("Lang: "+language);
	let languageContent = props.languageContent;
	/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			console.log(response.data);
		});
		});
	}, [language]);*/
//Language change end
	//----------- data table start ------------
	const [search, setSearch] = React.useState('');
	const [blockUsers, setBlockUsers] = React.useState([]);
	const [filteredUsers, setFilteredUsers] = React.useState([]);
	
	const [itemsLoad, setItemsLoad] = useState();
	const [visible, setVisible] = useState();
	//get all blocked users
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-users-items-sold/${loggedInUserId}`).then((response)=>{
			// console.log(response);
			setBlockUsers(response.data.items);
			setFilteredUsers(response.data.items);
			//document.body.classList.remove('disable_load_content');
			
			setItemsLoad(response.data.items_load_more);
			setVisible(response.data.items_load_more);
		});
		});
    }, []);
	
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue + itemsLoad);
	};
	//for filter data with search box
	useEffect(()=> {
		const result = blockUsers.filter((user) => {
			return user.buyername.toLowerCase().match(search.toLowerCase()) || user.itemname.toLowerCase().match(search.toLowerCase()) || user.date.toLowerCase().match(search.toLowerCase()) || user.time.toLowerCase().match(search.toLowerCase()) || user.email.toLowerCase().match(search.toLowerCase());
		});
		setFilteredUsers(result);
    }, [search]);
	//datatable column
	const columns = [
		{
			name: 'Buyer Name',
			selector: (row) => row.buyername,
			sortable: true,
		},
		{
			name: 'Item Name',
			selector: (row) => row.itemname,
			sortable: true,
		},
		{
			name: 'Date',
			selector: (row) => row.date,
			sortable: true,
		},
		{
			name: 'Time',
			selector: (row) => row.time,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		
	]
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>My Item Sold</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}</Link>/My Item Sold</div>
									</div>
								</div>
							</div>
							<div className="reviewPart">
								<div className="row">
								{filteredUsers.length ? (
									<div className="col-md-12">
										<div className="row">
										{filteredUsers.slice(0, visible).map((val) => {
										//{currentItems.map((val) => {
											return <>
												<div className="col-md-4 myItemCat mb-2">
													<div className="product-card">
													<Link to={"/item/"+`${val.slug}-id-${val.id}`}>
														<div className="favItem sponsoredBadge">
															<div className="imgPar product-image">
															<img src={process.env.REACT_APP_SERVER_API_URL +`images/items/${val.id}/${val.image}`} alt="Ferrari" />
															<span className="new_price_label myitemPrice mobileView">{val.currency_symbol} {val.price}</span>
															{val.is_sponsered == 1?(
															<span className="sponsored_item">Sponsored</span>) : ''
															}
															</div>														
															<div className="imgDetails desktopView">
																<p className="mt-3">{val.itemname}</p>
																<p>{val.categoryname}</p>
															</div>
															<div className="product-info mobileView">
																<h3 className="product-title">{val.itemname}</h3>
																<ul><li>{val.categoryname}</li></ul>
															</div>
															
														</div>
													</Link>
													</div>
												</div>
											</>;
										})}
										</div>
										{visible < filteredUsers.length && (
											<div className="col-md-12 mt-3 mb-3 text-center">
												<button type="submit" className="btn btn-primary" onClick={loadMoreItems}>Show More</button>
											</div>
										)}
										{/*<div className="pagination">
											<div className="paginationLeft">
												<ReactPaginate
													breakLabel="..."
													nextLabel="next"
													onPageChange={handlePageClick}
													pageRangeDisplayed={2}
													pageCount={pageCount}
													previousLabel="prev"
													nextClassName={""}
													renderOnZeroPageCount={null}
													activeClassName={"active"}
													previousClassName={""}
												/>
											</div>
											<div className="showRight">
												<div className="form-group mySelect"><small>show</small>
													<select className="form-control" onChange={handleShowChange}>
														{lis} 
													</select>
												</div>
											</div>
										</div>*/}
									</div>
								) : (
									<div className="noRecord">
										<h6>No Record Found</h6>
										<Link to="/add-item">Add Item</Link>
									</div>
								)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Myitemsold;