import React, { useState, useEffect } from "react";
import axios from "axios";
import  { useParams, useNavigate, NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Verifyuser(props) {
	const navigate = useNavigate();
	// const {email} = useParams();
	
	//let languageStoredInLocalStorage = localStorage.getItem("language");
	//let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	
	// console.log("language::"+props.language);
	let verify_id = localStorage.getItem("verify_id");
	if(verify_id==null){
		// console.log(verify_id);
		navigate('/');
	}
	const [verifyFormErrors, setVerifyFormErrors] = useState([]);
	const [isLoading, setisLoading] = useState(false);
	let isVerifySubmit = false;
	const [verifyState, verifySetState] = React.useState({
		verify_id: verify_id,
		verify_code: ""
	});
	const handleVerifyChange = ({ target })=>{
		verifySetState({ ...verifyState, [target.name]: target.value});
	};
	
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	//console.log(resetState);
	const onVerifySubmit = (e) => {
		e.preventDefault();
		verifyValidate(verifyState);
		if(isVerifySubmit){
			setisLoading(true);
			//console.log(resetState);
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/verify/${props.language}`, verifyState).then(response => {
					if(response.data.status==200){
						/*const errors = {};
						errors.verifyError = <div className="alert alert-success alert-dismissible fade show" role="alert">
												{response.data.message}
											</div>;						
						setVerifyFormErrors(errors);*/
						localStorage.setItem("loggedin_user_id",response.data.user.id);
						localStorage.setItem("loggedin_user_name",response.data.user.name);
						localStorage.setItem("loggedin_user_email",response.data.user.email);
						localStorage.setItem("loggedin_user_pic",response.data.user.profile_picture);	
						localStorage.removeItem("verify_id");
						if(localStorage.getItem("login_form") == 'add_item'){
							localStorage.removeItem("login_form");
							navigate('/add-item');
						}else{
							navigate('/');
						}
						
						toast(response.data.message);
					}else if(response.data.status==400){
						//console.log(response.data.message);
						const errors = {};
						errors.verify_code = response.data.message;
						setVerifyFormErrors(errors);
					}else if(response.data.status==600){
						const errors = {};
						errors.verify_code = response.data.errors.verify_code;
						setVerifyFormErrors(errors);
					}
					setisLoading(false);
				});
			});
		}
	};
	const verifyValidate = (values) => {
		const errors = {};
		if (!values.verify_code) {
			isVerifySubmit = false;
			errors.verify_code = "Verify code is required!";
		}else{
			isVerifySubmit = true;
		}
		setVerifyFormErrors(errors);
		return isVerifySubmit;
	}
	const resendCode = () => {
		setisLoading(false);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/resendcode`, verifyState).then(response => {
				toast(response.data.message);
			});
		});
	};
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage verify_user_bg">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="top_logo_for_mobile mt-4 mb-5 text-center">
								<img src="https://snapsells.com/images/SnapSells.png" className="mobile_top_logo" alt="" srcset="" />
							</div>
							<div className="top_logo_for_mobile mt-3 text-white text-center">
								<p>We have sent you a verification code 
									on your mobile number
								</p>
							</div>
							<div className="page-title row">
								<div className="col-md-12">
									<h1>User verify</h1>
								</div>
							</div>
							<div className="list-an-item-content verify_user_parent">
								<div className="row upload-right">
									{verifyFormErrors.resetError}
									<form id="forgotpassword" onSubmit={onVerifySubmit}>
										<div className="form-group">
											<label>Enter your verification code here</label>
											<input type="text" className="form-control mobile_input" name="verify_code" value={verifyState.verify_code} onChange={handleVerifyChange} />
										   <div className="errorMesg">{verifyFormErrors.verify_code}</div>
										</div>
										<div className="form-group d-flex justify-content-between justify_center_for_mobile">
										  <div className="forgot "><NavLink className="resend_user_code" style={{textDecoration: 'none'}} to="#" onClick={resendCode}>Resend Code</NavLink></div>
										</div>
										<div className="btn btn-info btn-block btn-round submit verify_btn">
											<button type="submit" className="btn btn-primar " disabled={isLoading}>
												{isLoading ? <div className="btnloader"></div> : "Send"}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Verifyuser;
