import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, TrashOutline, StarOutline, Star, PencilSharp, LogOutOutline,ThumbsUp, ThumbsUpOutline, MenuOutline, CloseCircleOutline, RemoveCircleOutline } from 'react-ionicons';

import Userleftmenu from './Userleftmenu';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
//const Myitem = () => {
function Myitem(props) {
	
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	
	let languageContent = props.languageContent;
	
	/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			// console.log(response.data);
		});
		});
	}, [language]);*/
	
	
	//--- get all item for userid
	const [itemList, setItemList] = useState([]);
	const [getitemsPerPage, setGetItemsPerPage] = useState();
	const [itemsPerPage, setItemsPerPage] = useState();
	const [pagiLoop, setpagiLoop] = useState();
	
	const [itemsLoad, setItemsLoad] = useState();
	const [visible, setVisible] = useState();
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-items/${loggedInUserId}/${props.language}`).then((response)=>{
			//console.log(response.data);
			setItemList(response.data.itemData);
			setItemsPerPage(response.data.per_page_list);
			setGetItemsPerPage(response.data.per_page_list);
			setpagiLoop(response.data.per_page_pagination_loop);
			
			setItemsLoad(response.data.items_load_more);
			setVisible(response.data.items_load_more);
		});
		});
	}, [props.language]);
	
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue + itemsLoad);
	};
	const soldItemClick = value => () => {
		let id = value;
		//alert(id);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/my-item-sold/${loggedInUserId}/${id}/${props.language}`).then((response)=>{	  
				setItemList(response.data.itemData);
				setItemsPerPage(response.data.per_page_list);
				setGetItemsPerPage(response.data.per_page_list);
				setpagiLoop(response.data.per_page_pagination_loop);
			});
		});
	};
	
	
	
	const delete_item = value => () => {
		let expvalue        = value.split("/");
		let Id              = expvalue[0];
		
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/delete-item/${loggedInUserId}/${Id}`).then((response)=>{	
				//console.log(response.data);
				if(response.data.status==200){
					setItemList(response.data.itemData);
					setItemsPerPage(response.data.per_page_list);
					setGetItemsPerPage(response.data.per_page_list);
					setpagiLoop(response.data.per_page_pagination_loop);
					toast(response.data.message);
				}else{
					toast(response.data.message);
				}
			});
		 });
	};
	//console.log(itemList);
	
	//--- pagination start
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const handleShowChange = ({ target })=>{
		setItemsPerPage(target.value);
	};
	
	useEffect(() => {
		const endOffset = Number(itemOffset) + Number(itemsPerPage);
		setCurrentItems(itemList.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(itemList.length / itemsPerPage));
		
		//document.body.classList.remove('disable_load_content');
	}, [itemOffset, itemsPerPage, itemList]);
	
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % itemList.length;
		setItemOffset(newOffset);
	};
	//--- pagination end
	var lis = [];
	for (var i=1; i<pagiLoop+1; i++) {
		lis.push(<option value={getitemsPerPage*i} >{getitemsPerPage*i}</option>);
	}
	//----------------------------
	//const likeClassStyle = {marginRight: '10px', color:'orange', width:'22px', height:'22px'}
	const likeClassStyle = {padding: '4px', background:'#fff', borderRadius: '50%', marginRight: '10px', fill:'orange', width:'28px', height:'28px'}
	const unLikeClassStyle = {color: 'orange', padding: '4px', background:'#fff', borderRadius: '50%', marginRight: '10px', width:'28px', height:'28px'}
	//const unLikeClassStyle = {fill: 'orange', marginRight: '10px', width:'28px', height:'28px'}
	const editClassStyle = {width:'15px', height:'15px',marginRight: '5px'}
	const deleteClassStyle = {width:'20px', height:'20px',marginRight: '5px'}
	
	const [allItemsList, setAllItemsList] = useState([]);
	const likeMeClick = value => () => {
		//console.log(value);
		//alert(value);
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/my-item-like/${loggedInUserId}/${id}/${props.language}`).then((response)=>{	  
				setItemList(response.data.itemData);
				setItemsPerPage(response.data.per_page_list);
				setGetItemsPerPage(response.data.per_page_list);
				setpagiLoop(response.data.per_page_pagination_loop);
			});
		});
	};
	
	//---- viewers item 
	
	const [viewsMyItems, setViewsMyItems] = useState(false);
	const [allItemBuyers, setAllItemBuyers] = useState([]);
	const [viewItemName, setViewItemName] = useState([]);
	const itemsLiveViews = value => () => {
		//alert(value);
		
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/item-view-by-seller/${loggedInUserId}/${value}`).then((response)=>{		
			// console.log(response.data);
			setAllItemBuyers(response.data.viewdetails);
			setViewItemName(response.data.item_name);
		});
		});
	
		setViewsMyItems(true);
	};
	// console.log(allItemBuyers);
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage my_item_parent">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>My {languageContent.list_an_item}</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}</Link>/ {languageContent.list_an_item}</div>
									</div>
								</div>
							</div>
							<div className="reviewPart">
								<div className="row">
								{itemList.length ? (
									<div className="col-md-12">
										<div className="row">
										{itemList.slice(0, visible).map((val) => {
										//{currentItems.map((val) => {
											return <>
												<div className="col-md-4 myItemCat mb-2">
													<div className="product-card">
													<Link to={"/item/"+`${val.slug}-id-${val.id}`}>
														<div className="favItem sponsoredBadge">
															<div className="imgPar product-image">
															<img src={process.env.REACT_APP_SERVER_API_URL +`images/items/${val.id}/${val.image}`} alt="Ferrari" />
															<span className="new_price_label myitemPrice mobileView">{val.currency_symbol} {val.price}</span>
															{val.is_sponsered == 1?(
															<span className="sponsored_item">Sponsored</span>) : ''
															}
															<Link to={"/edit-item/" +`${val.id}`}><PencilSharp style={editClassStyle}/>Edit</Link>
															</div>
															<div className="desktopView">
															<div className="d-flex justify-content-between p-1 ">
																<Link to value={val.id} onClick={soldItemClick(`${val.id}`)}>Sold</Link>
																
																<Link to value={val.id} onClick={itemsLiveViews(`${val.id}`)}>Viewers</Link>
															</div>														
															</div>														
															
															<div className="imgDetails desktopView">
																<h6>
																<span className="myLike">
																
																<Link to value={val.id} onClick={likeMeClick(`${val.id}`)} className="">
																{ val.likeclass==1 ? <ThumbsUp style={likeClassStyle}/> :<ThumbsUpOutline style={unLikeClassStyle} />  }
																</Link>
																
																
																{/*<ThumbsUpOutline style={likeClassStyle}/>*/}
																
																{val.totallike} Likes</span>{val.currency_symbol} {val.price}
																</h6>
																<p className="mt-3">{val.itemname}</p>
																<p>{val.categoryname}</p>
															</div>
															<div className="product-info mobileView">
																<h3 className="product-title">{val.itemname}</h3>
																<ul><li>{val.categoryname}</li></ul>
																<div className="rFav myDelete mobileView">
																<div className="d-flex">
																	<Link className="w-50" to="#" onClick={soldItemClick(`${val.id}`)}><RemoveCircleOutline style={deleteClassStyle}/>Mark as Sold</Link>
																	<Link className="w-50" to="#" onClick={delete_item(`${val.id}`)}><TrashOutline style={deleteClassStyle}/>Delete</Link>
																</div>
																</div>
															</div>
															<div className="rFav myDelete desktopView">
																<Link to="#" onClick={delete_item(`${val.id}`)}><TrashOutline style={deleteClassStyle}/>Delete</Link>
															</div>
														</div>
													</Link>
													</div>
												</div>
											</>;
										})}
										</div>
										{visible < itemList.length && (
											<div className="col-md-12 mt-3 mb-3 text-center">
												<button type="submit" className="btn btn-primary" onClick={loadMoreItems}>Show More</button>
											</div>
										)}
										{/*<div className="pagination">
											<div className="paginationLeft">
												<ReactPaginate
													breakLabel="..."
													nextLabel="next"
													onPageChange={handlePageClick}
													pageRangeDisplayed={2}
													pageCount={pageCount}
													previousLabel="prev"
													nextClassName={""}
													renderOnZeroPageCount={null}
													activeClassName={"active"}
													previousClassName={""}
												/>
											</div>
											<div className="showRight">
												<div className="form-group mySelect"><small>show</small>
													<select className="form-control" onChange={handleShowChange}>
														{lis} 
													</select>
												</div>
											</div>
										</div>*/}
									</div>
								) : (
									<div className="noRecord">
										<h6>No Record Found</h6>
										<Link to="/add-item">Add Item</Link>
									</div>
								)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<Modal show={viewsMyItems} onHide={() => setViewsMyItems(false)}>
			<Modal.Header closeButton>
				<Modal.Title className="w-100 text-center">{viewItemName}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
		    {allItemBuyers.length ? (
			<div>
				{allItemBuyers.map((val) => {
				return <>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label><Link to={"/chat/"+`${val.seller_id}`+"/"+`${val.item_id}`}>{val.buyer}</Link></label>
								</div>
							</div>
						</div>
					</>;
				})}
			</div>
			) : ( 
			   <div className="notiClear text-center">
				<span className="notifyText">No One See The Item</span>
				</div>
			)}
			</Modal.Body>
		</Modal>
	</>;
}

export default Myitem;