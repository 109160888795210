import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, TimeOutline, TrashOutline, BagHandleOutline, CheckmarkSharp, MenuOutline, CloseCircleOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Userleftmenu from './Userleftmenu';
import axios from "axios";
import useGeoLocation from "../../hooks/useGeoLocation";
import MapContainer from "../MapContainer";

const Emergencycontacts = (props) => {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	//Language change start
	let languageStoredInLocalStorage = localStorage.getItem("language");
	let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	
	let languageContent = props.languageContent;
	//console.log("Lang: "+language);
	/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${language}`).then((response)=>{
			setLanguageContent(response.data);
			console.log(response.data);
		});
		});
	}, [language]);*/
//Language change end
	//------for google map------------
	const getLocation = useGeoLocation();
	const [useLocation, setUseLocation] = useState({lat: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LATITUDE, lng: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LONGITUDE});
	useEffect(()=> {
		if(getLocation.status==200){
			const newUserPos = { 
				lat: getLocation.coordinates.lat,
				lng: getLocation.coordinates.lng,
			};
			setUseLocation(newUserPos)
			// console.log("location:"+useLocation)	
		}
	}, [getLocation]);
	const [useAddress, setUseAddress] = useState();
	useEffect(()=> {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+useLocation.lat+','+useLocation.lng+'&key='+process.env.REACT_APP_GOOGLE_MAP_API_KEY)
			.then((response) => response.json())
			.then((responseJson) => {
				//console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));
				//console.log(responseJson.results[0].formatted_address);
				setUseAddress(responseJson.results[0].formatted_address);
		})
	}, [useLocation]);
	// console.log(useAddress);
	//For Ip address
	const [useIp, setUseIp] = useState();
	useEffect(() => {
		fetch('https://ipapi.co/json/')
		// fetch('https://geolocation-db.com/json/')
			.then((response) => response.json())
			.then((responseJson) => {
				//console.log(responseJson.IPv4);
				setUseIp(responseJson.ip);
		})
	}, [useIp]);
	//--------------------------------------
	const [show, setShow] = React.useState();
	const [sponsorPlan, setSponsorPlan] = React.useState([]);
	{/*useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/sponsore-plan`).then((response)=>{		
			console.log(response.data);
			setSponsorPlan(response.data);
		});
		});
}, []);*/}
		const [userContent, setUserContent] = useState([]);
		const [profileRating, profileSetRating] = useState([]);
		useEffect(()=> {
			if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user/${loggedInUserId}`).then((response)=>{
					//console.log(response.data);
					setUserContent(response.data);
					//profileSetState(response.data);
					//document.body.classList.remove('disable_load_content');
					//this.setState({ myArray: [...this.state.myArray, 'new value'] })
					});
				});
			}
		}, [loggedInUserId]);
		
		const [profileState, profileSetState] = useState([]);
		const [getEmgData, setGetEmgData] = useState([]);
		useEffect(()=> {
			if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/get-emergency-contact/${loggedInUserId}`).then((response)=>{
					// console.log(response.data);
					profileSetState(response.data);
					setGetEmgData(response.data);
					//document.body.classList.remove('disable_load_content');
					});
				});
			}
	}, [loggedInUserId]);
	//console.log("fdata "+getEmgData.user_id);
	const [profileFormErrors, setProfileFormErrors] = useState([]);
	let isProfileSubmit = false;
	const [isLoading, setisLoading] = useState(false);	
	const handleProfileChange = ({ target })=>{
			profileSetState({ ...profileState, [target.name]: target.value});
			// console.log(profileState);
	};	
	const onProfileSubmit = (e) => {
			e.preventDefault();
			profileValidate(profileState);
			if(isProfileSubmit){
				setisLoading(true);
				const pData = new FormData();
				pData.append("id", localStorage.getItem("loggedin_user_id"));
				pData.append("name", profileState.name);
				pData.append("email", profileState.email);
				pData.append("mobile_number", profileState.mobile_number);
				pData.append("location", useAddress);
				pData.append("latitude", useLocation.lat);
				pData.append("longitude", useLocation.lng);
				//console.log(pData);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/update-emergency-contact`, pData).then(response => {
						//console.log(response);
						if(response.data.status==200){
							//console.log(response);
							/*localStorage.setItem("loggedin_user_id",response.data.user.id);
							localStorage.setItem("loggedin_user_name",response.data.user.name);
							localStorage.setItem("loggedin_user_email",response.data.user.email);*/
							//userContent.push(response.data.user);
							//console.log(userContent);
							//props.chgUserContent(response.data.user)
							//setUserContent(response.data.user);
							//profileSetState(response.data.user);
							//setProfileImage();
							toast(response.data.message);
						}else if(response.data.status==400){
							//console.log(response.data.message);
							const errors = {};
							errors.editProfileError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
													{response.data.message}
												</div>;
							setProfileFormErrors(errors);
						}else if(response.data.status==600){
							// console.log(response.data);
							const errors = {};
							errors.name = response.data.errors.name;
							errors.email = response.data.errors.email;
							errors.mobile_number = response.data.errors.mobile_number;
							setProfileFormErrors(errors);
						}
						setisLoading(false);
					});
				});
			}
	};	
		
	const profileValidate = (values) => {
			//console.log(values);
			const errors = {};
			const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
			if (!values.name) {
				errors.name = "Name is required!";
			}else if (!values.mobile_number) {
				errors.mobile_number = "Mobile number is required!";
			}else{
				isProfileSubmit = true;
			}
			setProfileFormErrors(errors);
			//console.log(profileFormErrors);
			return isProfileSubmit;
		}	
		
	//--- fetch userdata-----
	//const [userEmergencyData, setuserEmergencyData] = useState([]);
			
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage emergency_contact emergency_contact_parent">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Emergency Contact</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home} </Link>/Emergency Contact</div>
									</div>
								</div>
							</div>
							<div className="nInfo">
							{/*<div className="row sPlans">
							</div>*/}
							{profileFormErrors.editProfileError}
								<form onSubmit={onProfileSubmit} method="post">
									<div className="cPassword">
										<h4>Emergency Contact</h4>
										<div className="">{}</div>
										<div className="mb-3">
											<label for="cupassword" className="form-label">Name</label>
											<input type="text" className="form-control" id="ename" value={profileState.name} onChange={handleProfileChange} name="name" />
											<div className="errorMesg">{profileFormErrors.name}</div>
										</div>
										<div className="mb-3">
											<label for="npassword" className="form-label">{languageContent.email}</label>
											<input type="email" className="form-control" id="eemail" onChange={handleProfileChange} name="email" value={profileState.email} />
											<div className="errorMesg">{profileFormErrors.email}</div>
										</div>
										<div className="mb-3 countryInput">
											<label for="cpassword" className="form-label">{languageContent.mobile_number}</label>
											<PhoneInput enableSearch={true} countryCodeEditable={false} onChange={phone => profileSetState({ ...profileState, ['mobile_number']: phone})} value={profileState.mobile_number} inputProps={{ name: 'mobile_number', className: 'form-control mobileInput' }}/>
										</div>
										<div className="errorMesg">{profileFormErrors.mobile_number}</div>
										
										<div className="col-md-12">
											<div className="addItemMapPart" >
												<MapContainer useZoom={10} useLocation={useLocation} chgUseLocation={useLocation => setUseLocation(useLocation)}/>
												{/*<img src="./images/big_map.jpg" alt="Avatar" />*/}
											</div>
										</div>
										{useAddress ?(
										<input type="text" onChange={handleProfileChange} name="" className="form-control" placeholder="User location" value={useAddress} disabled/>
										) : (<input type="text" onChange={handleProfileChange} name="" className="form-control" placeholder="User location" value="" disabled/>)}
										{getEmgData.user_id == null ? (
										<div className="saveBtn mt-3"><button type="submit" className="btn btn-info" disabled={isLoading}>{ isLoading ? (<div className="btnloader"></div>) : ('Save') }</button></div>
										)
										:
										(
										<div className="saveBtn mt-3"><button type="submit" className="btn btn-info" disabled={isLoading}>{ isLoading ? (<div className="btnloader"></div>) : ('Update') }</button></div>
										)
										}
										
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Emergencycontacts;