import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Button,
  useNavigate,
} from "react-router-dom";
import {
  QrCodeOutline,
  PersonOutline,
  ListOutline,
  AppsOutline,
  ChatbubblesOutline,
  EllipsisHorizontalOutline,
  NotificationsOutline,
  HelpCircleOutline,
  ChatboxEllipsesOutline,
  TicketOutline,
  ChatboxOutline,
  HeartOutline,
  StarOutline,
  Star,
  PencilSharp,
  LogOutOutline,
  CloudUploadOutline,
  DesktopOutline,
  CarOutline,
  HomeOutline,
  FlaskOutline,
  BodyOutline,
  BonfireOutline,
  BusinessOutline,
  KeypadOutline,
  NewspaperOutline,
  BicycleOutline,
  BookOutline,
  AirplaneOutline,
  ServerOutline,
  FlowerOutline,
  MenuOutline,
  CloseCircleOutline,
  Close,
} from "react-ionicons";
import Autocomplete from "react-google-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useGeoLocation from "../../hooks/useGeoLocation";
import MapContainer from "../MapContainer";

import Userleftmenu from "./Userleftmenu";
import axios from "axios";
import Sponsoredplans from "./Sponsored-plans";

function Additem(props) {
  // console.log(props.language);
  // console.log(props.countryId);
  const navigate = useNavigate();
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  if (loggedInUserId == null) {
    navigate(`/`);
  }
  let languageContent = props.languageContent;
  //console.log("lang "+props.languageContent);
  /*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
  const getLocation = useGeoLocation();
  //console.log(getLocation)
  //const useLocation = getLocation.coordinates;
  const [sponsoreState, setSponsorState] = useState(false);
  const [useLocation, setUseLocation] = useState({
    lat: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LATITUDE,
    lng: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LONGITUDE,
  });
  // const [useLocation, setUseLocation] = useState({lat: null, lng: null});
  // const [useLocation, setUseLocation] = useState([]);
  useEffect(() => {
    if (getLocation.status == 200) {
      const newUserPos = {
        lat: getLocation.coordinates.lat,
        lng: getLocation.coordinates.lng,
      };
      setUseLocation(newUserPos);
      //console.log(useLocation)
    }
  }, [getLocation]);

  const [useAddress, setUseAddress] = useState();
  useEffect(() => {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        useLocation.lat +
        "," +
        useLocation.lng +
        "&key=" +
        process.env.REACT_APP_GOOGLE_MAP_API_KEY
    )
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));
        //console.log(responseJson.results[0].formatted_address);
        setUseAddress(responseJson.results[0].formatted_address);
      });
  }, [useLocation]);
  //For Ip address
  const [useIp, setUseIp] = useState();
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      // fetch('https://geolocation-db.com/json/')
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        //console.log(responseJson.IPv4);
        setUseIp(responseJson.ip);
      });
  }, [useIp]);
  //console.log(useAddress);
  //console.log(useLocation);

  const [isLoading, setisLoading] = useState(false);
  const [ItemFormErrors, setItemFormErrors] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const onClickMenu = () => {
    setisOpen(!isOpen);
  };

  //let loggedInUserId = 8;
  //const [itemImage, setItemImage] = React.useState();

  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/categories/${props.language}`).then((response) => {
        //console.log(response.data);
        setCategoryList(response?.data);
        //document.body.classList.remove('disable_load_content');
      });
    });
  }, [props.language]);
  //----- Remove image ----
  //----- multiple file upload
  const [multipleFiles, setmultIpleFiles] = React.useState();
  const [multipleReadImgFiles, setmultIpleReadImgFiles] = useState([]);
  const [multipleReadVidFiles, setmultIpleReadVidFiles] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  let vidFlag = "";
  const multiplefileschange = (e) => {
    setmultIpleFiles(e.target.files);
    //preview start
    const selectedImgFIles = [];
    const selectedVidFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file, i) => {
      if (
        file.type === "video/webm" ||
        file.type === "video/mp4" ||
        file.type === "video/ogv"
      ) {
        if (vidFlag === "") {
          selectedVidFIles[i] = {
            url: [URL.createObjectURL(file)],
          };
          vidFlag = 1;
        } else {
          vidFlag = 2;
          setmultIpleFiles("");
        }
      } else {
        return selectedImgFIles.push(URL.createObjectURL(file));
      }
    });
    if (vidFlag > 1) {
      setmultIpleFiles("");
      const errors = {};
      errors.imageFile = "You can not upload more than one video";
      setItemFormErrors(errors);
    } else {
      const errors = {};
      errors.imageFile = "";
      setItemFormErrors(errors);
      setmultIpleReadVidFiles(selectedVidFIles);
      setmultIpleReadImgFiles(selectedImgFIles);
    }
    //preview end
  };
  const handleUploadImageDelete = (arrImg) => {
    var updatedUploadPhotos = [...multipleFiles]; // make a separate copy of the array
    //var index = updatedUploadPhotos.indexOf(arrImg);
    //console.log(arrImg);
    if (arrImg !== -1) {
      updatedUploadPhotos.splice(arrImg, 1);
      setmultIpleFiles(updatedUploadPhotos);
      //console.log(updatedUploadPhotos);

      //preview start
      const selectedImgFIles = [];
      const selectedVidFIles = [];
      const targetFiles = updatedUploadPhotos;
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file, i) => {
        if (
          file.type === "video/webm" ||
          file.type === "video/mp4" ||
          file.type === "video/ogv"
        ) {
          //return selectedVidFIles.push(URL.createObjectURL(file));
          selectedVidFIles[i] = {
            url: [URL.createObjectURL(file)],
          };
        } else {
          return selectedImgFIles.push(URL.createObjectURL(file));
        }
      });
      setmultIpleReadVidFiles(selectedVidFIles);
      setmultIpleReadImgFiles(selectedImgFIles);
    }
  };
  //---------end----------

  let isItemSubmit = false;

  const [itemState, itemSetState] = React.useState({
    category_id: "",
    name: "",
    price: "",
    location: "",
    description: "",
    country_id: props.countryId,
    is_sponsered: "",
  });

  // get country
  let [countryName, setCountryName] = useState([]);
  let [currencyName, setCurrencyName] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/getcountry`).then((response) => {
        // console.log(response.data);
        setCountryName(response.data);
      });
    });
  }, []);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/get-currency/${itemState.country_id}`)
        .then((response) => {
          // console.log(response.data);
          setCurrencyName(response.data.currency_symbol);
        });
    });
  }, [itemState.country_id]);
  //console.log("country name:-"+countryName);

  //-- for check box value
  /*const handleChange = (e) => {
		const checkedName = e.target.name;
	};*/

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setSponsorState(true);
      itemSetState({ ...itemState, [event.target.name]: event.target.checked });
      isItemSubmit = false;
    } else {
      setSponsorState(false);
      itemSetState({ ...itemState, [event.target.name]: event.target.checked });
      isItemSubmit = true;
    }
  };

  const handleChangeChk = ({ target }) => {
    //let isChecked = target.checked;
    console.log(target, "target", itemState);
    itemSetState({ ...itemState, [target.name]: target.checked });
    // if(itemState.is_sponsered){
    // 	window.location.pathname = "/sponsored-plans";
    // }else{
    // 	window.location.pathname = "/my-item";
    // }
  };

  //console.log(itemState);

  const handleItemChange = ({ target }) => {
    itemSetState({ ...itemState, [target.name]: target.value });
    //[target.name]:target.type === "checkbox" ? target.checked : target.value;
    //console.log(itemState);
  };
  const handlePlaceChange = (place) => {
    setUseLocation({
      ...useLocation,
      ["lat"]: place.geometry.location.lat(),
      ["lng"]: place.geometry.location.lng(),
    });
  };

  const onItemSubmit = (e) => {
    console.log("multipleFiles");

    let arrFile = [];
    e.preventDefault();
    itemValidate(itemState);
    if (isItemSubmit) {
      setisLoading(true);
      const rData = new FormData();
      for (let i = 0; i <= multipleFiles.length; i++) {
        rData.append("image_data[]", multipleFiles[i]);
      }
      rData.append("user_id", loggedInUserId);
      rData.append("category_id", itemState.category_id);
      rData.append("country_id", itemState.country_id);
      rData.append("name", itemState.name);
      rData.append("price", itemState.price);
      rData.append("location", useAddress);
      rData.append("latitude", useLocation.lat);
      rData.append("longitude", useLocation.lng);
      rData.append("description", itemState.description);
      rData.append("is_sponsered", itemState.is_sponsered);
      rData.append("ip_address", useIp);

      axios.get(`/sanctum/csrf-cookie`).then((response) => {
        axios.post(`/api/add-items`, rData).then((response) => {
          if (response.data.status == 200) {
            itemSetState([]);
            setmultIpleReadImgFiles([]);
            setmultIpleReadVidFiles([]);
            //console.log(setmultIpleReadImgFiles.length);
            toast(response.data.message);
            navigate("/my-item")
            // history.push('/sponsored-plans');
            // if(itemState.is_sponsered){
            // 	localStorage.setItem("added_item",response.data.item_id);
            // 	window.location.pathname = "/sponsored-plans";
            // }else{
            	window.location.pathname = "/my-item";
            // }
          } else if (response.data.status == 600) {
            const errors = {};
            errors.name = response.data.errors.name;
            errors.price = response.data.errors.price;
            errors.location = response.data.errors.location;
            errors.description = response.data.errors.description;
            setItemFormErrors(errors);
          }
          setisLoading(false);
        });
      });
    }
  };

  const itemValidate = (values) => {
    const errors = {};
    if (multipleReadImgFiles.length <= 0) {
      isItemSubmit = false;
      errors.imageFile = "Image is required!";
    } else if (!values.category_id) {
      isItemSubmit = false;
      errors.category_id = "Category field is required!";
    } else if (!values.name) {
      isItemSubmit = false;
      errors.name = "Item field is required!";
    } else if (!values.price) {
      isItemSubmit = false;
      errors.price = "Price is required!";
    } else if (!values.description) {
      isItemSubmit = false;
      errors.description = "Description is required!";
    } else {
      isItemSubmit = true;
    }
    setItemFormErrors(errors);
    return isItemSubmit;
  };

  /*const removeSelectedImage = () => {
		setItemImage();
	};	*/

  /*useEffect(()=> {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address=22.6159285,88.4020592&key='+process.env.REACT_APP_GOOGLE_MAP_API_KEY)
			.then((response) => response.json())
			.then((responseJson) => {
				// console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));
		})
	}, []);*/

  console.log(ItemFormErrors, "ItemFormErrors");

  return (
    <>
      <section>
        <ToastContainer />
        <div className="products-section-innerpage add_item_parent">
          <div className="container myLeftContainer">
            <div className="row">
              <Userleftmenu
                state={{ userContent: props.userContent }}
                userContent={props.userContent}
                state={{ languageContent: props.languageContent }}
                languageContent={props.languageContent}
                isOpen={isOpen}
              />
              <div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
                <div className="page-title myPageTitle">
                  <div className="mobCat">
                    <MenuOutline onClick={onClickMenu} />
                  </div>
                  <div className="container">
                    <div className="row">
                      <h1>Add an Item</h1>
                      <div className="breadcrumb">
                        <Link to="/">{languageContent.home} </Link>/ Add an Item
                      </div>
                    </div>
                  </div>
                </div>
                {ItemFormErrors.itemError}
                {/* <form
                  id="itemForm"
                  // onSubmit={onItemSubmit}
                  method="post"
                  enctype="multipart/form-data"
                > */}
                  <div className="videoUpload">
                    <h6>
                      Upload Item video and Images
                      <small>(upload up to 20 photos)</small>
                    </h6>
                    <div className="mediaUpload clearfix mobile_display_flex">
                      <div className="videoLoad">
                        {multipleReadVidFiles.length ? (
                          <div>
                            {multipleReadVidFiles.map((val, i) => {
                              return (
                                <>
                                  <div key={val.url}>
                                    <video width="320" height="240" controls>
                                      <source src={val.url} />
                                    </video>
                                    <span
                                      onClick={() => handleUploadImageDelete(i)}
                                      id={i}
                                    >
                                      <Close />
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          <div key="" className="desktopView">
                            <video width="320" height="240" controls>
                              <source src="" type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                      </div>
                      <div className="imageLoad">
                        <div className="uploadFile">
                          <CloudUploadOutline />
                          <p>Drag and drop or click here</p>

                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => multiplefileschange(e)}
                            name="itemImage[]"
                            id="itemImage"
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                    <div className="errorMesg">{ItemFormErrors.imageFile}</div>
                    <div className="mediaUpload clearfix">
                      {multipleReadImgFiles ? (
                        <div className="imageUploaded">
                          {multipleReadImgFiles.map((url, j) => {
                            return (
                              <>
                                <div>
                                  <img src={url} alt="Avatar" />
                                  <span>
                                    <Close />
                                  </span>
                                  <span
                                    onClick={() => handleUploadImageDelete(j)}
                                  >
                                    <Close />
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <h6 className="mt-3 mobile_text_center">
                      {languageContent.select_a_category}
                    </h6>
                    <div>
                      <div className="default_radio_button">
                        <section>
                          {categoryList.map((val) => {
                            return (
                              <>
                                <div>
                                  <input
                                    className="escrow_tab"
                                    type="radio"
                                    id={`tab${val.id}`}
                                    value={val.id}
                                    name="category_id"
                                    onChange={handleItemChange}
                                  />
                                  <label
                                    className="doubleLine_radioBtn"
                                    for={`tab${val.id}`}
                                  >
                                    {/*<DesktopOutline />*/}
                                    <img
                                      src={
                                        process.env.REACT_APP_SERVER_API_URL +
                                        `images/category/${val.id}/${val.category_icon}`
                                      }
                                    />
                                    <span className="tabName">
                                      {val.categoryname}
                                    </span>
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </section>
                        <div className="errorMesg">
                          {ItemFormErrors.category_id}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label for="dName" className="form-label">
                              Select Country
                            </label>
                            <select
                              className="form-control"
                              id="country_id"
                              name="country_id"
                              onChange={handleItemChange}
                              value={itemState.country_id}
                            >
                              <option value="">-select_country-</option>
                              {countryName.map((val) => {
                                return (
                                  <>
                                    <option value={val.id}>
                                      {val.country_name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                            <div className="errorMesg">
                              {ItemFormErrors.country_id}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label for="dName" className="form-label">
                              Item Name
                            </label>
                            <input
                              type="text"
                              onChange={handleItemChange}
                              className="form-control"
                              placeholder="Item Name"
                              id="name"
                              name="name"
                            />
                            <div className="errorMesg">
                              {ItemFormErrors.name}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label for="mNumber" className="form-label">
                              Item Price
                            </label>
                            <div className="input-group">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                {currencyName}
                              </span>
                              <input
                                type="text"
                                onChange={handleItemChange}
                                id="price"
                                name="price"
                                className="form-control"
                                placeholder="Item Price"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                            <div className="errorMesg">
                              {ItemFormErrors.price}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label for="emailType" className="form-label">
                              Seller Location
                            </label>
                            <div className="form-group mySelect">
                              {/*<select className="form-control" onChange={handleItemChange} name="location" id="location">
														<option value="0">Select Location</option>
														<option value="1" >Kolkata</option>
														<option value="2" >Chennai</option>
														<option value="4" >America</option>
												</select>*/}
                              <Autocomplete
                                apiKey={
                                  process.env.REACT_APP_GOOGLE_MAP_API_KEY
                                }
                                onPlaceSelected={handlePlaceChange}
                                className="form-control"
                                placeholder="Search location here"
                                options={{
                                  types: ["geocode", "establishment"],
                                }}
                              />
                              {useAddress ? (
                                <input
                                  type="hidden"
                                  onChange={handleItemChange}
                                  name="location"
                                  className="form-control"
                                  placeholder="User location"
                                  value={useAddress}
                                  disabled
                                />
                              ) : (
                                <input
                                  type="hidden"
                                  onChange={handleItemChange}
                                  name="location"
                                  className="form-control"
                                  placeholder="User location"
                                  value=""
                                  disabled
                                />
                              )}
                            </div>
                            <div className="errorMesg">
                              {ItemFormErrors.location}
                            </div>
                          </div>
                          <div className="form-group mySelect">
                            <input
                              type="text"
                              onChange={handleItemChange}
                              name="location"
                              className="form-control"
                              placeholder="User location"
                              value={useAddress}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="addItemMapPart">
                            <MapContainer
                              useZoom={10}
                              useLocation={useLocation}
                              chgUseLocation={(useLocation) =>
                                setUseLocation(useLocation)
                              }
                            />
                            {/*<img src="./images/big_map.jpg" alt="Avatar" />*/}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label for="emailType" className="form-label">
                              Item Description
                            </label>
                            <div className="form-group myTextarea">
                              <textarea
                                id="description"
                                name="description"
                                className="form-control"
                                rows="3"
                                onChange={handleItemChange}
                              ></textarea>
                              <div className="errorMesg">
                                {ItemFormErrors.description}
                              </div>
                            </div>
                          </div>
                          <div className="switchCheckbox">
                            <div className="form-check form-switch mobile_text_center radioSwitchCheck">
                              <label
                                className="form-check-label"
                                for="flexSwitchCheckChecked"
                              >
                                Make as Sponsored Item
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                id="is_sponsered"
                                name="is_sponsered"
                              />
                            </div>
                            <div className="errorMesg">
                              {ItemFormErrors.is_sponsered}
                            </div>
                          </div>
                        </div>

                        {isChecked ? <Sponsoredplans props={props} /> : null}

                        {/* {Object.keys(ItemFormErrors).length <= 0 && (
												<div className="errorMesg">Please fill all required input.</div>
											)} */}
                        <div className="col-md-12 mt-3 mobile_text_center">
                          <button
                            // type="submit"
                            onClick={(e) => onItemSubmit(e)}
                            className="btn btn-primary w-100"
                          >
                            {isLoading ? (
                              <div className="btnloader"></div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                {/* </form> */}
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Additem;
