import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Link,
  Button,
  useParams,
  useNavigate,
} from "react-router-dom";
import {
  QrCodeOutline,
  PersonOutline,
  ListOutline,
  AppsOutline,
  ChatbubblesOutline,
  EllipsisHorizontalOutline,
  NotificationsOutline,
  HelpCircleOutline,
  ChatboxEllipsesOutline,
  TicketOutline,
  ChatboxOutline,
  HeartOutline,
  StarOutline,
  Star,
  PencilSharp,
  LogOutOutline,
  Close,
  CarOutline,
  Location,
  MenuOutline,
  CloseCircleOutline,
  CloudUploadOutline,
} from "react-ionicons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "react-google-autocomplete";
import Userleftmenu from "./Userleftmenu";
import axios from "axios";
import MapContainer from "../MapContainer";
import Sponsoredplans from "./Sponsored-plans";

const Edititem = (props) => {
  /*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/

  const navigate = useNavigate();
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  if (loggedInUserId == null) {
    navigate(`/`);
  }
  let languageContent = props.languageContent;
  const [isLoading, setisLoading] = useState(false);
  const [ItemFormErrors, setItemFormErrors] = useState([]);

  const [isOpen, setisOpen] = useState(false);
  const onClickMenu = () => {
    setisOpen(!isOpen);
  };
  const { id } = useParams();

  //---- get only item details----
  // const [useLocation, setUseLocation] = useState({lat: null, lng: null});
  const [useLocation, setUseLocation] = useState({
    lat: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LATITUDE,
    lng: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LONGITUDE,
  });
  //console.log(useLocation);
  const [useAddress, setUseAddress] = useState();
  useEffect(() => {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        useLocation.lat +
        "," +
        useLocation.lng +
        "&key=" +
        process.env.REACT_APP_GOOGLE_MAP_API_KEY
    )
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));
        //console.log("address-: "+responseJson.results[0].formatted_address);
        setUseAddress(responseJson.results[0].formatted_address);
      });
  }, [useLocation]);
  // console.log("check address-: "+useAddress);
  //For Ip address
  const [useIp, setUseIp] = useState();
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      // fetch('https://geolocation-db.com/json/')
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson.IPv4);
        setUseIp(responseJson.ip);
      });
  }, [useIp]);
  const [editItemsImage, setEditItemsImage] = useState([]);
  const [editItemsData, setEditItemsData] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/edit-item/${id}`).then((response) => {
        // console.log(response?.data?.itemData,"editItemsData")
        setEditItemsData(response.data.itemData);
        setEditItemsImage(response.data.image);
        setUseLocation({
          lat: response.data.itemData.latitude,
          lng: response.data.itemData.longitude,
        });
      });
    });
  }, []);
  //----- category data ----
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/categories/${props.language}`).then((response) => {
        setCategoryList(response.data);
        //document.body.classList.remove('disable_load_content');
      });
    });
  }, []);

  // get country
  //const [currentValue, setCurrentValue] = useState(99);
  let [countryName, setCountryName] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/getcountry`).then((response) => {
        // console.log(response.data);
        setCountryName(response.data);
      });
    });
  }, []);
  let [currencyName, setCurrencyName] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/get-currency/${editItemsData.country_id}`)
        .then((response) => {
          // console.log(response.data);
          setCurrencyName(response.data.currency_symbol);
        });
    });
  }, [editItemsData.country_id]);
  //----- Remove image ----
  const [deletedImageList, setDeletedImageList] = useState([]);
  const handleImageDelete = (remImg) => {
    let arrIndex = editItemsImage.findIndex((obj) => obj.image === remImg);
    const updatedPhotos = [...editItemsImage];
    updatedPhotos.splice(arrIndex, 1);
    //console.log(editItemsImage);
    //console.log(updatedPhotos);
    setDeletedImageList({ ...deletedImageList, [arrIndex]: remImg }); //store removed image
    setEditItemsImage(updatedPhotos);
  };
  //----- multiple file upload
  const [multipleFiles, setmultIpleFiles] = React.useState();
  const [multipleReadImgFiles, setmultIpleReadImgFiles] = useState([]);
  const [multipleReadVidFiles, setmultIpleReadVidFiles] = useState([]);
  let vidFlag = "";
  const multiplefileschange = (e) => {
    setmultIpleFiles(e.target.files);
    //console.log(e.target.files);
    //preview start
    const selectedImgFIles = [];
    const selectedVidFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file, i) => {
      if (
        file.type === "video/webm" ||
        file.type === "video/mp4" ||
        file.type === "video/ogv"
      ) {
        if (vidFlag === "") {
          selectedVidFIles[i] = { url: [URL.createObjectURL(file)] };
          vidFlag = 1;
        } else {
          vidFlag = 2;
          setmultIpleFiles("");
        }
      } else {
        return selectedImgFIles.push(URL.createObjectURL(file));
      }
    });
    if (vidFlag > 1) {
      setmultIpleFiles("");
      const errors = {};
      errors.imageFile = "You can not upload more than one video";
      setItemFormErrors(errors);
    } else {
      const errors = {};
      errors.imageFile = "";
      setItemFormErrors(errors);
      setmultIpleReadVidFiles(selectedVidFIles);
      setmultIpleReadImgFiles(selectedImgFIles);
    }
    //console.log(setCount);
    //console.log(setmultIpleFiles);
    // console.log(selectedImgFIles);
    //console.log(e.target.files);
    //preview end
  };
  //console.log(multipleReadVidFiles);
  //const [deletedImageList, setDeletedImageList] = useState([]);
  const handleUploadImageDelete = (arrImg) => {
    var updatedUploadPhotos = [...multipleFiles]; // make a separate copy of the array
    //var index = updatedUploadPhotos.indexOf(arrImg);
    //console.log(arrImg);
    if (arrImg !== -1) {
      updatedUploadPhotos.splice(arrImg, 1);
      setmultIpleFiles(updatedUploadPhotos);
      //console.log(updatedUploadPhotos);

      //preview start
      const selectedImgFIles = [];
      const selectedVidFIles = [];
      const targetFiles = updatedUploadPhotos;
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file, i) => {
        if (
          file.type === "video/webm" ||
          file.type === "video/mp4" ||
          file.type === "video/ogv"
        ) {
          //return selectedVidFIles.push(URL.createObjectURL(file));
          selectedVidFIles[i] = { url: [URL.createObjectURL(file)] };
        } else {
          return selectedImgFIles.push(URL.createObjectURL(file));
        }
      });
      setmultIpleReadVidFiles(selectedVidFIles);
      setmultIpleReadImgFiles(selectedImgFIles);
    }
  };
  //console.log(multipleFiles);
  //----- change event start
  const handleChangeChk = ({ target }) => {
    if (target.checked === true) {
      let isCheckedValue = 1;
      setEditItemsData({ ...editItemsData, [target.name]: isCheckedValue });
    } else {
      let isCheckedValue = 0;
      setEditItemsData({ ...editItemsData, [target.name]: isCheckedValue });
    }
  };
  const handleItemChange = ({ target }) => {
    setEditItemsData({ ...editItemsData, [target.name]: target.value });
  };
  //----- change event end
  const handlePlaceChange = (place) => {
    //alert(place.geometry.location.lng);
    //console.log("place:-"+place);
    setUseLocation({
      ...useLocation,
      ["lat"]: place.geometry.location.lat(),
      ["lng"]: place.geometry.location.lng(),
    });
  };

  //----- Form submit start
  let isItemSubmit = false;
  const onItemSubmit = (e) => {
    e.preventDefault();
    let arrFile = [];
    itemValidate(editItemsData);
    setisLoading(true);
    const rData = new FormData();
    if (multipleFiles) {
      for (let i = 0; i <= multipleFiles.length; i++) {
        rData.append("image_data[]", multipleFiles[i]);
      }
    }
    //console.log(rData);
    //console.log(deletedImageList);
    rData.append("rem_image", JSON.stringify(deletedImageList));
    rData.append("user_id", loggedInUserId);
    rData.append("item_id", id);
    rData.append("category_id", editItemsData.category_id);
    rData.append("country_id", editItemsData.country_id);
    rData.append("name", editItemsData.name);
    rData.append("price", editItemsData.price);
    rData.append("location", useAddress);
    rData.append("latitude", useLocation.lat);
    rData.append("longitude", useLocation.lng);
    rData.append("description", editItemsData.description);
    rData.append("is_sponsered", editItemsData.is_sponsered);
    rData.append("ip_address", useIp);
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.post(`/api/edit-items`, rData).then((response) => {
        //console.log(response.data);
        if (response.data.status == 200) {
          setEditItemsData(response.data.editData.itemData);
          setEditItemsImage(response.data.editData.image);
          setmultIpleReadImgFiles([]);
          setmultIpleReadVidFiles([]);
          navigate("/my-item");
          console.log(response.data.message, "response.data.message");
          toast(response.data.message);
          //console.log(setmultIpleReadImgFiles.length);
          // localStorage.setItem("added_item",response.data.item_id);
          // window.location.pathname = "/sponsored-plans";
          /*const errors = {};
						errors.itemError = <div className="alert alert-success alert-dismissible fade show" role="alert">
												{response.data.message}
											</div>;
						setItemFormErrors(errors);*/
        }
        setisLoading(false);
      });
    });
  };
  //console.log(multipleReadVidFiles);
  //----- Form validation start
  const itemValidate = (values) => {
    const errors = {};
    /*if (multipleReadImgFiles.length <= 0) {
			isItemSubmit = false;
			errors.imageFile = "Image is required!";
			
		}else*/ if (!values.category_id) {
      isItemSubmit = false;
      errors.category_id = "Category field is required!";
    } else if (!values.name) {
      isItemSubmit = false;
      errors.name = "Item field is required!";
    } else if (!values.price) {
      isItemSubmit = false;
      errors.price = "Price is required!";
    } else if (!values.description) {
      isItemSubmit = false;
      errors.description = "Description is required!";
    } else {
      isItemSubmit = true;
    }
    setItemFormErrors(errors);
    return isItemSubmit;
  };
  //console.log(useLocation);
  //useEffect(() => { console.log(useLocation) }, [useLocation])
  //-------------------------------------------
  return (
    <>
      <section>
        <ToastContainer />
        <div className="products-section-innerpage edit_item_parent">
          <div className="container myLeftContainer">
            <div className="row">
              <Userleftmenu
                state={{ userContent: props.userContent }}
                userContent={props.userContent}
                state={{ languageContent: props.languageContent }}
                languageContent={props.languageContent}
                isOpen={isOpen}
              />
              <div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
                <div className="page-title myPageTitle">
                  <div className="mobCat">
                    <MenuOutline onClick={onClickMenu} />
                  </div>
                  <div className="container">
                    <div className="row">
                      <h1>Edit Item</h1>
                      <div className="breadcrumb">
                        <Link to="/">{languageContent.home} </Link>/ Edit an
                        Item
                      </div>
                    </div>
                  </div>
                </div>
                {/* <form
                  id="itemForm"
                  onSubmit={(e) => onItemSubmit(e)}
                  method="post"
                  enctype="multipart/form-data"
                > */}
                  <div className="videoUpload">
                    <h6>Uploaded video and Images</h6>
                    <div className="mediaUpload clearfix mobile_display_flex">
                      <div className="videoLoad">
                        {multipleReadVidFiles.length ? (
                          <div>
                            {multipleReadVidFiles.map((val, i) => {
                              return (
                                <>
                                  <div className="uploadedVideo" key={val.url}>
                                    <video width="320" height="240" controls>
                                      <source src={val.url} />
                                    </video>
                                    <span
                                      onClick={() => handleUploadImageDelete(i)}
                                      id={i}
                                    >
                                      <Close />
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          <div key="">
                            <video width="320" height="240" controls>
                              <source src="" type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                      </div>
                      <div className="imageLoad">
                        <div className="uploadFile">
                          <CloudUploadOutline />
                          <p>
                            Drag and drop file here<small>or</small>
                          </p>
                          <button className="btn btn-info">
                            Browse for file
                          </button>
                          <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => multiplefileschange(e)}
                            name="itemImage[]"
                            id="itemImage"
                            multiple
                          />
                        </div>
                      </div>
                      <div className="errorMesg">
                        {ItemFormErrors.imageFile}
                      </div>
                    </div>
                    <div className="mediaUpload clearfix">
                      {multipleReadImgFiles ? (
                        <div className="imageUploaded">
                          {multipleReadImgFiles.map((url, j) => {
                            return (
                              <>
                                <div>
                                  <img src={url} alt="Avatar" />
                                  <span>
                                    <Close />
                                  </span>
                                  <span
                                    onClick={() => handleUploadImageDelete(j)}
                                  >
                                    <Close />
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="mediaUpload clearfix">
                      {/*<div className="videoUploaded">
										<div><img src="./upload/user/video.jpg" alt="Avatar" /></div>
										<Link to="#"><Close/></Link>
									</div>*/}
                      <div className="videoLoad">
                        <div>
                          <div key={editItemsData.video}>
                            <video width="320" height="240" controls>
                              <source src={editItemsData.video} />
                            </video>
                          </div>
                        </div>
                      </div>
                      <div className="imageUploaded">
                        {editItemsImage.map((val, i) => {
                          return (
                            <>
                              <div key={val.iid}>
                                <img
                                  src={
                                    process.env.REACT_APP_SERVER_API_URL +
                                    `images/items/${val.id}/${val.image}`
                                  }
                                  alt="Avatar"
                                />
                                <span
                                  id={val.iid}
                                  onClick={() => handleImageDelete(val.image)}
                                >
                                  <Close />
                                </span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="editCategory">
                      <h6 className="mt-3 mobile_text_center">
                        Select a Category
                      </h6>
                      <div>
                        <div className="default_radio_button">
                          <section>
                            {categoryList.map((val) => {
                              return (
                                <>
                                  <div>
                                    <input
                                      className="escrow_tab"
                                      type="radio"
                                      id={`tab${val.id}`}
                                      value={val.id}
                                      name="category_id"
                                      onChange={handleItemChange}
                                      checked={
                                        editItemsData.category_id == val.id
                                      }
                                    />
                                    <label
                                      className="doubleLine_radioBtn"
                                      for={`tab${val.id}`}
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_SERVER_API_URL +
                                          `images/category/${val.id}/${val.category_icon}`
                                        }
                                      />
                                      <span className="tabName">
                                        {val.categoryname}
                                      </span>
                                    </label>
                                  </div>
                                </>
                              );
                            })}
                          </section>
                          <div className="errorMesg">
                            {ItemFormErrors.category_id}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label for="name" className="form-label">
                              Country Name
                            </label>
                            <select
                              className="form-control"
                              id="country_id"
                              name="country_id"
                              onChange={handleItemChange}
                              value={editItemsData.country_id}
                            >
                              <option value="">-select_country-</option>
                              {countryName.map((val) => {
                                return (
                                  <>
                                    <option value={val.id}>
                                      {val.country_name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                            <div className="errorMesg">
                              {ItemFormErrors.country_id}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label for="name" className="form-label">
                              Item Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={editItemsData.name}
                              onChange={handleItemChange}
                            />
                            <div className="errorMesg">
                              {ItemFormErrors.name}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label for="mNumber" className="form-label">
                            Item Price
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              {currencyName}
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Item Price"
                              name="price"
                              aria-describedby="basic-addon1"
                              value={editItemsData.price}
                              onChange={handleItemChange}
                            />
                            <div className="errorMesg">
                              {ItemFormErrors.price}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label for="emailType" className="form-label">
                              Seller Location
                            </label>
                            <div className="form-group mySelect">
                              {/*<select className="form-control" id="location" name="location" value={editItemsData.location} onChange={handleItemChange}>
														<option value="0">Select Location</option>
														<option value="1" >4741 Radio Park Drive, Columbus, GA</option>
														<option value="2" >Chennai</option>
														<option value="3" >America</option>
												</select>*/}
                              {useAddress ? (
                                <>
                                  <Autocomplete
                                    apiKey={
                                      process.env.REACT_APP_GOOGLE_MAP_API_KEY
                                    }
                                    onPlaceSelected={handlePlaceChange}
                                    className="form-control"
                                    options={{
                                      types: ["geocode", "establishment"],
                                    }}
                                    defaultValue={useAddress || useLocation}
                                  />
                                  <input
                                    type="hidden"
                                    onChange={handleItemChange}
                                    name="location"
                                    className="form-control"
                                    placeholder="User location"
                                    value={useAddress}
                                    disabled
                                  />
                                </>
                              ) : (
                                <>
                                  <Autocomplete
                                    apiKey={
                                      process.env.REACT_APP_GOOGLE_MAP_API_KEY
                                    }
                                    onPlaceSelected={handlePlaceChange}
                                    className="form-control"
                                    placeholder="Search location here"
                                    options={{
                                      types: ["geocode", "establishment"],
                                    }}
                                  />
                                  <input
                                    type="hidden"
                                    onChange={handleItemChange}
                                    name="location"
                                    className="form-control"
                                    placeholder="User location"
                                    value=""
                                    disabled
                                  />
                                </>
                              )}
                            </div>
                            <div className="errorMesg">
                              {ItemFormErrors.location}
                            </div>
                            <div className="form-group mySelect">
                              <input
                                type="text"
                                onChange={handleItemChange}
                                name="location"
                                className="form-control"
                                placeholder="User location"
                                value={useAddress}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="addItemMapPart">
                            <MapContainer
                              useZoom={10}
                              useLocation={useLocation}
                              chgUseLocation={(useLocation) =>
                                setUseLocation(useLocation)
                              }
                            />
                            {/*<img src="./images/big_map.jpg" alt="Avatar" />*/}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label for="emailType" className="form-label">
                              Item Description
                            </label>
                            <div className="form-group myTextarea">
                              <textarea
                                className="form-control"
                                name="description"
                                rows="5"
                                value={editItemsData.description}
                                onChange={handleItemChange}
                              ></textarea>
                              <div className="errorMesg">
                                {ItemFormErrors.description}
                              </div>
                            </div>
                          </div>
                          <div className="switchCheckbox mobile_text_center">
                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                                for="flexSwitchCheckChecked"
                              >
                                Make as Sponsored Item
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="is_sponsered"
                                checked={
                                  editItemsData.is_sponsered === 0
                                    ? ""
                                    : "checked"
                                }
                                id="flexSwitchCheckChecked"
                                onChange={handleChangeChk}
                              />
                            </div>
                            <div className="errorMesg">
                              {ItemFormErrors.is_sponsered}
                            </div>
                          </div>
                        </div>
                        <div>
                  {editItemsData.is_sponsered === 0 ? null : (
                    <Sponsoredplans props={props} page={"edit"} />
                  )}
                </div>

                        {/* {Object.keys(ItemFormErrors).length <= 0 ? null : (
                          <div className="errorMesg">
                            Please fill all required input.
                          </div>
                        )} */}
                        <div className="col-md-12 mt-3 mobile_text_center">
                          <button
                            // type="submit"
                            onClick={(e)=>onItemSubmit(e)}
                            className="btn btn-primary w-100"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <div className="btnloader"></div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                {/* </form> */}
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Edititem;
