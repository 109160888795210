import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useParams} from 'react-router-dom';
import axios from "axios";


function Itemdetails(props) {
	
	const { slug } = useParams();
    //console.log(slug);
    let expSlug     = slug.split("-");
	let index       = (expSlug.length)-1;
	let ItemId      = expSlug[index];
	//console.log(ItemId);
	
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	const [itemPrice, setItemPrice] = useState([]);  
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/item-details/${loggedInUserId}/${slug}`).then((response)=>{		
			setItemPrice(response.data[0].price);
		});
		});
	}, []);
	
	return <>
									<div className="priceShare">
										<h6>Price</h6>
										<p>${itemPrice}</p>
									</div>
		
	</>;
	
	
	
}

export default Itemdetails;
