import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Create, MenuOutline, CloseCircleOutline, Camera } from 'react-ionicons';
import ReactStars from "react-rating-stars-component";  

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Userleftmenu from './Userleftmenu';
import axios from "axios";

function Myprofile(props) {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	let languageContent = props.languageContent;
	//console.log("la "+props.languageContent);
	/*const location = useLocation();
	const { userContent } = location.state;*/
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	
	//console.log(loggedInUserId);
	const [userContent, setUserContent] = useState([]);
	const [profileState, profileSetState] = useState([]);
	const [profileRating, profileSetRating] = useState([]);
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user/${loggedInUserId}`).then((response)=>{
					//console.log(response.data);
					setUserContent(response.data);
					profileSetRating(response.data.star_rating)
					profileSetState(response.data);
					// console.log(response.data);
					//document.body.classList.remove('disable_load_content');
					//this.setState({ myArray: [...this.state.myArray, 'new value'] })
				});
			});
		}
	}, [loggedInUserId]);
	
	//console.log(props.userContent);
	//let userContent = props.userContent;
	//edit my profile start
		const [profileFormErrors, setProfileFormErrors] = useState([]);
		let isProfileSubmit = false;
		//let loading = false;
		const [isLoading, setisLoading] = useState(false);
		/*const [profileState, profileSetState] = useState({
			profile_picture: '',
			name: userContent.name,
			email: userContent.email,
			mobile_number: userContent.mobile_number,
			current_password: "",
			password: "",
			password_confirmation: ""
		});*/
		const [profileImage, setProfileImage] = useState();
		/*const phoneChange = ({ target })=>{
			console.log(target.value);
		};*/
		const handleProfileChange = ({ target })=>{
			profileSetState({ ...profileState, [target.name]: target.value});
			//console.log(profileState);
		};
		// This function will be triggered when the "Remove This Image" button is clicked
		const removeSelectedImage = () => {
			setProfileImage();
		};
		//console.log(profileState);
		const onProfileSubmit = (e) => {
			e.preventDefault();
			profileValidate(profileState);
			if(isProfileSubmit){
				setisLoading(true);
				const pData = new FormData();
				pData.append("id", localStorage.getItem("loggedin_user_id"));
				pData.append("profile_picture", profileImage);
				pData.append("name", profileState.name);
				//pData.append("email", profileState.email);
				pData.append("mobile_number", profileState.mobile_number);
				pData.append("current_password", profileState.current_password);
				pData.append("password", profileState.password);
				pData.append("password_confirmation", profileState.password_confirmation);
				
				//console.log(pData);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/update-profile`, pData).then(response => {
						//console.log(response);
						if(response.data.status==200){
							//console.log(response);
							localStorage.setItem("loggedin_user_id",response.data.user.id);
							localStorage.setItem("loggedin_user_name",response.data.user.name);
							localStorage.setItem("loggedin_user_email",response.data.user.email);
							//userContent.push(response.data.user);
							//console.log(userContent);
							props.chgUserContent(response.data.user)
							setUserContent(response.data.user);
							profileSetState(response.data.user);
							setProfileImage();
							toast(response.data.message);
						}else if(response.data.status==400){
							//console.log(response.data.message);
							const errors = {};
							errors.editProfileError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
													{response.data.message}
												</div>;
							setProfileFormErrors(errors);
						}else if(response.data.status==600){
							// console.log(response.data);
							const errors = {};
							errors.name = response.data.errors.name;
							//errors.email = response.data.errors.email;
							errors.mobile_number = response.data.errors.mobile_number;
							errors.password = response.data.errors.password;
							setProfileFormErrors(errors);
						}
						setisLoading(false);
					});
				});
			}
		};
	
		const profileValidate = (values) => {
			//console.log(values);
			const errors = {};
			const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
			if (!values.name) {
				errors.name = "Name is required!";
			}else if (!values.mobile_number) {
				errors.mobile_number = "MObile number is required!";
			}else if (values.password != '') {
				if(!values.current_password){
					errors.current_password = "Current Password is required!";
				}else if(values.password.length < 8){
					errors.password = "The password must be at least 8 characters.";
				}else if(values.password != values.password_confirmation){
					errors.password = "The password confirmation does not match";
				}
			}else{
				isProfileSubmit = true;
			}
			setProfileFormErrors(errors);
			//console.log(profileFormErrors);
			return isProfileSubmit;
		}
	//Edit my profile end
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//console.log("edit Profile: "+languageContent.edit_profile);
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage my_profile_parent">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu  state={{userContent: userContent }} userContent={userContent} languageContent={languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle profilePage">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1 className="desktopView">{languageContent.edit_profile}</h1>
										<h1 className="mobileView">{profileState.name}</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}  </Link>/ {languageContent.edit_profile}</div>
									</div>
								</div>
							</div>
							<div className="pInfo">
								<h4 className="desktopView">Personal Information</h4>
								{profileFormErrors.editProfileError}
								<form onSubmit={onProfileSubmit} method="post" enctype="multipart/form-data">
									<div className="profileD">
										<div className="pImg">
											<h6 className="desktopView">Profile Picture</h6>
											<label className="mobileView profileMobileSubmit"><button type="submit" className="btn btn-info" disabled={isLoading}>{ isLoading ? (<div className="btnloader"></div> ):('Save')}</button></label>
											<div className="ppEdit">
												{profileImage ? (
													<div>
														<img src={URL.createObjectURL(profileImage)} alt="Thumb" />
														<div className="fileUpload">
															<span onClick={removeSelectedImage}><CloseCircleOutline /></span>
														</div>
													</div>
												) : (
													<div>
														{profileState.profile_picture ? <img src={profileState.profile_picture} alt="Avatar" /> : <img src="./upload/user/avatar.png" alt="Avatar" />}
														<div className="fileUpload">
															<input type="file" className="upload" onChange={(e) => setProfileImage(e.target.files[0])}/>
															<span><Camera /></span>
														</div>
													</div>
												)}
												{/*<p><Star /><Star /><Star /><Star /><Star /></p>*/}
												<p className="leftMenuStart rating-star-disable"><ReactStars count={5} key={profileRating} value={profileRating} size={24} 
									activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/></p>
											</div>
										</div>
										<div className="pTxt">
											<div className="mb-3">
												<label for="dName" className="form-label">Display Name</label>
												<input type="text" className="form-control" id="dName" name="name" value={profileState.name} onChange={handleProfileChange} />
												<div className="errorMesg">{profileFormErrors.name}</div>
											</div>
											<div className="mb-3 countryInput">
												<label for="mNumber" className="form-label">{languageContent.mobile_number}<small>(With country code)</small></label>
												<PhoneInput enableSearch={true} countryCodeEditable={false} onChange={phone => profileSetState({ ...profileState, ['mobile_number']: phone})} value={profileState.mobile_number} inputProps={{ name: 'mobile_number', className: 'form-control mobileInput' }}/>
												<div className="errorMesg">{profileFormErrors.mobile_number}</div>
											</div>
											<div className="mb-3">
												<label for="emailType" className="form-label">Email Address</label>
												<input type="email" className="form-control" id="emailType" name="email" value={profileState.email} disabled/>
											</div>
										</div>
									</div>
									<div className="cPassword">
										<h4 className="textCapitalize">{languageContent.change_password}</h4>
										<div className="mb-3">
											<label for="cupassword" className="form-label">{languageContent.enter_right_password}</label>
											<input type="password" className="form-control" id="cupassword" onChange={handleProfileChange} name="current_password" />
											<div className="errorMesg">{profileFormErrors.current_password}</div>
										</div>
										<div className="mb-3">
											<label for="npassword" className="form-label">{languageContent.enter_your_new_password}</label>
											<input type="password" className="form-control" id="npassword" onChange={handleProfileChange} name="password" />
											<div className="errorMesg">{profileFormErrors.password}</div>
										</div>
										<div className="mb-3">
											<label for="cpassword" className="form-label">Confirm Password</label>
											<input type="password" className="form-control" id="cpassword" onChange={handleProfileChange} name="password_confirmation" />
										</div>
										<div className="saveBtn desktopView"><button type="submit" className="btn btn-info" disabled={isLoading}>{ isLoading ? (<div className="btnloader"></div> ):('Save Changes')}</button></div>
									</div>
									<div className="cPassword mobileView">
										<h4 className="textCapitalize">See All Favourite Items</h4>
										<div className="mb-3">
											<NavLink to="/favourite-item"><label className="form-label textCapitalize">View Favourite Items</label></NavLink>
										</div>
									</div>
									<div className="cPassword mobileView">
										<h4 className="textCapitalize">Rating & Reviews</h4>
										<div className="mb-3">
											<NavLink to="/review"><label className="form-label textCapitalize">Rating & reviews</label></NavLink>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Myprofile;
