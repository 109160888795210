import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, MenuOutline, CloseCircleOutline } from 'react-ionicons';
import axios from "axios";
import ReactStars from "react-rating-stars-component";
import ReactPaginate from "react-paginate";
import Userleftmenu from './Userleftmenu';

const Myreview = (props) => {
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	let languageContent = props.languageContent;
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	const [show, setShow] = React.useState();
	
	
	const [avgRatingItem, setAvgRatingItem] = useState([]);
	const [reviewDetails, reviewSetDetails] = useState([]);
	const [getitemsPerPage, setGetItemsPerPage] = useState();
	const [itemsPerPage, setItemsPerPage] = useState();
	const [pagiLoop, setpagiLoop] = useState();
	
	const [itemsLoad, setItemsLoad] = useState();
	const [visible, setVisible] = useState();
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/review-details/${loggedInUserId}`).then((response)=>{
					// console.log(response.data);
					reviewSetDetails(response.data.getreviewdetails);
					setItemsPerPage(response.data.per_page_list);
					setGetItemsPerPage(response.data.per_page_list);
					setpagiLoop(response.data.per_page_pagination_loop);
					//document.body.classList.remove('disable_load_content');
					
					setItemsLoad(response.data.items_load_more);
					setVisible(response.data.items_load_more);
				});
			});
		}
	}, [[props.reviewDetails]]);
	//console.log(avgRatingItem);
	
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue + itemsLoad);
	};
	
	//--- pagination start
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const handleShowChange = ({ target })=>{
		setItemsPerPage(target.value);
	};
	useEffect(() => {
		const endOffset = Number(itemOffset) + Number(itemsPerPage);
		setCurrentItems(reviewDetails.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(reviewDetails.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, reviewDetails]);
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % reviewDetails.length;
		setItemOffset(newOffset);
	};
	//--- pagination end
	var lis = [];
	for (var i=1; i<pagiLoop+1; i++) {
		lis.push(<option value={getitemsPerPage*i} >{getitemsPerPage*i}</option>);
	}
	//----------------------------
	return <>
		<section>
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu  state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>{languageContent.reviews}</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home} </Link>/ {languageContent.reviews}</div>
									</div>
								</div>
							</div>
							<div className="reviewPart">
								{currentItems.length ? (
								<div>
									<div className="row">
										<div className="col-md-12">
										{reviewDetails.slice(0, visible).map((val) => {
										// {currentItems.map((val) => {
										return <>
											<div className="reviewMedia reviewMedia_desktop_view">
												<div className="rMedia">
													<img src={val.profile_picture} alt="Avatar" />
												</div>
												<div className="rText rating-star-disable">
													<h6>{val.reviewer_name}</h6>
													
													<ReactStars count={5} key={val.avg_rating} value={val.avg_rating} size={24} 
										activeColor="#3498DB" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/>,
													<p className="pAmount">${val.price}.00</p>
													<p className="pTime">{val.dates}</p>
												</div>
												<div className="clearfix"></div>
												<div className="rFeedback">
												{val.message}.
												</div>
											</div>
											<div className="reviewMedia reviewMedia_mobile_view">
												<div className="card mobile mb-3" style={{ maxWidth: '540px' }}>
													<div className="row g-0" style={{ paddingBottom: '5px' }}>
														<div className="review_logo_div">
															<img src={val.profile_picture} className="img-fluid rounded-start" alt="Avatar" />
														</div>
														<div className="review_text_div">
															<div className="card-body">
																<div className="d-flex justify-content-between name_and_star">
																	<h5 className="card-title ellipsis">{val.reviewer_name}</h5>
																	<ReactStars count={5} key={val.avg_rating} value={val.avg_rating} size={24} 
										                            activeColor="#3498DB" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/>
																</div>
																<p className="card-text">{val.message}.</p>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* <div className="card reviewMedia mb-3" style="max-width: 540px;">
												<div className="row g-0">
													<div className="col-md-4">
													<img src="..." className="img-fluid rounded-start" alt="..." />
													</div>
													<div className="col-md-8">
													<div className="card-body">
														<h5 className="card-title">Card title</h5>
														<p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
														<p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
													</div>
													</div>
												</div>
											</div> */}
										</>;
										})}
										</div>
									</div>
									{visible < reviewDetails.length && (
										<div className="col-md-12 mt-3 mb-3 text-center">
											<button type="submit" className="btn btn-primary" onClick={loadMoreItems}>Show More</button>
										</div>
									)}
								</div>
								) : (
									<div className="noRecord">
										<h6 className="m-0">No Record Found</h6>
									</div>
								)}	
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Myreview;