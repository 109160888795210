import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ThumbsUpOutline, Heart, ShareSocialOutline, Location, TimeOutline, Star } from 'react-ionicons';
import { Carousel } from 'react-carousel-minimal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Categoriedetails = (props) => {
	//const [login, setLoginShow] = useState(false);
	const [meeting, setMeetingShow] = useState(false);
	const [registerShow, setRegisterShow] = useState(false);	
	const [isOpen, setisOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	useEffect(()=> {
		setMeetingShow(props.meeting);
		//console.log(props.meeting);
	},);
	
	
	
 const data = [
    {
      image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg",
      caption: "Scotland"
    },
    {
      image: "https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg",
      caption: "Scotland"
    }
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
	return <>
		<section className="page-title">
			<div className="container">
				<div className="row">
					<h1>Category Details</h1>
					<div className="breadcrumb"><Link to="/">Home  </Link>/<Link className="ml5" to="/category-list">Category </Link>/ Category Details</div>
				</div>
			</div>
		</section>
		<section>
			<div className="products-section-innerpage">
				<div className="container cDetailsContainer">
					<div className="row">
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 catDetailsLeft">
							<div className="myCaptionSlider">
								<div style={{ textAlign: "center" }}>
									<div style={{
									padding: "0"
									}}>
									<Carousel
										data={data}
										time={2000}
										width="850px"
										height="500px"
										captionStyle={captionStyle}
										radius="10px"
										slideNumber={true}
										slideNumberStyle={slideNumberStyle}
										captionPosition="bottom"
										automatic={true}
										dots={true}
										pauseIconColor="white"
										pauseIconSize="40px"
										slideBackgroundColor="darkgrey"
										slideImageFit="cover"
										thumbnails={true}
										thumbnailWidth="100px"
										style={{
											textAlign: "center",
											maxWidth: "850px",
											maxHeight: "500px",
											margin: "40px auto",
										}}
									/>
									</div>
								</div>
							</div>
							<div className="detailsCat">
								<div className="detailsPart">
									<div className="loaclPickup"><Location /> Local pickup (10 miles away)</div>
									<div className="detailsName">Details</div>
								</div>
								<div className="knowBrand">
									<div>Brand</div>
									<div>Apple</div>
								</div>
								<div className="knowBrand">
									<div>Condition</div>
									<div>Used</div>
								</div>
								<div className="knowBrand">
									<div>Purchase Year</div>
									<div>2022</div>
								</div>
								<div className="knowBrand">
									<div>Invoice</div>
									<div>Available</div>
								</div>
								<div className="knowBrand">
									<div>Physical Condition</div>
									<div>Excellent</div>
								</div>
								<div className="knowDesc">
									<h6>Description</h6>
									<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed justo sed nisi volutpat vehicula sed eget justo. Nulla blandit ante et facilisis lobortis. Curabitur iaculis, ligula in vehicula lacinia, risus arcu faucibus nulla, at semper ligula nunc eget ante. Mauris malesuada, erat sed dignissim viverra, tellus nulla sodales orci, non scelerisque metus lorem et nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras pulvinar elit id nisl suscipit ultricies. Phasellus gravida lobortis velit, sit amet hendrerit tellus ornare at.</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 catDetailsRight">
							<div className="cardBdr">
								<div className="listDetails">
									<div className="likeShare">
										<span className="bdrAdjust"><ThumbsUpOutline className="activeLike" /> 5 Likes</span><span className="bdrAdjust"><ShareSocialOutline /></span><span className="bdrAdjust"><Heart className="activeheart" /></span>
									</div>
									<div className="priceShare">
										<h6>Price</h6>
										<p>$4000</p>
									</div>
								</div>
								<div className="timeDetails">
									<div><Location className="timeIcon" /><small>4741 Radio Park Drive, Columbus, GA</small></div>
									<div><TimeOutline className="timeIcon" /><small>02/02/2022 at 12:00 PM</small></div>
								</div>
							</div>
							<div className="seller">
								<h5>Seller Information</h5>
								<div className="media">
									<div className="media_img">
										<img src="./upload/user/avatar.png" alt="Avatar" />
									</div>
									<div className="media_body">
										<h6>John Marthow</h6>
										<p><Star /><Star /><Star /><Star /><Star /></p>
										<p>Member Since 2016</p>
									</div>
								</div>
								<div className="mesgBtn">
									<div><button className="btn btn-info btn-block btn-round w-100">Send Message</button></div>
									<div><button className="btn btn-secondary btn-block btn-round w-100" onClick={() => setMeetingShow(true)}>Arrange Meeting</button></div>
								</div>
							</div>
							<div className="locationOn">
								<h5>Location</h5>
								<div className="mapPart">
									<img src="./images/map.jpg" alt="Map" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<Modal show={meeting} onHide={() => setMeetingShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title className="w-100 text-center">Arrange Meeting</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="loginForm">
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label>Date</label>
								<DatePicker className="form-control" placeholderText="Select a Date" required />
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>Time</label>
								<input type="email" className="form-control" name="email1"  id="email1" placeholder="Time" required />
							</div>
						</div>
					</div>
					<div className="form-group mt-2">
						<label>Seller Name</label>
						<input type="email" className="form-control" name="email1"  id="email1" placeholder="Seller Name" required />
					</div>
					<div className="form-group mt-2">
						<label>Buyer Name</label>
						<input type="email" className="form-control" name="email1"  id="email1" placeholder="Buyer Name" required />
					</div>
					<div className="form-group mt-2">
						<label>Meeting Location</label>
						<input type="email" className="form-control" name="email1"  id="email1" placeholder="Meeting Location" required />
					</div>
					<div className="mapPart">
						<img src="./images/map.jpg" alt="Map" />
					</div>
					<div className="submitBtn"><button className="btn">Submit</button></div>
				</form>
			</Modal.Body>
		</Modal>
	</>;
}

export default Categoriedetails;
