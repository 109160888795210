import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";

function Cms(props) {
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	const { slug } = useParams();
	const [cmsContent, setCmsContent] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/cms/${slug}/${props.language}`).then((response)=>{
				//console.log(response.data);
				setCmsContent(response.data);
				//document.body.classList.remove('disable_load_content');
			});
		});
	}, [slug,props.language]);
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	return <>
		<section className="page-title">
			<div className="container">
				<div className="row">
					<h1>{cmsContent.cms_page_name}</h1>
				</div>
			</div>
		</section>
		<section className="container">
			<div className="cmsPage" dangerouslySetInnerHTML={{__html: cmsContent.cms_page_content }} />
		</section>
	</>;
}

export default Cms;
