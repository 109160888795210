import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import PaymentItemForm from "./PaymentItemForm"

/*const PUBLIC_KEY = "pk_test_51KqbriSFDScLi9X4jPaN11rESo6ZNw3wI9K2XoD67W65dVLvwFLK28KbeIR9OsHqJ8ioeycGuGXjH02Dazu9W7JO00eDhjZCZm";

const stripeTestPromise = loadStripe(PUBLIC_KEY)*/

export default function StripeItemContainer(props) {
	const PUBLIC_KEY = props.publicKey;

	const stripeTestPromise = loadStripe(PUBLIC_KEY)
	// console.log(props.showPayment);
	function onPaymentClose() {
		props.onPaymentModalClose(false);
	}
	return (
		<Modal className="PaymentModal" show={props.showPayment} onHide={e => onPaymentClose(e)}>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<Elements stripe={stripeTestPromise}>
					<PaymentItemForm paymentAmount={props.paymentAmount} paymentCurrency={props.paymentCurrency} profileState={props.profileState}/>
				</Elements>
			</Modal.Body>
		</Modal>
	)
}