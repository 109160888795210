import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, TimeOutline, TrashOutline, MenuOutline, CloseCircleOutline } from 'react-ionicons';
import axios from "axios";
import dateFormat from 'dateformat';

import Userleftmenu from './Userleftmenu';
import ReactPaginate from "react-paginate";

const Notification = (props) => {
	//console.log(props.language);
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	let languageContent = props.languageContent;
	//console.log("lang:"+languageContent);
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	/*const location = useLocation();
	const { getAllNotification } = location.state;*/
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	const [show, setShow] = React.useState();
	
	
	let [getNotification, setGetNotification] = useState([]);
	const [getitemsPerPage, setGetItemsPerPage] = useState();
	const [itemsPerPage, setItemsPerPage] = useState();
	const [pagiLoop, setpagiLoop] = useState();
	
	const [itemsLoad, setItemsLoad] = useState();
	const [visible, setVisible] = useState();
		useEffect(()=> {
			axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/get-notification/${loggedInUserId}/${props.language}`).then((response)=>{
				// console.log(response.data);
				setGetNotification(response.data.arrNotification);
				setItemsPerPage(response.data.per_page_list);
				setGetItemsPerPage(response.data.per_page_list);
				setpagiLoop(response.data.per_page_pagination_loop);
				//setCreateDate(dateFormat(response.data[0].create_date,"dd/mm/yyyy h:i:s"));
				
				setItemsLoad(response.data.items_load_more);
				setVisible(response.data.items_load_more);
			});
			});
		}, [props.language]);
		
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue + itemsLoad);
	};
	// console.log(props.getNotification);	
	const delete_notifi = value => () => {
		// console.log("value: "+value);
		let expvalue        = value.split("/");
		let sender_id       = expvalue[0];
		let Id              = expvalue[1];
		//console.log(sender_id+"****"+Id);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/delete-notification-by-id/${loggedInUserId}/${sender_id}/${Id}`).then((response)=>{	
			   //console.log(response.data);
			setGetNotification(response.data.arrNotification);
			setItemsPerPage(response.data.per_page_list);
			setGetItemsPerPage(response.data.per_page_list);
			setpagiLoop(response.data.per_page_pagination_loop);
			
			});
		 });
	};
	//--- pagination start
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const handleShowChange = ({ target })=>{
		setItemsPerPage(target.value);
	};
	
	useEffect(() => {
		const endOffset = Number(itemOffset) + Number(itemsPerPage);
		setCurrentItems(getNotification.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(getNotification.length / itemsPerPage));
		
		//document.body.classList.remove('disable_load_content');
	}, [itemOffset, itemsPerPage, getNotification]);
	
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % getNotification.length;
		setItemOffset(newOffset);
	};
	//--- pagination end
	var lis = [];
	for (var i=1; i<pagiLoop+1; i++) {
		lis.push(<option value={getitemsPerPage*i} >{getitemsPerPage*i}</option>);
	}
	//----------------------------
	return <>
		<section>
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu  state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>{languageContent.notifications}</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}  </Link>/ {languageContent.notifications}</div>
									</div>
								</div>
							</div>
							<div className="nInfo desktopView">
								{currentItems.length ? (
								<div>
								<div className="notiP">
								{currentItems.map((val) => {
									return <> 
									<div className="notifiyPart">
										<div className="nRight">
											<label><TimeOutline />{val.create_date}</label>
											<Link to="#" onClick={delete_notifi(`${val.sender_id}/${val.id}`)}><TrashOutline /> Delete</Link>
										</div>
										<div className="nLeft">
										{val.sender_id==0 ? 'Admin':val.sendername}</div>
										{val.notification_title}<p>
										<div className="row" dangerouslySetInnerHTML={{__html: val.notification }} />
										</p>
									</div>
									</>;
		                            })}
								</div>
								<div className="pagination">
									<div className="paginationLeft">
										<ReactPaginate
											breakLabel="..."
											nextLabel="next"
											onPageChange={handlePageClick}
											pageRangeDisplayed={2}
											pageCount={pageCount}
											previousLabel="prev"
											nextClassName={""}
											renderOnZeroPageCount={null}
											activeClassName={"active"}
											previousClassName={""}
										/>
									</div>
									<div className="showRight">
										<div className="form-group mySelect"><small>show</small>
											<select className="form-control" onChange={handleShowChange}>
												{lis} 
											</select>
										</div>
									</div>
								</div>
								</div>
								) : (
									<div className="noRecord">
										<h6>No Record Found</h6>
									</div>
								)}
							</div>
							<div className="nInfo mobileView">
								{getNotification.length ? (
								<div>
									<div className="color000">
									{getNotification.slice(0, visible).map((val) => {
									// {getNotification.map((val) => {
										return <> 
										<div className="row mb-2 padding_for_mobile">
											<div className="review_logo_div">
												
													{val.profile_picture ? <img src={val.profile_picture} alt="Avatar" className="img-fluid rounded-start" /> : <img src="./upload/user/avatar.png" alt="Avatar" className="img-fluid rounded-start" />}
												    {val.name}
												
											</div>
											<div className="review_text_div">
												<div className="card-body notifiBorder">
													<div className="d-flex justify-content-between name_and_star">
														<h5 className="card-title ellipsis">{val.sender_id==0 ? 'Admin':val.sendername}</h5>
														<div className="notiDateMobile">{val.create_date_mobile}</div>
													</div>
													<p className="notiDateMobile">
														<div className="card-text" dangerouslySetInnerHTML={{__html: val.notification }} />
													</p>
												</div>
											</div>
										</div>
										</>;
										})}
									</div>
									{visible < getNotification.length && (
										<div className="col-md-12 mt-3 mb-3 text-center">
											<button type="submit" className="btn btn-primary" onClick={loadMoreItems}>Show More</button>
										</div>
									)}
								</div>
								) : (
									<div className="noRecord">
										<h6>No Record Found</h6>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Notification;
