import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Close, CarOutline, Location, MenuOutline, CloseCircleOutline, AttachOutline, ArrowBackOutline, SendOutline } from 'react-ionicons';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useParams } from 'react-router-dom';

import Userleftmenu from './Userleftmenu';
import axios from "axios";

import StripeItemContainer from '../stripe/StripeItemContainer';
import dateFormat from 'dateformat';

function Chat(props) {
	const navigate = useNavigate();
	let loggedInUserId = localStorage.getItem("loggedin_user_id");// user login Id
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	const { oth_user_id, item_id } = useParams();
	
	const [profileState, profileSetState] = useState({
		message_from: loggedInUserId,
		message_to: oth_user_id,
		item_id: item_id,
		is_offer_accept: ''
	});
	

	
	const [publicKey, setPublicKey] = React.useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/stripe-public-key`).then((response)=>{
			// console.log(response.data);
			setPublicKey(response.data);
		});
		});
	}, []);
	

	const [newMessage, setNewMessage] = useState([]);
	const [countOffer, setCountOfferCust] = useState(0);
	const [chatList, setChatList] = useState([]);
	
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/chat-list/${loggedInUserId}`).then((response)=>{
				setChatList(response.data);
			});
		});
		// console.log(newMessage);
		// console.log(newMessage.findLastIndex(obj => obj.message_type == 4));
		setCountOfferCust(newMessage.findLastIndex(obj => obj.message_type == 4));
	}, [newMessage]);
	const [chatDetails, setChatDetails] = useState([]);
	const [countOfferSeller, setCountOfferSeller] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/chat-details/${item_id}/${oth_user_id}/${loggedInUserId}`).then((response)=>{
				setNewMessage(response.data.chat_list);
				setCountOfferCust(response.data.offer_count_cust);
				setCountOfferSeller(response.data.offer_count_seller);
				setChatDetails({ ...chatDetails, ['profile_picture']: response.data.message_from_profile, ['name']: response.data.message_from_name, ['oth_id']: response.data.message_from_id, ['item_details']: response.data.item_details});
				profileSetState({ ...profileState, ['buyer_id']: response.data.buyer_id, ['seller_id']: response.data.seller_id, ['message_to']: oth_user_id, ['item_id']: item_id});
			});
		});
	}, [item_id,oth_user_id]);
	
	
	
	const handleChatOfferChange = ({target}) => {
		
		profileSetState({ ...profileState, [target.name]: target.value, ['message_type']: '4'});
		//console.log(profileState);
		const re = /^[0-9\b]$/;
		if (target.value === '' || re.test(target.value)) {
			profileState.offer({number: target.value})
		}
	};
	const [disabled, setDisabled] = useState(false);
	const onChatOfferSubmit = (e) => {
		setDisabled(true);
		e.preventDefault();
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/chat-new-message`, profileState).then(response => {
				profileSetState({ ...profileState, ['message']: '', ['offer']: '', ['message_type']: ''});
				setDisabled(false);
			});
		});
	};
	const handleChatMessageChange = ({ target })=>{
		profileSetState({ ...profileState, [target.name]: target.value, ['message_type']: '1'});
	};
	const onChatMessageSubmit = (e) => {
		setDisabled(true);
		e.preventDefault();
		//alert(profileState.message_type);
		const rData = new FormData();
		
		rData.append("buyer_id", profileState.buyer_id);
		rData.append("is_offer_accept", profileState.is_offer_accept);
		rData.append("item_id", profileState.item_id);
		rData.append("message", profileState.message);
		rData.append("message_from", profileState.message_from);
		rData.append("message_to", profileState.message_to);
		rData.append("message_type", profileState.message_type);
		rData.append("seller_id", profileState.seller_id);
		for(let i=0;i<multipleFiles.length ;i++)
		{
			rData.append("message_type", 1);
			rData.append("image_data[]", multipleFiles[i]);
		}
		// profileSetState({ ...profileState, ['rData']: rData});
		// console.log(rData);
		// console.log(profileState);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/chat-new-message`, rData).then(response => {
				// console.log(response);
				profileSetState({ ...profileState, ['message']: '', ['offer']: '', ['message_type']: ''});
				setmultIpleReadImgFiles([]);
				setDisabled(false);
			});
		});
	};
	
	
	
	/*const onlyNumberKey = (e) => {
		
	const re = /^[0-9\b]+$/;
     if(e.target.value === '' || re.test(e.target.value)) {
		 //alert('error');
		 profileSetState({ ...profileState, ['offer']: ''});
         //this.setState({value: e.target.value})
      }else{
		alert('success');
		profileSetState({ ...profileState, ['offer']: e.target.value});
	  }
	{if(e.charCode >= 48 && e.charCode <= 57) 
	{
		return true;
	}
	else{
		alert(e.charCode);
		return false;
	}}
  };*/
	
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	const chatBack = () => {
		navigate(-1);
	}
	
	var roomName = 'room_s'+profileState.seller_id+'_i'+item_id+'_b'+profileState.buyer_id;
	useEffect(()=> {
		window.Echo.channel('EventTriggered.'+roomName).listen('GetRequestEvent', (response) => {
			/*let temp_state = [...newMessage];
			let temp_element = { ...temp_state[countOffer] };
			temp_element.is_offer_accept = response.message.is_offer_accept;
			temp_state[countOffer] = temp_element;
			setNewMessage( temp_state );
					
			let items = {...response.message};
			items.is_offer_accept = '0';
			// items = item;
			// this.setState({items});
			
			setNewMessage((newMessage) => [...newMessage, items]);*/
			
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/chat-details/${item_id}/${oth_user_id}/${loggedInUserId}`).then((response)=>{
					setNewMessage(response.data.chat_list);
					setCountOfferCust(response.data.offer_count_cust);
					setCountOfferSeller(response.data.offer_count_seller);
					setChatDetails({ ...chatDetails, ['profile_picture']: response.data.message_from_profile, ['name']: response.data.message_from_name, ['oth_id']: response.data.message_from_id, ['item_details']: response.data.item_details});
					profileSetState({ ...profileState, ['buyer_id']: response.data.buyer_id, ['seller_id']: response.data.seller_id, ['message_to']: oth_user_id, ['item_id']: item_id});
				});
			});
		})
	}, [roomName]);
	useEffect(()=> {
		window.Echo.channel('MessageTriggered.chat_list'+loggedInUserId).listen('GetRequestEvent', (response) => {
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/chat-list/${loggedInUserId}`).then((response)=>{
					setChatList(response.data);
				});
			});
		})
	}, []);
	
	//document.body.classList.remove('disable_load_content');
	const [offer, setOffer] = useState(false);
	const showOffer = () => {
		setOffer(!offer);
	};
	const editOffer = (id,amt) => () => {
		profileSetState({ ...profileState, ['offer']: amt});
	};
	//For reject offer start
	const [reject, setReject] = useState(false);
	const rejectOffer = (id) => (e) => {
		e.preventDefault();
		// console.log(id);
		profileSetState({ ...profileState, ['message']: 'Offer rejected', ['message_type']: '1', ['is_offer_accept']: '2', ['offer_id']: id});
		setReject(!reject);
		// console.log(profileState);
	};
	useEffect(()=> {
		if(reject){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/chat-new-message`, profileState).then(response => {
					profileSetState({ ...profileState, ['message']: '', ['offer']: '', ['message_type']: '', ['is_offer_accept']: '', ['offer_id']: ''});
					setReject(!reject);
				});
			});
		}
	}, [reject]);
	//For reject offer end
	//For accept offer start
	const [accept, setAccept] = useState(false);
	const acceptOffer = (id) => (e) => {
		e.preventDefault();
		profileSetState({ ...profileState, ['message']: 'Offer accepted', ['message_type']: '1', ['is_offer_accept']: '1', ['offer_id']: id});
		setAccept(!accept);
	};
	useEffect(()=> {
		if(accept){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/chat-new-message`, profileState).then(response => {
					profileSetState({ ...profileState, ['message']: '', ['offer']: '', ['message_type']: '', ['is_offer_accept']: '', ['offer_id']: ''});
					setAccept(!accept);
				});
			});
		}
	}, [accept]);
	//For accept offer end
	//Buy Item Start
	const [showPayment, setShowPayment] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState('');
	
	const buyItem = (price) => () => {
		//console.log(price);
		setPaymentAmount(price);
		setShowPayment(true);
	};
	//Buy Item End
	//console.log(newMessage);
	
	
	//----- multiple file upload
		const [ItemFormErrors, setItemFormErrors] = useState([]);
		const [multipleFiles, setmultIpleFiles] = React.useState([]);
		const [multipleReadImgFiles, setmultIpleReadImgFiles] = useState([]);
		const multiplefileschange = (e)=> {
			setmultIpleFiles(e.target.files);
			//preview start
			const selectedImgFIles = [];
			const selectedVidFIles = [];
			const targetFiles = e.target.files;
			const targetFilesObject = [...targetFiles]
			targetFilesObject.map((file, i)=>{
				if(file.type==='image/gif' || file.type==='image/jpeg' || file.type==='image/png' || file.type==='image/webp'){
					return selectedImgFIles.push(URL.createObjectURL(file));
				}
			})
			setmultIpleReadImgFiles(selectedImgFIles);
			//preview end
		}
		const handleUploadImageDelete = arrImg => {
			var updatedUploadPhotos = [...multipleFiles]; // make a separate copy of the array
			//var index = updatedUploadPhotos.indexOf(arrImg);
			//console.log(arrImg);
			if (arrImg !== -1) {
				updatedUploadPhotos.splice(arrImg, 1);
				setmultIpleFiles(updatedUploadPhotos);
				//console.log(updatedUploadPhotos);
				
				//preview start
				const selectedImgFIles = [];
				const targetFiles = updatedUploadPhotos;
				const targetFilesObject = [...targetFiles]
				targetFilesObject.map((file, i)=>{
					return selectedImgFIles.push(URL.createObjectURL(file));
				})
				setmultIpleReadImgFiles(selectedImgFIles);
			}
		};
		// console.log(multipleFiles);
		// console.log(chatDetails);
		
		// let header_height = document.getElementById('site-header').clientHeight;
		let calculate_height = window.innerHeight - 66;
		const lmenuHeight = {maxHeight:calculate_height+'px', minHeight:'auto'}
		// console.log(calculate_height);
	//---------end----------
	//alert(multipleFiles);
	return <>
		<section>
			<div className="products-section-innerpage">
				<div className="chatBack mobileView"><ArrowBackOutline onClick={chatBack}/></div>
				<div className="mobChatLeftMenuIcon mobileView"><MenuOutline onClick={onClickMenu}/></div>
				<div className="container myLeftContainer myChatPage">
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 chat-column categoriesLeft leftNavMenu pl-0" style={{display: isOpen ? 'inline' : 'none'}}>
							<div className="cListClose"><CloseCircleOutline onClick={onClickMenu} /></div>
							<ul className="leftMenu" style={lmenuHeight}>
								{chatList.map((val) => {
									return <> 
										<li key={val.item_id}>
											<NavLink to={"/chat/"+`${val.oth_user_id}`+"/"+`${val.item_id}`}>
												<div className="chatImgDiv">
													<img src={val.message_from==localStorage.getItem("loggedin_user_id") ? (val.message_to_profile ? val.message_to_profile:"../../upload/user/avatar.png"):(val.message_from_profile ? val.message_from_profile:"../../upload/user/avatar.png")} alt="" />
												</div>
												<div className="chatleftDiv">
													<div className="chatUserDiv">{val.message_from==localStorage.getItem("loggedin_user_id") ? val.message_to_name:val.message_from_name}</div>
													<div className="chatItemDiv">{val.item_name}</div>
												</div>
											</NavLink>
										</li>
									</>;
								})}
							</ul>
						</div>
						<>
						{showPayment ? (
							<StripeItemContainer publicKey={publicKey} paymentAmount={paymentAmount} paymentCurrency={chatDetails.item_details.currency_code} profileState={profileState} showPayment={showPayment} onPaymentModalClose={showPayment => setShowPayment(false)}/>
						) : ''}
						</>
						{oth_user_id ? (
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated padding-0">
							<div className="chat-page-title">
								<div className="row">
									<div className="col-lg-10 col-md-10 col-sm-9 col-12 d-flex">
										<div className="chat-page-title-img">
											<img src={chatDetails.profile_picture ? chatDetails.profile_picture:"../../upload/user/avatar.png"} alt="" />
										</div>
										<div className="chat-page-title-desc">
											<h4><Link to={"/user-details/"+chatDetails.oth_id}>{chatDetails.name}</Link></h4>
											<div className="chat-page-title-item"><Link to={chatDetails.item_details ? ("/item/"+`${chatDetails.item_details.slug}-id-${chatDetails.item_details.id}`) :''}>{chatDetails.item_details ? chatDetails.item_details.name:''}</Link></div>
										</div>
									</div>
									{loggedInUserId==profileState.buyer_id ? (
									<div className="col-lg-2 col-md-2 col-sm-3 col-12 offerBtnRight">
										<button className="btn btn-primary" onClick={showOffer}>{offer ? 'Chat':'Offer'}</button>
									</div>
									) : ''}
								</div>
							</div>
							<div className="">
								<div id="div-data" className="padding-10 chatHeight">
									{newMessage.map((val,k) => {
										let dateTime = val.created_at.split(" ");
										let timeSplit = dateTime[1].split(":");
										let chatTime = timeSplit[0]+':'+timeSplit[1];
										let dateF = new Date(val.created_at);
										let dateMDY = `${dateF.getDate()}/${dateF.getMonth() + 1}/${dateF.getFullYear()}`;
										
										let dateFormats = dateFormat(val.created_at, "mmmm dS, yyyy, h:MM TT")
										let message_html = '';
										if (val.message_type=='1') {
										  message_html = <>
											{/*	{val.message_from!=localStorage.getItem("loggedin_user_id") ? (
													<label><img src={chatDetails.profile_picture} /></label>
												) : ''} 
											*/}
												
													<span className="textMessage">
														{val.message}
													</span>												
												{/*	{val.message_from==localStorage.getItem("loggedin_user_id") ? (
														<span><img src={process.env.REACT_APP_SERVER_API_URL + `images/user_chat/${val.message}`} /></span>
													) : ''}
												*/}	
													<br></br>
												
													<span className="chatDate">{dateFormats}</span>
													</>;
										} else if(val.message_type=='2') {
										  message_html = <>
														<img src={process.env.REACT_APP_SERVER_API_URL + `images/user_chat/${val.message}`} />
													<br></br>
												
													<span className="chatDate">{dateFormats}</span>
													</>;
										}else{
											message_html = <>
														<span className="priceMessage">
															<span className="priceMessageTxt">Your offer </span>
															{chatDetails.item_details ? chatDetails.item_details.currency_symbol:''} {val.message}
														</span>
														<br></br>
														<span className="chatDate">{dateFormats}</span>
														<br></br>
														{val.message_from==localStorage.getItem("loggedin_user_id") && (k == countOffer) ? (
														<>
															{val.is_offer_accept==0 || val.is_offer_accept==2 ? (
															<span className="editOffer" onClick={editOffer(val.id,val.message)}>
																EDIT OFFER
															</span>
															) : (
															<span className="acceptOffer" onClick={buyItem(`${val.message}`)}>
																PAY
															</span>
															)}
														</>
														) : val.message_from==localStorage.getItem("loggedin_user_id") ?(
															<>
															{/*<span className="acceptOffer">
																	Accept
																</span>
																<span className="rejectOffer">
																	Reject
															</span>*/}
															</>
														): countOffer == k ? (
															<>
															{val.is_offer_accept==0 ? (
															<>
																<span className="acceptOffer" onClick={acceptOffer(val.id)}>
																	Accept
																</span>
																<span className="rejectOffer" onClick={rejectOffer(val.id)}>
																	Reject
																</span>
															</>
															) : (
															<>
																{val.is_offer_accept==1 ? (
																<span className="acceptOffer">
																	Accepted
																</span>
																) : (
																<span className="rejectOffer">
																	Rejected
																</span>
																)}
															</>
															)}
															</>
														) : ''}
													</>;
										}
										return <> 
											<div className="notifiyPart" key={val.message} className={val.message_from==localStorage.getItem("loggedin_user_id") ? 'sender_message':'receiver_message'}>
												{message_html}
											</div>
										</>;
									})}
								</div>
								{offer ? (
								<>
								{loggedInUserId==profileState.buyer_id ? (
								<form onSubmit={onChatOfferSubmit} method="post" className="mobileChatForm">
									<div className="">
										<div className="input-group">
											<input type="number" className="form-control chatSendInput" onChange={handleChatOfferChange} value={profileState.offer} name="offer" />
											<button className="btn btn-info chatSendBtn desktopView" type="submit" disabled={disabled}>Offer</button>
											<button className="btn btn-info1 chatSendBtn mobileView" type="submit" disabled={disabled}><SendOutline/></button>
										</div>
									</div>
								</form>
								) : '' }
								</>
								) : (
								<>
								{multipleReadImgFiles.length > 0 ? (
								<div className="clearfix mt-2">
									<div className="imageUploaded chatImageDiv">
										{multipleReadImgFiles.map((url, j)=>{
											return <>
												<div>
													<img src={url} alt="Avatar"/>
													<span ><Close/></span>
													<span onClick={() => handleUploadImageDelete(j)}><Close/></span>
												</div>
											</>;
										})}
									</div>
								</div>
								) : (
									null
								)}
								<form onSubmit={onChatMessageSubmit} method="post" className="mobileChatForm">
									<div className="input-group">
										<input type="text" className="form-control chatSendInput" onChange={handleChatMessageChange} value={profileState.message} name="message" />
										<div className="input-group-append d-flex">
											<input accept="image/*" type="file" id="openImageUpload" className="d-none" onChange={(e) => multiplefileschange(e)} name="itemImage[]" multiple/>
											<label for="openImageUpload" className="chatImageUpload"><AttachOutline /></label>
											<button className="btn btn-info chatSendBtn desktopView" type="submit" disabled={disabled}>Send</button>
											<button className="btn btn-info1 chatSendBtn mobileView" type="submit" disabled={disabled}><SendOutline/></button>
										</div>
									</div>
								</form>
								</>
								)}
							</div>
						</div>
						) : (
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated selectNoChat">
							Select a chat to view conversation
						</div>
						)}
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Chat;
