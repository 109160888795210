import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, AttachOutline, LogOutOutline, Close, CarOutline, Location, MenuOutline, CloseCircleOutline, ArrowBackOutline, SendOutline } from 'react-ionicons';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Userleftmenu from './Userleftmenu';
import axios from "axios";

import dateFormat from 'dateformat';
// Moment.globalTimezone = 'America/Los_Angeles';
function Help(props) {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");// user login Id
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	
	const [profileState, profileSetState] = useState({
		message_from: loggedInUserId,
		message_to: 0
	});
	
	const [newMessage, setNewMessage] = useState([]);
	
	const [chatDetails, setChatDetails] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/user-admin-chat-details/${loggedInUserId}`).then((response)=>{
				setNewMessage(response.data.message);
			});
		});
	}, []);
	
	const handleChatMessageChange = ({ target })=>{
		profileSetState({ ...profileState, [target.name]: target.value, ['message_type']: '1'});
	};
	
	const [disabled, setDisabled] = useState(false);
	// console.log(profileState);
	const onUserChatMessageSubmit = (e) => {
		setDisabled(true);
		e.preventDefault();
		const rData = new FormData();
		rData.append("message_from", profileState.message_from);
		rData.append("message_to", profileState.message_to);
		rData.append("message", profileState.message);
		
		for(let i=0;i<multipleFiles.length ;i++)
		{
			rData.append("image_data[]", multipleFiles[i]);
		}
		
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.post(`/api/user-admin-chat-new-message`, rData).then(response => {
				profileSetState({ ...profileState, ['message']: '', ['message_type']: ''});
				setmultIpleReadImgFiles([]);
				setDisabled(false);
			});
		});
	};
	
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	//var roomName = 'room_s'+profileState.seller_id+'_i'+item_id+'_b'+profileState.buyer_id;
	var roomName = 'room_u'+loggedInUserId+'_a0';
	// var roomName = 'room_chat';
	// console.log(roomName);
	useEffect(()=> {
		window.Echo.channel('EventTriggeredAdmin.'+roomName).listen('GetRequestEventAdminChat', (response) => {	
			// console.log(response);		
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user-admin-chat-details/${loggedInUserId}`).then((response)=>{
					setNewMessage(response.data.message);
					// console.log(response);
					//profileSetState({ ...profileState, ['buyer_id']: response.data.buyer_id, ['seller_id']: response.data.seller_id, ['message_to']: oth_user_id, ['item_id']: item_id});
				});
			});
		})
	}, [roomName]);
	//document.body.classList.remove('disable_load_content');
	//--------- single or multiple file upload--------------
	const [multipleFiles, setmultIpleFiles] = React.useState([]);
		const [multipleReadImgFiles, setmultIpleReadImgFiles] = useState([]);
		const multiplefileschange = (e)=> {
			setmultIpleFiles(e.target.files);
			//preview start
			const selectedImgFIles = [];
			const selectedVidFIles = [];
			const targetFiles = e.target.files;
			const targetFilesObject = [...targetFiles]
			targetFilesObject.map((file, i)=>{
				if(file.type==='image/gif' || file.type==='image/jpeg' || file.type==='image/png' || file.type==='image/webp'){
					return selectedImgFIles.push(URL.createObjectURL(file));
				}
			})
			setmultIpleReadImgFiles(selectedImgFIles);
			//preview end
		}
		const handleUploadImageDelete = arrImg => {
			var updatedUploadPhotos = [...multipleFiles]; // make a separate copy of the array
			//var index = updatedUploadPhotos.indexOf(arrImg);
			//console.log(arrImg);
			if (arrImg !== -1) {
				updatedUploadPhotos.splice(arrImg, 1);
				setmultIpleFiles(updatedUploadPhotos);
				//console.log(updatedUploadPhotos);
				
				//preview start
				const selectedImgFIles = [];
				const targetFiles = updatedUploadPhotos;
				const targetFilesObject = [...targetFiles]
				targetFilesObject.map((file, i)=>{
					return selectedImgFIles.push(URL.createObjectURL(file));
				})
				setmultIpleReadImgFiles(selectedImgFIles);
			}
		};
		
	const chatBack = () => {
		navigate(-1);
	}
	
	return <>
		<section>
			<div className="products-section-innerpage">
				<div className="chatBack mobileView"><ArrowBackOutline onClick={chatBack}/></div>
				<div className="container myLeftContainer myChatPage">
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 chat-column categoriesLeft leftNavMenu pl-0" style={{display: isOpen ? 'inline' : 'none'}}>
							<div className="cListClose"><CloseCircleOutline onClick={onClickMenu} /></div>
							<ul className="leftMenu">
							{/*{chatList.map((val) => {
									return <> 
										<li key={val.item_id}>
											<NavLink to="">
												<div className="chatImgDiv">
													<img src={val.message_from==localStorage.getItem("loggedin_user_id") ? (val.message_to_profile ? val.message_to_profile:"../../upload/user/avatar.png"):(val.message_from_profile ? val.message_from_profile:"../../upload/user/avatar.png")} alt="" />
												</div>
												<div className="chatleftDiv">
													<div className="chatUserDiv">{val.message_from==localStorage.getItem("loggedin_user_id") ? val.message_to_name:val.message_from_name}</div>
													<div className="chatItemDiv">{val.item_name}</div>
												</div>
											</NavLink>
										</li>
									</>;
							})}*/}
							</ul>
						</div>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated padding-0">
							<div className="chat-page-title">
								<div className="row">
									<div className="col-lg-10 col-md-10 col-sm-9 col-12 d-flex">
										<div className="chat-page-title-img">
											<img src={chatDetails.profile_picture ? chatDetails.profile_picture:"../../upload/user/avatar.png"} alt="" />
										</div>
										<div className="chat-page-title-desc">
											<h4><Link to={"/user-details/"+chatDetails.oth_id}>{chatDetails.name}</Link></h4>
											<div className="chat-page-title-item"><Link to={chatDetails.item_details ? ("/item/"+`${chatDetails.item_details.slug}-id-${chatDetails.item_details.id}`) :''}>{chatDetails.item_details ? chatDetails.item_details.name:''}</Link></div>
										</div>
									</div>
								</div>
							</div>
							<div className="">
								<div id="div-data" className="padding-10 chatHeight">
									{newMessage.map((val,k) => {
										/*let dateTime = val.create_date.split(" ");
										let timeSplit = dateTime[1].split(":");
										let chatTime = timeSplit[0]+':'+timeSplit[1];
										let dateF = new Date(val.create_date);
										let dateMDY = `${dateF.getDate()}/${dateF.getMonth() + 1}/${dateF.getFullYear()}`;*/
										
										let dateFormats = dateFormat(val.create_date, "mmmm dS, yyyy, h:MM TT")
										return <> 
											<div className="notifiyPart" key={val.message} className={val.message_from==localStorage.getItem("loggedin_user_id") ? 'sender_message':'receiver_message'}>
												{
												   val.message_type=='1' ? (
													<>
														<span className="textMessage">
															{val.message}
														</span><br></br>												
														<span className="chatDate">
															<Moment format="MMMM Do YYYY, h:mm A">{val.create_date}</Moment>
														</span>
													</>
													
												) : (
													<>
														<img src={process.env.REACT_APP_SERVER_API_URL + `images/user_chat/${val.message}`} />
														<br></br>
														<span className="chatDate">
															<Moment format="MMMM Do YYYY, h:mm A">{val.create_date}</Moment>
														</span>
														
													</>
												)}
											</div>
										</>;
									})}
								</div>
								{multipleReadImgFiles.length > 0 ? (
								<div className="clearfix mt-2">
									<div className="imageUploaded chatImageDiv">
										{multipleReadImgFiles.map((url, j)=>{
											return <>
												<div>
													<img src={url} alt="Avatar"/>
													<span ><Close/></span>
													<span onClick={() => handleUploadImageDelete(j)}><Close/></span>
												</div>
											</>;
										})}
									</div>
								</div>
								) : (
									null
								)}
								<form onSubmit={onUserChatMessageSubmit} method="post" className="mobileChatForm">
									<div className="input-group">
										<input type="text" className="form-control chatSendInput" onChange={handleChatMessageChange} value={profileState.message} name="message" />
										<div className="input-group-append">
											<input accept="image/*" type="file" id="openImageUpload" className="d-none" onChange={(e) => multiplefileschange(e)} name="itemImage[]" multiple/>
											<label for="openImageUpload" className="chatImageUpload"><AttachOutline /></label>
											<button className="btn btn-info chatSendBtn desktopView" type="submit" disabled={disabled}>Send</button>
											<button className="btn btn-info1 chatSendBtn mobileView" type="submit" disabled={disabled}><SendOutline/></button>
										</div>
									</div>
								</form>								
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Help;
