import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, NavLink, Link, Button, useParams, useNavigate} from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Close, CarOutline, Location, MenuOutline, CloseCircleOutline, CloudUploadOutline } from 'react-ionicons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from "react-google-autocomplete";
import Userleftmenu from './Userleftmenu';
import axios from "axios";
import MapContainer from "../MapContainer";
import PhoneInput from 'react-phone-input-2';

const Editbulletin = (props) => {
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	let languageContent = props.languageContent;
	const [isLoading, setisLoading] = useState(false);
	const [ItemFormErrors, setItemFormErrors] = useState([]);

	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	const { id } = useParams();
	
	//For Ip address
	const [useIp, setUseIp] = useState();
	useEffect(() => {
		fetch('https://ipapi.co/json/')
		// fetch('https://geolocation-db.com/json/')
			.then((response) => response.json())
			.then((responseJson) => {
				//console.log(responseJson.IPv4);
				setUseIp(responseJson.ip);
		})
	}, [useIp]);
	const [editItemsImage, setEditItemsImage] = useState([]);
	const [editItemsData, setEditItemsData] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/edit-bulletin/${id}`).then((response)=>{
			// console.log(response);
			setEditItemsData(response.data.itemData);
			setEditItemsImage(response.data.image);
			// setUseLocation({lat: response.data.itemData.latitude, lng: response.data.itemData.longitude});
		});
		});
	}, []);
	
	// get country
	//const [currentValue, setCurrentValue] = useState(99);
	let [bulletinPackage,setBulletinPackage] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-bulletin-package`).then((response)=>{
			// console.log(response.data);
			setBulletinPackage(response.data);
		});
		});
	}, []);
	let [countryName,setCountryName] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/getcountry`).then((response)=>{
			// console.log(response.data);
			setCountryName(response.data);
		});
		});
	}, []);
	const [showPayment, setShowPayment] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState('');
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/get-bulletin-currency-price/${editItemsData.country_id}/${editItemsData.package}`).then((response)=>{
			// console.log(response.data);
			setCurrencyName(response.data.country.currency_symbol);
			setPaymentAmount(response.data.package.price);
		});
		});
	}, [editItemsData.country_id, editItemsData.package]);
	let [currencyName,setCurrencyName] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/get-currency/${editItemsData.country_id}`).then((response)=>{
			// console.log(response.data);
			setCurrencyName(response.data.currency_symbol);
		});
		});
	}, [editItemsData.country_id]);
	//----- Remove image ----
	const [deletedImageList, setDeletedImageList] = useState([]);
	const handleImageDelete = remImg => {
		let arrIndex = editItemsImage.findIndex(obj => obj.image === remImg);
		const updatedPhotos = [...editItemsImage];
		updatedPhotos.splice(arrIndex, 1);
		setDeletedImageList({ ...deletedImageList, [arrIndex]: remImg}); //store removed image
		setEditItemsImage(updatedPhotos);
	};
	//----- multiple file upload
	const [multipleFiles, setmultIpleFiles] = React.useState();
	const [multipleReadImgFiles, setmultIpleReadImgFiles] = useState([]);
	const [multipleReadVidFiles, setmultIpleReadVidFiles] = useState([]);
	let vidFlag = '';
	const multiplefileschange = (e)=> {
		setmultIpleFiles(e.target.files);
		//console.log(e.target.files);
		//preview start
		const selectedImgFIles = [];
		const targetFiles = e.target.files;
		const targetFilesObject = [...targetFiles]
		targetFilesObject.map((file, i)=>{
			if(file.type==='image/gif' || file.type==='image/jpeg' || file.type==='image/png' || file.type==='image/webp'){
				return selectedImgFIles.push(URL.createObjectURL(file));
			}
		})
		setmultIpleReadImgFiles(selectedImgFIles);
	}
	//console.log(multipleReadVidFiles);
	//const [deletedImageList, setDeletedImageList] = useState([]);
	const handleUploadImageDelete = arrImg => {
		var updatedUploadPhotos = [...multipleFiles]; // make a separate copy of the array
		//var index = updatedUploadPhotos.indexOf(arrImg);
		//console.log(arrImg);
		if (arrImg !== -1) {
			updatedUploadPhotos.splice(arrImg, 1);
			setmultIpleFiles(updatedUploadPhotos);
			//console.log(updatedUploadPhotos);
			
			//preview start
			const selectedImgFIles = [];
			const targetFiles = updatedUploadPhotos;
			const targetFilesObject = [...targetFiles]
			targetFilesObject.map((file, i)=>{
				return selectedImgFIles.push(URL.createObjectURL(file));
			})
			setmultIpleReadImgFiles(selectedImgFIles);
		}
	};
	//console.log(multipleFiles);
	const handleItemChange = ({ target })=>{
		setEditItemsData({ ...editItemsData, [target.name]: target.value});
	};
	
	//----- Form submit start
	let isItemSubmit = false;
	const onItemSubmit = e => {
		let arrFile = [];
		e.preventDefault();
		itemValidate(editItemsData);
		if(isItemSubmit){
			setisLoading(true);
			const rData = new FormData();
			if(multipleFiles){
				for(let i=0;i<=multipleFiles.length ;i++)
				{
					rData.append("image_data[]", multipleFiles[i]);
				}
			}
			rData.append("user_id", loggedInUserId);
			rData.append("item_id", editItemsData.id);
			rData.append("first_name", editItemsData.first_name);
			rData.append("last_name", editItemsData.last_name);
			rData.append("business_name", editItemsData.business_name);
			rData.append("email_id", editItemsData.email_id);
			rData.append("mobile_number", editItemsData.contact_number);
			rData.append("web_url", editItemsData.web_url);
			rData.append("user_location", editItemsData.user_location);
			rData.append("advertisement_duration", editItemsData.advertisement_duration);
			rData.append("country_id", editItemsData.country_id);
			rData.append("package", editItemsData.package);
			rData.append("message", editItemsData.message);
			rData.append("paymentAmount", paymentAmount);
			rData.append("ip_address", useIp);
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/edit-bulletin`, rData).then(response => {
					// console.log(response);
					if(response.data.status==200){
						setEditItemsData(response.data.editData.itemData);
						setEditItemsImage(response.data.editData.image);
						setmultIpleReadImgFiles([]);
						setmultIpleReadVidFiles([]);
						//console.log(setmultIpleReadImgFiles.length);
						toast(response.data.message);
						/*const errors = {};
						errors.itemError = <div className="alert alert-success alert-dismissible fade show" role="alert">
												{response.data.message}
											</div>;
						setItemFormErrors(errors);*/	
					}
					setisLoading(false);
				});
			});
		}
	};
	//console.log(multipleReadVidFiles);
	//----- Form validation start	
	const itemValidate = (values) => {
		const errors = {};
		if (!values.first_name) {
			isItemSubmit = false;
			errors.first_name = "First name is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.last_name) {
			isItemSubmit = false;
			errors.last_name = "Last name is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.business_name) {
			isItemSubmit = false;
			errors.business_name = "Business name is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.email_id) {
			isItemSubmit = false;
			errors.email_id = "Email is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.contact_number) {
			isItemSubmit = false;
			errors.contact_number = "Mobile number is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.web_url) {
			isItemSubmit = false;
			errors.web_url = "Website is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.user_location) {
			isItemSubmit = false;
			errors.user_location = "Location is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.advertisement_duration) {
			isItemSubmit = false;
			errors.advertisement_duration = "Advertisement duration is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.country_id) {
			isItemSubmit = false;
			errors.country_id = "Country is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.package) {
			isItemSubmit = false;
			errors.package = "Package is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.message) {
			isItemSubmit = false;
			errors.message = "Message is required!";
		}else{
			isItemSubmit = true;
		}
		setItemFormErrors(errors);
		return isItemSubmit;
	}
	//console.log(useLocation);
	//useEffect(() => { console.log(useLocation) }, [useLocation])
	//-------------------------------------------
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage bulletin_page">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Edit Bulletin</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}  </Link>/ Edit Bulletin</div>
									</div>
								</div>
							</div>
							<form id="itemForm" onSubmit={onItemSubmit} method="post" enctype="multipart/form-data">
							<div className="videoUpload">
								<h6>Uploaded video and Images</h6>
								<div className="mediaUpload clearfix">
									<div className="imageLoad">
										<div className="uploadFile">
											<CloudUploadOutline />
											<p>Drag and drop file here<small>or</small></p>
											<button className="btn btn-info">Browse for file</button>
											<input accept="image/*" type="file" onChange={(e) => multiplefileschange(e)} name="itemImage" id="itemImage"/>
										</div>
									</div>
									<div className="errorMesg">{ItemFormErrors.imageFile}</div>
								</div>
								<div className="mediaUpload clearfix">
								{multipleReadImgFiles ? (
									<div className="imageUploaded">
										{multipleReadImgFiles.map((url, j)=>{
											return <>
												<div>
													<img src={url} alt="Avatar"/>
													<span ><Close/></span>
													<span onClick={() => handleUploadImageDelete(j)}><Close/></span>
												</div>
											</>;
										})}
									</div>
								) : (
									null
								)}	
								</div>
								<div className="mediaUpload clearfix">
									<div className="imageUploaded">
										{editItemsImage.map((val, i) => {
										return <>

										<div key={val.iid}>
											<img src={process.env.REACT_APP_SERVER_API_URL +`images/bulletin/${val.id}/${val.image}`} alt="Avatar"/>
											<span id={val.iid} onClick={() => handleImageDelete(val.image)}><Close/></span>
										</div>

										</>;
										})}
									</div>
								</div>
								<div className="mt-4 mb-4">
									<div className="row">
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">First Name</label>
												<input type="text" onChange={handleItemChange} value={editItemsData.first_name} className="form-control" name="first_name" />
												<div className="errorMesg">{ItemFormErrors.first_name}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Last Name</label>
												<input type="text" onChange={handleItemChange} value={editItemsData.last_name} className="form-control" name="last_name" />
												<div className="errorMesg">{ItemFormErrors.last_name}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Business Name</label>
												<input type="text" onChange={handleItemChange} value={editItemsData.business_name} name="business_name" className="form-control" placeholder="Business Name" />
												<div className="errorMesg">{ItemFormErrors.business_name}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Email</label>
												<input type="text" onChange={handleItemChange} value={editItemsData.email_id} name="email_id" className="form-control" placeholder="Email" />
												<div className="errorMesg">{ItemFormErrors.email_id}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3 countryInput">
												<label for="" className="form-label">{languageContent.contact_number}<small>(With country code)</small></label>
												<PhoneInput enableSearch={true} countryCodeEditable={false} onChange={phone => setEditItemsData({ ...editItemsData, ['contact_number']: phone})} value={editItemsData.contact_number} inputProps={{ name: 'contact_number', className: 'form-control mobileInput' }}/>
												<div className="errorMesg">{ItemFormErrors.contact_number}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Website</label>
												<input type="text" onChange={handleItemChange} value={editItemsData.web_url} name="web_url" className="form-control" placeholder="Website" />
												<div className="errorMesg">{ItemFormErrors.web_url}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="dName" className="form-label">Select Country</label>
												<select className="form-control" id="country_id" name="country_id" value={editItemsData.country_id} onChange={handleItemChange} value={editItemsData.country_id}>
													<option value=''>-select_country-</option>
													{countryName.map((val) => {
															return <>	
															<option value={val.id}>{val.country_name}</option>
														</>;
													})}
												</select>
												<div className="errorMesg">{ItemFormErrors.country_id}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="dName" className="form-label">Select Package</label>
												<select className="form-control" id="package" name="package" value={editItemsData.package} onChange={handleItemChange} value={editItemsData.package}>
													<option value=''>-select_package</option>
													{bulletinPackage.map((val) => {
														return <>	
															<option value={val.id}>{val.package_name}</option>
														</>;
													})}
												</select>
												<div className="errorMesg">{ItemFormErrors.package}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Location</label>
												<input type="text" onChange={handleItemChange} value={editItemsData.user_location} name="user_location" className="form-control" placeholder="Location" />
												<div className="errorMesg">{ItemFormErrors.user_location}</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-3">
												<label for="" className="form-label">Duration of Advertisement <small>(Months)</small></label>
												<input type="text" onChange={handleItemChange} value={editItemsData.advertisement_duration} name="advertisement_duration" className="form-control" placeholder="Duration of Advertisement" />
												<div className="errorMesg">{ItemFormErrors.advertisement_duration}</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-3">
												<label for="emailType" className="form-label">Message</label>
												<div className="form-group myTextarea">
													<textarea name="message"className="form-control" rows="5" onChange={handleItemChange} value={editItemsData.message}></textarea>
													<div className="errorMesg">{ItemFormErrors.message}</div>
												</div>
											</div>
										</div>
										<div className="col-md-12 mt-3">
											<button type="submit" className="btn btn-primary" disabled={isLoading}>
												{isLoading ? <div className="btnloader"></div> : 'Submit'}
											</button>
										</div>
									</div>
								</div>
							</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Editbulletin;
