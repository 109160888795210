import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { LogoFacebook, LogoInstagram, LogoTwitter, Notifications, Chatbox, Camera, TrashOutline, MenuOutline, AlertCircle, ArrowForward, PhonePortraitSharp } from 'react-ionicons';
import GoogleLogin from 'react-google-login';
import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Cookies from 'universal-cookie';
import Autocomplete from "react-google-autocomplete";
import useGeoLocation from "../hooks/useGeoLocation";

function Loginmodal(props) {
	const countryID= localStorage.getItem('country_id')
	let language = localStorage.getItem("language");
	//let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	//console.log(languageStoredInLocalStorage);
	
	
	const getLocation = useGeoLocation();
	const [useLocation, setUseLocation] = useState({lat: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LATITUDE, lng: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LONGITUDE});
	
	useEffect(()=> {
		if(getLocation.status==200){
			const newUserPos = { 
				lat: getLocation.coordinates.lat,
				lng: getLocation.coordinates.lng,
			};
			setUseLocation(newUserPos)
			//console.log(useLocation)	
		}
	}, [getLocation]);
	
	const [useAddress, setUseAddress] = useState();
	useEffect(()=> {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+useLocation.lat+','+useLocation.lng+'&key='+process.env.REACT_APP_GOOGLE_MAP_API_KEY)
			.then((response) => response.json())
			.then((responseJson) => {
				//console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));
				//console.log(responseJson.results[0].formatted_address);
				setUseAddress(responseJson.results[0].formatted_address);
		})
	}, [useLocation]);
	
	
	const handlePlaceChange = (place)=>{
		setUseLocation({ ...useLocation, ['lat']: place.geometry.location.lat(), ['lng']: place.geometry.location.lng()});
	};
	// console.log(useAddress);
	// console.log("lan1-"+useLocation.lat);
	// console.log("lng2:-"+useLocation.lng);
	let languageContent = props.languageContent;
	const navigate = useNavigate();
	
	const [loginPopupShow, setLoginPopupShow] = useState(false);
	const [registerShow, setRegisterShow] = useState(false);
	const [fPasswordPopupShow, setFPasswordPopupShow] = useState(false);
	useEffect(()=> {
		setLoginPopupShow(props.loginPopupShow);
		//console.log(props.loginPopupShow);
	}, [props.loginPopupShow]);
	function onLoginModalClose() {
		props.onLoginPopupCloseModal(false);
		// props.onRegisterPopupCloseModal(false);
		setLoginPopupShow(false);
		// setRegisterShow(false);
		//console.log(props.onLoginPopupCloseModal);
	}
	
	useEffect(()=> {
		setRegisterShow(props.registerShow);
	}, [props.registerShow]);
	function onRegisterModalClose() {
		// props.onLoginPopupCloseModal(false);
		props.onRegisterPopupCloseModal(false);
		// setLoginPopupShow(false);
		setRegisterShow(false);
		//console.log(props.onRegisterPopupCloseModal);
	}
	
	//login to register popup open Start
	function createNewAccount() {
		props.onLoginPopupCloseModal(false);
		props.onRegisterPopupCloseModal(true);
		setRegisterShow(true);
		setLoginPopupShow(false);
		setFPasswordPopupShow(false);
	};
	//login to register popup open End
	//register to login popup open Start
	function alreadyRegister() {
		props.onRegisterPopupCloseModal(false);
		props.onLoginPopupCloseModal(true);
		setLoginPopupShow(true);
		setRegisterShow(false);
		setFPasswordPopupShow(false);
	};
	//register to login popup open End
	//login to forgot password popup open Start
	const forgotPassword = () => {
		props.onRegisterPopupCloseModal(false);
		props.onLoginPopupCloseModal(false);
		setLoginPopupShow(false);
		setRegisterShow(false);
		setFPasswordPopupShow(true);
	};
	//login to forgot password popup open End
	
	//const [loginPopupShow, setLoginPopupShow] = useState(false);
	//const [registerShow, setRegisterShow] = useState(false);
	const [verifyAccount, verifyAccountShow] = useState(false);
	const [mobileVerify, mobileVerifyShow] = useState(false);	
	const [isLoading, setisLoading] = useState(false);
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//console.log(loginPopupShow);
		
	let [countryName,setCountryName] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/getcountry`).then((response)=>{
			//console.log(response.data);
			setCountryName(response.data);
		});
		});
	}, []);
	console.log("country name:-",countryName);
	//}
	//loggedin user details if available end
	//console.log(localStorage.getItem("loggedin_user_id"));
	//Login start
		//normal login start
		const [loginFormErrors, setLoginFormErrors] = useState([]);
		let isLoginSubmit = false;
		const [loginState, loginSetState] = React.useState({
			email: "",
			password: ""
		});
		const handleLoginChange = ({ target })=>{
			loginSetState({ ...loginState, [target.name]: target.value});
		};
		let loggedInUserId = localStorage.getItem("loggedin_user_id");
		//console.log(loggedInUserId);
		//let [userContent, setUserContent] = useState([]);
		useEffect(()=> {
			if(loggedInUserId!=null){
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.get(`/api/user/${loggedInUserId}`).then((response)=>{
						//console.log(response.data);
						//setUserContent(response.data);
						props.chgUserContent(response.data);
					});
				});
			}
		}, [loggedInUserId]);
		
		//--------- remember me-----
		const cookies = new Cookies();
		
		class CookieService{
			
			get(key: string)
			{
				return cookies.get(key);
			}
			
			set(key: string,value: string,options: Object)
			{
				cookies.set(key,value,options);
			}
			remove(key: string)
			{
				cookies.remove(key);
			}
		}
		
		
		const expiresAt  = 1;
		//--------------------------
		const onLoginSubmit = (e) => {
			e.preventDefault();
			loginValidate(loginState);
			//console.log('testtest');
			//console.log(loginState);
			if(isLoginSubmit){
				setisLoading(true);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/login`, loginState).then(response => {
						//console.log(response.data);
						if(response.data.status==200){
							localStorage.setItem("verify_id",response.data.user.id);					
							setTimeout(() => {
								window.location.pathname = "/verify-user";	
							}, 2000)
							/*//console.log(response);
							localStorage.setItem("loggedin_user_id",response.data.user.id);
							localStorage.setItem("loggedin_user_name",response.data.user.name);
							localStorage.setItem("loggedin_user_email",response.data.user.email);
							localStorage.setItem("loggedin_user_pic",response.data.user.profile_picture);
							//window.location.reload(false);
							
							toast(response.data.message);
							setLoginPopupShow(false);*/
						}else if(response.data.status==400){
							//console.log(response.data.message);
							const errors = {};
							errors.loginError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
								{response.data.message}
							</div>;
							setLoginFormErrors(errors);
						}else if(response.data.status==600){
							const errors = {};
							errors.email = response.data.errors.email;
							errors.password = response.data.errors.password;
							setLoginFormErrors(errors);
						}
						setisLoading(false);
					});
				});
			}
		};
		const loginValidate = (values) => {
			const errors = {};
			const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
			if (!values.email) {
				isLoginSubmit = false;
				errors.email = "Email is required!";
			} else if (!regex.test(values.email)) {
				isLoginSubmit = false;
				errors.email = "This is not a valid email format!";
			}else{
				isLoginSubmit = true;
			}
			if (!values.password) {
				isLoginSubmit = false;
				errors.password = "Password is required!";
			}else{
				isLoginSubmit = true;
			}
			setLoginFormErrors(errors);
			return isLoginSubmit;
		}
		//normal login end
		//google login start
		const handleGoogleLogin = (googleData) => {
			console.log(googleData);
			const googleresponse = {
				name: googleData.profileObj.name,
				email: googleData.profileObj.email,
				token: googleData.googleId,
				image: googleData.profileObj.imageUrl,
				login_type: '1'
			};
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/socialregister`, googleresponse).then(response => {
					localStorage.setItem("loggedin_user_id",response.data.user.id);
					localStorage.setItem("loggedin_user_name",response.data.user.name);
					localStorage.setItem("loggedin_user_email",response.data.user.email);
					toast(response.data.message);
					setLoginPopupShow(false);
					if(localStorage.getItem("login_form") == 'add_item'){
						localStorage.removeItem("login_form");
						navigate('/add-item');
					}else{
						navigate('/');
					}
				});
			});
		};
		
		//--------------remember me---------------
			//let loggedInUserId = localStorage.getItem("loggedin_user_id");
			//let date = new Date();
			//date.setTime(date.getTime() + expiresAt);
			//let time = new Time();
			//const redirect_path =  navigate(`/`);
			
			
			const [checkRemember, setCheckRemember] = useState();
			const rememberMe = (e) => {
				let checkedRemember = e.target.checked;
				let checkedRemValue = e.target.value;
				let checkedRemName = e.target.name;
				setCheckRemember(checkedRemember);
				//this.setState({isChecked : !this.state.isChecked});
				//console.log("dates: "+date);
				//const options = {path: navigate(`/`), expires:date };
				//Cookies.set('loggedin_user_id','18',options);
				
			};
		//----------------------------------------
		//google login end
		//facebook login start
		const handleFacebookLogin = (facebookData) => {
			//alert(facebookData);
			//console.log("fbData"+facebookData);
			const facebookresponse = {
				name: facebookData.name,
				email: facebookData.email,
				image: facebookData.picture.data.url,
				login_type: '2'
			};
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/socialregister`, facebookresponse).then(response => {
					localStorage.setItem("loggedin_user_id",response.data.user.id);
					localStorage.setItem("loggedin_user_name",response.data.user.name);
					localStorage.setItem("loggedin_user_email",response.data.user.email);
					toast(response.data.message);
					setLoginPopupShow(false);
					if(localStorage.getItem("login_form") == 'add_item'){
						localStorage.removeItem("login_form");
						navigate('/add-item');
					}else{
						navigate('/');
					}
				});
			});
		};
		//facebook login end
	//Login end
	//Register Start
	const [registerFormErrors, setRegisterFormErrors] = useState([]);
	let isRegisterSubmit = false;
	const [registerState, registerSetState] = React.useState({
		name: "",
		email: "",
		mobile_number: "",
		country_id:props.countryId || countryID,
		location:"",
		password: "",
		password_confirmation: ""
	});
	const [registerImage, setRegisterImage] = React.useState();
	const handleRegisterChange = ({ target })=>{
		registerSetState({ ...registerState, [target.name]: target.value});
		//console.log(registerState);
	};
	
	console.log(registerState,'registerState')
	const onRegisterSubmit = e => {
		e.preventDefault();
		registerValidate(registerState);
		if(isRegisterSubmit){
			setisLoading(true);
			const rData = new FormData();
			rData.append("profile_picture", registerImage);
			rData.append("name", registerState.name);
			rData.append("email", registerState.email);
			rData.append("location", useAddress);
			rData.append("latitude", useLocation.lat);
			rData.append("longitude", useLocation.lng);
			rData.append("country_id", registerState.country_id);
			rData.append("mobile_number", registerState.mobile_number);
			rData.append("password", registerState.password);
			rData.append("password_confirmation", registerState.password_confirmation);
			rData.append("agree", iAgree);
			// console.log(rData);
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/register/${language}`, rData).then(response => {
					if(response.data.status==200){
						const errors = {};
						errors.registerError = <div className="alert alert-success alert-dismissible fade show" role="alert">
												{response.data.message}
											</div>;
						localStorage.setItem("verify_id",response.data.user.id);					
						setTimeout(() => {
							window.location.pathname = "/verify-user";	
						}, 5000)					
						/*localStorage.setItem("loggedin_user_id",response.data.user.id);
						localStorage.setItem("loggedin_user_name",response.data.user.name);
						localStorage.setItem("loggedin_user_email",response.data.user.email);
						localStorage.setItem("loggedin_user_pic",response.data.user.profile_picture);					
						toast(response.data.message);	*/
						
						/*setRegisterFormErrors(errors);
						
						props.onLoginPopupCloseModal(!loginPopupShow);
						props.onRegisterPopupCloseModal(false);
						setRegisterShow(false);
						setLoginPopupShow(!loginPopupShow);*/
						// navigate('/resetpassword/test');						
					}else if(response.data.status==600){
						const errors = {};
						errors.name = response.data.errors.name;
						errors.mobile_number = response.data.errors.mobile_number;
						errors.email = response.data.errors.email;
						errors.password = response.data.errors.password;
						errors.country_id = response.data.errors.country_id;
						errors.location = response.data.errors.location;
						setRegisterFormErrors(errors);
					}
					setisLoading(false);
				});
			});
		}
	};
	// START IMAGE UPLOAD
		// This function will be triggered when the "Remove This Image" button is clicked
		const removeSelectedImage = () => {
			setRegisterImage();
		};
		const registerImageStyles = {
			container: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
			image: { maxWidth: "100%",},
			delete: {
				cursor: "pointer",
				padding: 5,
				background: "#f55",
				color: "white",
				border: "none",
				fontSize: 14,
				marginTop: 10,
				width: 175,
				marginLeft: -12,
				borderRadius: 4,
			},
		};
	// END IMAGE UPLOAD
	const registerValidate = (values) => {
		const errors = {};
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		if (!values.name) {
			isRegisterSubmit = false;
			errors.name = "Name field is required!";
		}else{
			isRegisterSubmit = true;
		}
		if (!values.email) {
			isRegisterSubmit = false;
			errors.email = "Email is required!";
		} else if (!regex.test(values.email)) {
			isRegisterSubmit = false;
			errors.email = "This is not a valid email format!";
		}else{
			isRegisterSubmit = true;
		}
		if (!values.mobile_number) {
			isRegisterSubmit = false;
			errors.mobile_number = "Mobile number field is required!";
		}else{
			isRegisterSubmit = true;
		}
		if (!values.country_id) {
			isRegisterSubmit = false;
			errors.country_id = "Country field is required!";
		}else{
			isRegisterSubmit = true;
		}
		if (!values.location) {
			isRegisterSubmit = false;
			errors.location = "Location field is required!";
		}else{
			isRegisterSubmit = true;
		}
		if (!values.password) {
			isRegisterSubmit = false;
			errors.password = "Password is required!";
		}else if(values.password.length < 8){
			isRegisterSubmit = false;
			errors.password = "The password must be at least 8 characters.";
		}else if(values.password != values.password_confirmation){
			isRegisterSubmit = false;
			errors.password = "The password confirmation does not match";
		}else{
			isRegisterSubmit = true;
		}
		setRegisterFormErrors(errors);
		return isRegisterSubmit;
	}
	//Register End
	//Logout Start
	const handleLogout = () => {
		localStorage.clear();
		window.location.pathname = "/";
	};
	//Logout End
	
	//forgot password start
		const [forgotFormErrors, setForgotFormErrors] = useState([]);
		let isForgotSubmit = false;
		const [forgotState, forgotSetState] = React.useState({
			email: ""
		});
		const handleForgotChange = ({ target })=>{
			forgotSetState({ ...forgotState, [target.name]: target.value});
		};
		//console.log("Rajesh"+language);
		const onForgotSubmit = (e) => {
			e.preventDefault();
			forgotValidate(forgotState);
			if(isForgotSubmit){
				setisLoading(true);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/forgotpassword/${language}`, forgotState).then(response => {
						if(response.data.status==200){
							const errors = {};
							errors.forgotError = <div className="alert alert-success alert-dismissible fade show" role="alert">
													{response.data.message}
												</div>;
							setForgotFormErrors(errors);					
						}else if(response.data.status==400){
							const errors = {};
							errors.forgotError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
													{response.data.message}
												</div>;
							setForgotFormErrors(errors);
						}else if(response.data.status==600){
							const errors = {};
							errors.email = response.data.errors.email;
							setForgotFormErrors(errors);
						}
						setisLoading(false);
					});
				});
			}
		};
		const forgotValidate = (values) => {
			const errors = {};
			const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
			if (!values.email) {
				errors.email = "Email is required!";
			} else if (!regex.test(values.email)) {
				errors.email = "This is not a valid email format!";
			}else{
				isForgotSubmit = true;
			}
			setForgotFormErrors(errors);
			return isForgotSubmit;
		}
		const [iAgree, setIAgree] = React.useState();
		const chkhandleChange = (e) => {
			let checkeddata = e.target.checked;
			let checkedValue = e.target.value;
			let checkedName = e.target.name;
			setIAgree(checkeddata);
			
		};
		//console.log("checkeddata: "+iAgree);
		console.log(registerState);
		console.log(props.countryId);
		
	//forgot password end
	return <>
		<div>
		<ToastContainer />
		{loginPopupShow ? (
		<Modal show={loginPopupShow} onHide={e => onLoginModalClose(e)} className="login_parent" backdrop="static">
			<div className="top_logo_for_mobile text-center">
				<img src={process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`} className="mobile_top_logo" alt="" srcset="" />
			</div>
			<Modal.Header closeButton className="">
				<Modal.Title className="w-100 text-center hide_title_for_mobile">{languageContent.login}
				</Modal.Title>
				<div className="text-center align-items-center w-100 modal-title text-white hide_title_for_desktop">welcome to SnapSells </div>
				{/* <button type="button" className="text-white fs-5 font-weight-bolder"  aria-label="Close">&#8592;</button> */}
			</Modal.Header>
			<Modal.Body>
			{loginFormErrors.loginError}
				<form id="loginForm" onSubmit={onLoginSubmit}>
					<div className="form-group">
					   <input type="email" className="form-control mobile_input" id="email" name="email" value={loginState.email} onChange={handleLoginChange} placeholder="Email Address" />
					   <div className="errorMesg">{loginFormErrors.email}</div>
					</div>
					<div className="form-group">
						<input type="password" className="form-control mobile_input" id="password" name="password" value={loginState.password} onChange={handleLoginChange} placeholder="Password" />
					   <div className="errorMesg">{loginFormErrors.password}</div>
					</div>
					<div className="form-group remember_div d-flex justify-content-between">
					   <div><input type="checkbox" id="rememberme" name="rememberme" onChange={rememberMe}/> <label for="remeber">Remember Me</label></div>
					  <div className="forgot forgot_p"><NavLink  style={{textDecoration: 'none'}} to="#" onClick={forgotPassword}>{languageContent.forgot_password}</NavLink></div>
					</div>
					<div className="btn btn-info btn-block btn-round submit mobile_login_btn_div">
					   <button type="submit" className="btn btn-primary mobile_login_btn" disabled={isLoading}>
						{isLoading ? <div className="btnloader"></div> : languageContent.login}
					   </button>
					</div>
					  <div className="d-flex divider_div mt-3">
						<div></div>
						<div><b> OR</b></div>
						<div></div>
					  </div>
					  <div className="text-center text-white mt-3"><b>Sign In With</b></div>
				</form>
			</Modal.Body>
			<Modal.Footer>
			{/*<div>- or_sign_in_with -</div>*/}
					<div className="socialMedia">
						<GoogleLogin
						clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
						buttonText={languageContent.login_with_gmail}
						onSuccess={handleGoogleLogin}
						onFailure={handleGoogleLogin}
						cookiePolicy={'single_host_origin'}
						autoLoad={false}
						></GoogleLogin>
					
						<FacebookLogin
						appId={process.env.REACT_APP_FACEBOOK_APP_ID}
						autoLoad={false}
						fields="name,email,picture"
						callback={handleFacebookLogin}
						textButton={languageContent.login_with_facebook}
						icon="fa-facebook"
						/>
						
						{/*<AppleLogin  
							clientId={"com.react.apple.login"} 
							redirectURI={"https://redirectUrl.com"}   
							responseType={"code"} 
							responseMode={"query"}  
							usePopup={false} 
							designProp={
							{
							   height: 50,
							   width: 300,
							   color: "black",
							   border: false,
							   type: "sign-in",
							   marginTop: 10,
							   locale: "en_US", 
							}
						   }
						/>*/}
						
					</div>
				<div className="very-footer mt-3">Dont Have Account Yet? <NavLink to="#" onClick={e => createNewAccount(e)}>Create Your Account</NavLink></div>
			</Modal.Footer>
		</Modal>
		) : (
			null
		)}
		{fPasswordPopupShow ? (
		<Modal show={fPasswordPopupShow} onHide={() => setFPasswordPopupShow(false)} className="forgot_password_parent" backdrop="static">
			<div className="top_logo_for_mobile text-center">
					<img src={process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`} className="mobile_top_logo" alt="" srcset="" />
			</div>
			<Modal.Header closeButton>
				{/* <Modal.Title className="w-100 text-center ">Forgot Password?</Modal.Title> */}
				<div className="text-center align-items-center w-100 modal-title text-white hide_title_for_desktop">Forgot Password?</div>
			</Modal.Header>
			<Modal.Body>
			{forgotFormErrors.forgotError}
				<form id="loginForm" onSubmit={onForgotSubmit}>
					<div className="form-group">
					   <input type="email" className="form-control mobile_input" id="email" name="email" value={forgotState.email} onChange={handleForgotChange} placeholder="Email Address" />
					   <div className="errorMesg">{forgotFormErrors.email}</div>
					</div>
					<div className="btn btn-info btn-block btn-round submit mobile_login_btn_div">
					   <button type="submit" className="btn btn-primary mobile_login_btn" disabled={isLoading}>
						{isLoading ? <div className="btnloader"></div> : "Submit"}
					   </button>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<div className="very-footer mt-3">Go to <NavLink to="#" onClick={e => alreadyRegister(e)}>Login</NavLink></div>
			</Modal.Footer>
		</Modal>
		) : (
			null
		)}
		{registerShow ? (
		<Modal show={registerShow} onHide={e => onRegisterModalClose(e)} className="register_parent" backdrop="static">
		<div className="top_logo_for_mobile text-center">
				<img src={process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`} className="mobile_top_logo" alt="" srcset="" />
			</div>
				{/* <div className="profile_img_div">
					<img src="https://source.unsplash.com/random" alt="Random Image" className="-img-fluid" />
				</div> */}
			<Modal.Header closeButton className="">
				<Modal.Title className="w-100 text-center">Create Your Account</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex flex-column text-center">
				{registerFormErrors.registerError}
					<form id="registerForm" onSubmit={onRegisterSubmit} method="post" enctype="multipart/form-data">
						<div className="form-group avatar-profile">
							<label className="d-none">
								<div className="imgUpload">
									<span>
									<img src={process.env.REACT_APP_SERVER_API_URL + `images/avatar.png`} width="150" height="150" id="previewProfile" alt="avatar"/>
									{/*<img src="./images/avatar.svg" width="150" height="150" id="previewProfile" alt="avatar" />*/}
									</span>
								</div>
							</label>
							{registerImage && (
								<div className="uploadedImage" style={registerImageStyles.preview}>
									<img src={URL.createObjectURL(registerImage)} style={registerImageStyles.image} alt="Thumb" />
									<button className="new_img_cross_btn" onClick={removeSelectedImage} style={registerImageStyles.delete}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1em" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
									</button>
								</div>
							)}

							<div className="inputImg support_for_mobile" style={registerImageStyles.container}>
								<label className="image-upload">
									<img src={process.env.REACT_APP_FRONT_URL + `images/smile.png`} alt="Profile Image" />
									<div className="overlay">
									<span className="upload-icon">+</span>
									</div>
									<input type="file" className="file-input uploadImg" accept="image/*" onChange={(e) => setRegisterImage(e.target.files[0])} />
								</label>
							</div>

							{/* <div className="inputImg" style={registerImageStyles.container}>
								<input accept="image/*" type="file" className="uploadImg" onChange={(e) => setRegisterImage(e.target.files[0])} />
								<span>Add Profile Picture</span>
							</div> */}
						</div>
						<div className="form-group">
						   <input type="text" className="form-control mobile_input" id="name" name="name" value={registerState.name} onChange={handleRegisterChange} placeholder="Name" />
						   <div className="errorMesg">{registerFormErrors.name}</div>
						</div>
						<div className="form-group">
						   <input type="email" className="form-control mobile_input" id="email" name="email" value={registerState.email} onChange={handleRegisterChange} placeholder="Email Address" />
						   <div className="errorMesg">{registerFormErrors.email}</div>
						</div>
						<div className="form-group">
						{/*<input type="text" className="form-control" id="mobile_number" name="mobile_number" value={registerState.mobile_number} onChange={handleRegisterChange} placeholder="Mobile Number" />*/}
						   
						   <PhoneInput enableSearch={true} countryCodeEditable={false} inputProps={{ name: 'mobile_number', id: 'mobile_number', className: 'form-control mobileInput mobile_input' }} value={registerState.mobile_number} onChange={phone => registerSetState({ ...registerState, ['mobile_number']: phone})} country={localStorage.getItem("country_code")}/>
						   
						   <div className="errorMesg">{registerFormErrors.mobile_number}</div>
						</div>
						<div className="form-group">
							{props.countryId == '' ? (
								<select className="form-control custom_country_select test1" id="country_id" value={props.countryId} name="country_id" onChange={handleRegisterChange}>
									<option value=''>-select_country-</option>
									{countryName.map((val) => {
											return <>	
											<option value={val.id} key={val.id}>{val.country_name}</option>
										</>;
									})}
								</select>
							) : (
								<select className="form-control custom_country_select test2" id="country_id" value={props.countryId} name="country_id" onChange={handleRegisterChange} disabled>
									<option value=''>-select_country-</option>
									{countryName.map((val) => {
											return <>	
											<option value={val.id} key={val.id}>{val.country_name}</option>
										</>;
									})}
								</select>
							)}
							<div className="errorMesg">{registerFormErrors.country_id}</div>
						</div>
						<div className="form-group">
						<Autocomplete
						  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
						  onPlaceSelected={handlePlaceChange}
						  className="form-control mobile_input"
						  placeholder="City"
						  options={{
							types: ["geocode", "establishment"],
						  }}
						/>
						</div>
						{/*<div className="form-group">
							{useAddress ?(
								<input type="text" className="form-control" id="location" name="location" onChange={handleRegisterChange} placeholder="Location" value={useAddress}/>
						   <div className="errorMesg">{registerFormErrors.location}</div>
						   ) : (
								<input type="text" className="form-control" id="location" name="location" onChange={handleRegisterChange} placeholder="Location" value==""/>
						   
							)
							<input type="hidden" id="latitude" name="latitude" />
							<input type="hidden" id="longitude" name="longitude" />
						</div>*/}
						<div className="form-group">
						   <input type="password" className="form-control mobile_input" id="password" name="password" value={registerState.password} onChange={handleRegisterChange} placeholder="Password" />
						   <div className="errorMesg">{registerFormErrors.password}</div>
						</div>
						<div className="form-group">
						   <input type="password" className="form-control mobile_input" id="password_confirmation" name="password_confirmation"  value={registerState.password_confirmation} onChange={handleRegisterChange}placeholder="Confirm Password" />
						</div>
						<div className="form-group d-flex justify-content-between">
						   <div><input type="checkbox" id="agree" name="agree" onChange={chkhandleChange}/> <label for="agree">{languageContent.i_agree}&nbsp;&nbsp;<NavLink className={"text_white_for_mobile"} to="privacy-policy" target="blank">Legal and privacy information</NavLink></label></div>
						</div>
						<div className="btn btn-info btn-block btn-round submit mobile_login_btn_div">
						   <button type="submit" className="btn btn-primary mobile_login_btn" disabled={isLoading}>
							{isLoading ? <div className="btnloader"></div> : languageContent.sign_up}
						   </button>
						</div>
					</form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="very-footer">Already have an account? <NavLink to="#" onClick={e => alreadyRegister(e)}>{languageContent.login}</NavLink></div>
			</Modal.Footer>
		</Modal>
		) : (
			null
		)}
		{verifyAccount ? (
		<Modal show={verifyAccount} onHide={() => verifyAccountShow(false)} backdrop="static">
			<Modal.Header closeButton className="modalClose"></Modal.Header>
			<Modal.Body className="alertBody">
				<div className="vAccount">
					<div className="iconAlert"><AlertCircle /></div>
					<h6>Verify Your Account</h6>
					<p>Your Registration has been done successfully but your account is not verified, if you want to verify your account kindly pay $1.So we will verify your account successfully.</p>
					<button className="btn btnSkip">Skip</button>
					<button className="btn btnVerify">Verify Now <ArrowForward /></button>
				</div>
			</Modal.Body>
		</Modal>
		) : (
			null
		)}
		{mobileVerify ? (
		<Modal show={mobileVerify} onHide={() => mobileVerifyShow(false)} backdrop="static">
			<Modal.Header closeButton className="modalClose"></Modal.Header>
			<Modal.Body className="alertBody">
				<div className="vAccount vDetails">
					<div className="iconAlert mVerify"><PhonePortraitSharp /></div>
					<h6>Mobile Number Verification</h6>
					<p>We have send you a verification code on your mobile number.</p>
					<p className="mobileNumber">+44 7832 160133</p>
					<div className="enterCode">
						<h5>Enter 4 Digit Code</h5>
						<div className="iCode"><input type="text" className="form-control" /><input type="text" className="form-control" /><input type="text" className="form-control" /><input type="text" className="form-control" /></div>
						<div className="rCode">Did't receive the code?<Link to="#">Resend Code</Link></div>
					</div>
					<button className="btn btnVerify">Submit <ArrowForward /></button>
				</div>
			</Modal.Body>
		</Modal>
		) : (
			null
		)}
		</div>
	</>;
}
export default Loginmodal;