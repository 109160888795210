import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import Sponsoredplans from "./Sponsored-plans";
import axios from "axios";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const labelStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
};

const RenewBulletinHistory = ({
  open,
  onClose,
  itemDetails,
  onRenew,
  userContent,
  props,
  setApiReload
}) => {
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  const [paymentID, setPaymentId] = useState();
  const [paymentState, paymentSetState] = React.useState({
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    street: "",
    city: "",
    zipcode: "",
    country_id: "",
  });

  const [itemState, itemSetState] = React.useState({
		first_name: "",
		last_name: "",
		business_name: "",
		email_id: "",
		mobile_number: "",
		web_url: "",
		user_location: "",
		package: "",
		country_id: props.countryId,
		advertisement_duration: "",
		message: "",
    id:"",
	});

  const [listPaymentMethod, setListPaymentMethod] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-payment-method/${loggedInUserId}`).then((response)=>{
			setListPaymentMethod(response.data);
			// console.log(response.data,"cardDetails");
		});
		});
	}, []);

  let [bulletinPackage, setBulletinPackage] = useState([]);
  useEffect(() => {
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.get(`/api/list-bulletin-package`).then((response) => {
        console.log(response.data, "bulletinPackage");
        setBulletinPackage(response.data);
      });
    });
  }, []);

  const handleItemChange = ({ target })=>{
		itemSetState({ ...itemState, [target.name]: target.value});
	};

  console.log(itemState,'itemState')
  const [paymentMethodState, setPaymentMethodState] = useState({
		method: ""
	});
  const handlePaymentMethodChange = ({ target })=>{
		setPaymentMethodState({ ...paymentMethodState, [target.name]: target.value});
	};

  useEffect(() => {
    var obj = JSON.parse(userContent.payment_details);
    paymentSetState({
      ...paymentState,
      ["first_name"]: obj.first_name || obj.FirstName,
      ["last_name"]: obj.last_name || obj.LastName,
      ["country"]: obj.country || obj.Country,
      ["address"]: obj.address || obj.Address,
      ["street"]: obj.street || obj.Street,
      ["city"]: obj.city || obj.City,
      ["zipcode"]: obj.zipcode || obj.ZipCode,
      ["country_id"]: obj.country_id || obj.Country_Id,
    });
  }, [userContent]);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      bulletin_id: itemDetails?.id,
      userId: loggedInUserId,
      plan_id: itemState?.id,
      amount: itemDetails?.ad_amount,
      paymentState,
      currency: "",
      id: paymentMethodState?.method,
      // item_id: itemDetails?.id,
      // userId: loggedInUserId,
      // plan_id: itemDetails?.plan_id,
      // amount: itemDetails?.plan_amount,
      // paymentState,
      // currency: itemDetails?.currency_code,
      // id: paymentID,
    };

    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios.post(`/api/renew-user-bulletin`, payload).then((response) => {
        //console.log(response.data);
        if (response.status == 200) {
          onClose();
          toast(response.data.message);
          setApiReload(true)
        } else if (response.data.status == 400) {
          //console.log(response.data.message);
          const errors = {};
          errors.loginError = (
            <div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              {response.data.message}
            </div>
          );
        } else if (response.data.status == 600) {
          const errors = {};
          errors.email = response.data.errors.email;
          errors.password = response.data.errors.password;
        }
      });
    });
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography
          variant="h6"
          component="h2"
          align="center"
          fontWeight="bold"
        >
          Renew Bulletin History
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Name:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.first_name + " " + itemDetails?.last_name}
            </Typography>
          </Box>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Business Name:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.business_name}
            </Typography>
          </Box>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Expiry Date:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.expiry_date}
            </Typography>
          </Box>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Plan Amount:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.ad_amount}
            </Typography>
          </Box>
        </Box>
        <Box>
          <div className="col-md-4">
            <div className="mb-3">
              <label for="dName" className="form-label">
                Select Package
              </label>
              <select
                className="form-control"
                id="id"
                name="id"
                onChange={handleItemChange}
                value={itemState.id}
              >
                <option value="">-select_package</option>
                {bulletinPackage.map((val) => {
                  return (
                    <>
                      <option value={val.id}>{val.package_name}</option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>
          <h4>Card details</h4>
			{listPaymentMethod?.map((val) => {
				return <div key={val.card.id}>
					<div className="d-flex">
						<input type="radio" className="savedCardRadio" value={val.id} name="method" onChange={(e)=>handlePaymentMethodChange(e)}/>
						<div>{val.card.brand} {val.card.funding} card <strong>xxxx {val.card.last4}</strong></div>
					</div>
					<div className="m-4 mt-0">Expires {val.card.exp_month}/{val.card.exp_year}
					</div>
				</div>;
			})}
          {/* <Sponsoredplans props={props?.props} setPaymentId={setPaymentId} /> */}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="secondary"
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <Button onClick={(e)=>onSubmit(e)} variant="contained" color="primary">
            Renew
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RenewBulletinHistory;
