import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, Heart, StarOutline, Star, PencilSharp, LogOutOutline, MenuOutline, CloseCircleOutline } from 'react-ionicons';
import axios from "axios";
import Userleftmenu from './Userleftmenu';
import ReactPaginate from "react-paginate";

const Likeitem = (props) => {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	let languageContent = props.languageContent;
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//const [show, setShow] = React.useState();
	
	//---- favourite item list show---
	const [favouriteItemsList, setFavouriteItemsList] = useState([]);
	const [getitemsPerPage, setGetItemsPerPage] = useState();
	const [itemsPerPage, setItemsPerPage] = useState();
	const [pagiLoop, setpagiLoop] = useState();
	
	const [itemsLoad, setItemsLoad] = useState();
	const [visible, setVisible] = useState();
	
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/user-like-items-list/${loggedInUserId}/${props.language}`).then((response)=>{		
			// console.log(response.data);
			setFavouriteItemsList(response.data.favoriteArray);
			setItemsPerPage(response.data.per_page_list);
			setGetItemsPerPage(response.data.per_page_list);
			setpagiLoop(response.data.per_page_pagination_loop);
			
			//document.body.classList.remove('disable_load_content');
			
			setItemsLoad(response.data.items_load_more);
			setVisible(response.data.items_load_more);
		});
		});
	}, [props.language]);
	
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue + itemsLoad);
	};
	
	const favouriteDeleteClick = value => () => {
		//console.log(value);
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/favourite-item-delete/${loggedInUserId}/${id}`).then((response)=>{
				//console.log(response.data);
				setFavouriteItemsList(response.data.favoriteArray);
				setItemsPerPage(response.data.per_page_list);
				setGetItemsPerPage(response.data.per_page_list);
				setpagiLoop(response.data.per_page_pagination_loop);
			});
		 });
	};
	//--- pagination start
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const handleShowChange = ({ target })=>{
		setItemsPerPage(target.value);
	};
	
	useEffect(() => {
		const endOffset = Number(itemOffset) + Number(itemsPerPage);
		setCurrentItems(favouriteItemsList.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(favouriteItemsList.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, favouriteItemsList]);
	
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % favouriteItemsList.length;
		setItemOffset(newOffset);
	};
	
	//--- pagination end
	var lis = [];
	for (var i=1; i<pagiLoop+1; i++) {
		lis.push(<option value={getitemsPerPage*i} >{getitemsPerPage*i}</option>);
	}
	//----------------------------
	return <>
		<section>
			<div className="products-section-innerpage fav_item_parent ">
				<div className="container myLeftContainer">
					<div className="row">
					<Userleftmenu state={{userContent: props.userContent }} userContent={props.userContent} state={{ languageContent: props.languageContent }} languageContent={props.languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Like Item</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home} </Link>/ Like Item</div>
									</div>
								</div>
							</div>
							<div className="reviewPart desktopView">
								<div className="row">
								{currentItems.length ? (
									<div className="col-md-12">
										<div className="row">
										{currentItems.map((val) => {
						                return <>
											<div className="col-md-4 myItemCat mb-2">
												<div className="favItem">
													<Link to={"/item/"+`${val.slug}-id-${val.id}`}>
														<div className="imgPar sponsoredBadge"><img src={process.env.REACT_APP_SERVER_API_URL + `images/items/${val.id}/${val.image}`} alt="Ferrari" />
														{val.is_sponsered == 1 ? (
														<span className="sponsored_item">Sponsored</span>) : ''
														}
														</div>
													</Link>
													<div className="imgDetails">
														<h6>$ {val.price}</h6>
														<p><Link to={"/item/"+`${val.slug}-id-${val.id}`}>{val.itemname}</Link></p>
														<p>{val.categoryname}</p>
													</div>
													<div className="rFav">
														<Link to="#" value={val.id} onClick={favouriteDeleteClick(`${val.id}`)}><Heart />Remove from Favourites</Link>
													</div>
												</div>
											</div>
											</>;
					                    })}
										</div>
										<div className="pagination">
											<div className="paginationLeft">
												<ReactPaginate
													breakLabel="..."
													nextLabel="next"
													onPageChange={handlePageClick}
													pageRangeDisplayed={2}
													pageCount={pageCount}
													previousLabel="prev"
													nextClassName={""}
													renderOnZeroPageCount={null}
													activeClassName={"active"}
													previousClassName={""}
												/>
											</div>
											<div className="showRight">
												<div className="form-group mySelect"><small>show</small>
													<select className="form-control" onChange={handleShowChange}>
														{lis} 
													</select>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className="noRecord">
										<h6>No Record Found</h6>
									</div>
								)}
								</div>
							</div>
							<div className="reviewPart mobileView">
								<div className="row">
								{favouriteItemsList.length ? (
									<div className="col-md-12">
										<div className="row">
										{favouriteItemsList.slice(0, visible).map((val) => {
										// {currentItems.map((val) => {
						                return <>
											<div className="col-md-4 myItemCat mb-2">
												<div className="favItem">
													<Link to={"/item/"+`${val.slug}-id-${val.id}`}>
														<div className="imgPar sponsoredBadge"><img src={process.env.REACT_APP_SERVER_API_URL + `images/items/${val.id}/${val.image}`} alt="Ferrari" />
														{val.is_sponsered == 1 ? (
														<span className="sponsored_item">Sponsored</span>) : ''
														}
														</div>
													</Link>
													<div className="imgDetails">
														<h6>$ {val.price}</h6>
														<p><Link to={"/item/"+`${val.slug}-id-${val.id}`}>{val.itemname}</Link></p>
														<p>{val.categoryname}</p>
													</div>
													<div className="rFav">
														<Link to="#" value={val.id} onClick={favouriteDeleteClick(`${val.id}`)}><Heart />Remove from Like</Link>
													</div>
												</div>
											</div>
											</>;
					                    })}
										</div>
										{visible < favouriteItemsList.length && (
											<div className="col-md-12 mt-3 mb-3 text-center">
												<button type="submit" className="btn btn-primary" onClick={loadMoreItems}>Show More</button>
											</div>
										)}
									</div>
								) : (
									<div className="noRecord">
										<h6>No Record Found</h6>
									</div>
								)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Likeitem;