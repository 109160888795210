import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Create, MenuOutline, CloseCircleOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Userleftmenu from './Userleftmenu';
import axios from "axios";

function Userdashboard(props) {
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	let languageContent = props.languageContent;
	//console.log("la "+props.languageContent);
	/*const location = useLocation();
	const { userContent } = location.state;*/
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	
	//console.log(loggedInUserId);
	const [userContent, setUserContent] = useState([]);
	const [profileState, profileSetState] = useState([]);
	const [profileRating, profileSetRating] = useState([]);
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user-dashboard/${loggedInUserId}`).then((response)=>{
					//console.log(response.data);
					setUserContent(response.data);
					profileSetState(response.data);
					//document.body.classList.remove('disable_load_content');
					//this.setState({ myArray: [...this.state.myArray, 'new value'] })
				});
			});
		}
	}, [loggedInUserId]);
	
	//console.log(props.userContent);
	//let userContent = props.userContent;
	//edit my profile start
		const [profileFormErrors, setProfileFormErrors] = useState([]);
		let isProfileSubmit = false;
		//let loading = false;
		const [isLoading, setisLoading] = useState(false);
		/*const [profileState, profileSetState] = useState({
			profile_picture: '',
			name: userContent.name,
			email: userContent.email,
			mobile_number: userContent.mobile_number,
			current_password: "",
			password: "",
			password_confirmation: ""
		});*/
		const [profileImage, setProfileImage] = useState();
		/*const phoneChange = ({ target })=>{
			console.log(target.value);
		};*/
		const handleProfileChange = ({ target })=>{
			profileSetState({ ...profileState, [target.name]: target.value});
			//console.log(profileState);
		};
		// This function will be triggered when the "Remove This Image" button is clicked
		const removeSelectedImage = () => {
			setProfileImage();
		};
		//console.log(profileState);
		
	
		
	//Edit my profile end
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	//console.log("edit Profile: "+languageContent.edit_profile);
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						<Userleftmenu  state={{userContent: userContent }} userContent={userContent} languageContent={languageContent} isOpen={isOpen}/>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>User Dashboard</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}  </Link>/User Dashboard</div>
									</div>
								</div>
							</div>
							<div className="pInfo">
								<h4>Personal Information</h4>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Userdashboard;
