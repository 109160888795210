import React from "react";
import { Link } from 'react-router-dom';

//const Tophead = (props) => {
function Tophead(props) {	

	return <>
		<select className="form-control" id="languagechange" value={props.language} onChange={e => props.handleSetLanguage(e.target.value)}>
			<option value="english" >English</option>
			<option value="german" >German</option>
			<option value="armenian" >Armenian</option>
			<option value="russian" >Russian</option>
			<option value="hebrew" >Hebrew</option>
			<option value="farsi" >Farsi</option>
			<option value="spanish" >Spanish</option>
		</select>
	</>;
}

export default Tophead;
