import React, { useEffect, useState } from "react";
import TransactionTable from "./TransactionTable";
import axios from "axios";
import { CircularProgress, Typography } from "@mui/material";

const TransactionHistory = () => {
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    setLoading(true); // Set loading to true when starting API call
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/user-transactions/${loggedInUserId}`)
        .then((response) => {
          setData(response.data);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when API call is complete
        });
    });
  }, []);

  return (
    <div>
      {loading ? ( // Show loader when loading
        <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {data?.data && data.data.length > 0 ? ( // Check if there are transactions
            <TransactionTable tableData={data.data} />
          ) : (
            <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
              No Transaction History Found
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default TransactionHistory;
