import React, { useState, useEffect } from "react";
import axios from "axios";
import  { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Resetpassword(props) {
	
	const {email} = useParams();
	
	//let languageStoredInLocalStorage = localStorage.getItem("language");
	//let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "english");
	
	// console.log("language::"+props.language);
	const [resetFormErrors, setResetFormErrors] = useState([]);
	const [isLoading, setisLoading] = useState(false);
	let isResetSubmit = false;
	const [resetState, resetSetState] = React.useState({
		email: email,
		password: "",
		password_confirmation: ""
	});
	const handleResetChange = ({ target })=>{
		resetSetState({ ...resetState, [target.name]: target.value});
	};
	
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	//console.log(resetState);
	const onResetSubmit = (e) => {
		e.preventDefault();
		resetValidate(resetState);
		if(isResetSubmit){
			setisLoading(true);
			//console.log(resetState);
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/resetpassword/${props.language}`, resetState).then(response => {
					if(response.data.status==200){
						const errors = {};
						errors.resetError = <div className="alert alert-success alert-dismissible fade show" role="alert">
												{response.data.message}
											</div>;
						toast(response.data.message);						
						setResetFormErrors(errors);
					}else if(response.data.status==400){
						//console.log(response.data.message);
						const errors = {};
						errors.resetError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
												{response.data.message}
											</div>;
						setResetFormErrors(errors);
					}else if(response.data.status==600){
						const errors = {};
						errors.password = response.data.errors.password;
						setResetFormErrors(errors);
					}
					setisLoading(false);
				});
			});
		}
	};
	const resetValidate = (values) => {
		const errors = {};
		if (!values.password) {
			isResetSubmit = false;
			errors.password = "Password is required!";
		}else if(values.password.length < 8){
			isResetSubmit = false;
			errors.password = "The password must be at least 8 characters!";
		}else if(values.password != values.password_confirmation) {
			isResetSubmit = false;
			errors.password_confirmation = "New password and confirm password not matched!";
		}else{
			isResetSubmit = true;
		}
		setResetFormErrors(errors);
		return isResetSubmit;
	}
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="page-title row">
								<div className="col-md-12">
									<h1>Reset Password</h1>
									<div className="breadcrumb">Home  /  Reset Password</div>
								</div>
							</div>
							<div className="list-an-item-content">
								<div className="row upload-right">
									{resetFormErrors.resetError}
									<form id="forgotpassword" onSubmit={onResetSubmit}>
										<div className="form-group">
											<input type="password" className="form-control" name="password" value={resetState.password} onChange={handleResetChange} placeholder="New password" />
										   <div className="errorMesg">{resetFormErrors.password}</div>
										</div>
										<div className="form-group">
											<input type="password" className="form-control" name="password_confirmation" value={resetState.password_confirmation} onChange={handleResetChange} placeholder="confirm password" />
										   <div className="errorMesg">{resetFormErrors.password_confirmation}</div>
										</div>
										<div className="messages-content">
											<div className="messages failure" style={{display: "none"}}>
												<p>Sorry. Please try again or contact to support</p>
											</div>
											<div className="messages success" style={{display: "none"}}>
												<p>Plese check your email, Password reset link sent succesfully.</p>
											</div>
											<div className="messages email_not_exists" style={{display: "none"}}>
												<p>Sorry.This email address not exists.</p>
											</div>
										</div>
										<div className="btn btn-info btn-block btn-round submit">
										<button type="submit" className="btn btn-primary" disabled={isLoading}>
											{isLoading ? <div className="btnloader"></div> : "Send"}
										</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Resetpassword;
