import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Modal from 'react-bootstrap/Modal';
import PaymentForm from "./PaymentForm"

export default function StripeContainer(props) {
	const PUBLIC_KEY = props.publicKey;

	const stripeTestPromise = loadStripe(PUBLIC_KEY)
	
	// console.log(props.showPayment);
	function onPaymentClose() {
		props.onPaymentModalClose(false);
	}
	return (
		<Modal className="PaymentModal" show={props.showPayment} onHide={e => onPaymentClose(e)}>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<Elements stripe={stripeTestPromise}>
					<PaymentForm paymentAmount={props.paymentAmount} getPlan={props.getPlan} getPackageType={props.getPackageType}/>
				</Elements>
			</Modal.Body>
		</Modal>
	)
}