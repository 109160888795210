import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, Create, MenuOutline, CloseCircleOutline } from 'react-ionicons';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import Userleftmenu from './Userleftmenu';
import Userdetailsleftmenu from './Userdetailsleftmenu';
import axios from "axios";

import { ThumbsUp, ThumbsUpOutline, Heart,  Location} from 'react-ionicons';

import ReactStars from "react-rating-stars-component";
import ReactPaginate from "react-paginate";
import Loginmodal from '../Loginmodal';


function Userprofiledetails(props) {
	const navigate = useNavigate()
	//alert('users');
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	let languageContent = props.languageContent;
	const [loginPopupShow, setLoginPopupShow] = useState(false);
	const [registerShow, setRegisterShow] = useState(false);
	const { id } = useParams();
	let sid      = id;
	//const { slug } = useParams();
	
	//console.log("pageslug "+pageslug);
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	//console.log(loggedInUserId);
	const [userContent, setUserContent] = useState([]);
	const [profileState, profileSetState] = useState([]);
	const [profileRating, profileSetRating] = useState([]);
	const [redirectUrl, setRedirectUrl] = useState([]);
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user-blockedby-id/${loggedInUserId}/${sid}`).then((response)=>{
					//console.log("return:-"+response.data);
					//alert(response.data);
					if(response.data == 2)
					{
						navigate(`/`);
					}
					else
					{
						setUserContent(response.data);
						profileSetState(response.data);
					}
					//this.setState({ myArray: [...this.state.myArray, 'new value'] })
				});
			});
		}
	}, []);
	
	
	const [allItemsList, setAllItemsList] = useState([]);
	//const [loadMoreItemsList, setLoadMoreItemsList] = useState();
	const [itemsLoad, setItemsLoad] = useState();
	const [visible, setVisible] = useState();
	
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/user-all-items/${id}/${props.language}`).then((response)=>{		
				//console.log(response.data);
				setAllItemsList(response.data.arrItemDetails);
				setItemsLoad(response.data.items_load_more);
				setVisible(response.data.items_load_more);
			});
		});
	}, [id, props.language]);
	
	
	//const [visible, setVisible] = useState(1);
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue+itemsLoad);
	};
	//console.log("load more "+visible);
	const likeMeClick = indexLike => {
		var id = allItemsList[indexLike].id;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/category-item-like/${sid}/${id}`).then((response)=>{	
				let itemListSet = [ ...allItemsList ];
				itemListSet[indexLike] = {...itemListSet[indexLike], likeclass: response.data.likeclass};
				setAllItemsList(itemListSet);
			});
		 });
	};
	const favouriteMeClick = indexFav => {
		var id = allItemsList[indexFav].id;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/category-item-favourite/${sid}/${id}`).then((response)=>{
				let itemListSet = [ ...allItemsList ];
				itemListSet[indexFav] = {...itemListSet[indexFav], favouriteclass: response.data.favouriteclass};
				setAllItemsList(itemListSet);
			});
		 });
	};
	/*const likeMeClick = value => () => {
		//console.log(value);
		//alert("hello");
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/home-item-like/${sid}/${id}`).then((response)=>{	   
				//console.log(response.data);
			     //setLkClass(response.data.likeclass);
			     //console.log("liks: "+likeclass);
				 setAllItemsList(response.data);
			});
		 });
	};
	
	const favouriteMeClick = value => () => {
		//console.log(value);
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/home-item-favourite/${sid}/${id}`).then((response)=>{	
				//console.log(response.data);
				//setFavClass(response.data.favouriteClass);
				//console.log("fav: "+favsClass);
				setAllItemsList(response.data);
			});
		 });
	};*/
	
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	//----- for review section ------
	const [avgRatingItem, setAvgRatingItem] = useState([]);
	const [reviewDetails, reviewSetDetails] = useState([]);
	const [getitemsPerPage, setGetItemsPerPage] = useState();
	const [itemsPerPage, setItemsPerPage] = useState();
	const [pagiLoop, setpagiLoop] = useState();
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/review-details/${sid}`).then((response)=>{
				//console.log(response.data);
				reviewSetDetails(response.data.getreviewdetails);
				setItemsPerPage(response.data.per_page_list);
				setGetItemsPerPage(response.data.per_page_list);
				setpagiLoop(response.data.per_page_pagination_loop);
				//document.body.classList.remove('disable_load_content');
			});
		});
	}, [id]);
	//console.log(avgRatingItem);
	
	//--- pagination start
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const handleShowChange = ({ target })=>{
		setItemsPerPage(target.value);
	};
	useEffect(() => {
		const endOffset = Number(itemOffset) + Number(itemsPerPage);
		setCurrentItems(reviewDetails.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(reviewDetails.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, reviewDetails]);
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % reviewDetails.length;
		setItemOffset(newOffset);
	};
	//--- pagination end
	var lis = [];
	for (var i=1; i<pagiLoop+1; i++) {
		lis.push(<option value={getitemsPerPage*i} >{getitemsPerPage*i}</option>);
	}
	//console.log(allItemsList);
	
	
	return <>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
					{/*<Userleftmenu  state={{userContent: userContent }} userContent={userContent} languageContent={languageContent}/>*/}
						<Userdetailsleftmenu  state={{userContent: userContent }} userContent={userContent} languageContent={languageContent} isOpen={isOpen}/>
						
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							<div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>User Details</h1>
										<div className="breadcrumb"><Link to="/">{languageContent.home}  </Link>/ User Details</div>
									</div>
								</div>
							</div>
							<div className="pInfo">
								<h4 className="section-title mb-3 ml-1">List User Items</h4>	
								{allItemsList.length ? (
								<div className="grid-container">
								{localStorage.getItem("loggedin_user_id") === null ? (
								<Loginmodal languageContent={languageContent} loginPopupShow={loginPopupShow} registerShow={registerShow} onLoginPopupCloseModal={() => setLoginPopupShow(false)} onRegisterPopupCloseModal={() => setRegisterShow(false)} chgUserContent={userContent => setUserContent(userContent)}/>
								) : (
									null
								)}
								{allItemsList.slice(0, visible).map((val, i) => {
								//{allItemsList.map((val) => {
									return <>	
										<div className="col-md-4">
											<div className="product-card">
												<Link to={"/item/"+`${val.slug}-id-${val.id}`} className="HomeItemImage">
													<div className="product-image sponsoredBadge">
														<img src={process.env.REACT_APP_SERVER_API_URL + `images/items/${val.id}/${val.image}`} alt="Ferrari" />
														{val.is_sponsered == 1 ? (
														 <span className="sponsored_item">Sponsored</span>) : ''
														}
													</div>	
												</Link>							
												<div className="product-info">
												{localStorage.getItem("loggedin_user_id") === null ? (
													<div className="like-fav d-flex">
														<Link to value={val.id} onClick={() => setLoginPopupShow(true)} className=""><ThumbsUpOutline /></Link>
														<Link to onClick={() => setLoginPopupShow(true)} className=""><HeartOutline /></Link>
													</div>
												) : (
													<div className="like-fav d-flex">
														<Link to value={val.id} onClick={() => likeMeClick(i)} className="">
														{ val.likeclass==1 ? <ThumbsUp/> :<ThumbsUpOutline/>  }
														</Link>
														<Link to="#" value={val.id} onClick={() => favouriteMeClick(i)} className="" data-id="296">
														{ val.favouriteclass==1 ? <Heart/> :<HeartOutline />}
														
														
														</Link>
													</div>
												)}
													<p className="product-price">$ {val.price}</p>
													<h3 className="product-title">
														<Link to={"/item/"+`${val.slug}-id-${val.id}`}>
														{val.itemname}</Link>
													</h3>
													<ul>
														<li>
															{val.categoryname}				
														</li>
													</ul>
													<p className="location d-flex">
														<Location />
														<span className="location-text-limit"> {val.location} </span>
													</p>
												</div>
												
											</div>
										</div>
										</>;
									})}
									{visible < allItemsList.length && (
										<div className="col-md-12 mt-3 mb-3 text-center">
											<button type="submit" className="btn btn-primary" onClick={loadMoreItems}>Show More</button>
										</div>
									)}
								</div>
								) : (
									<div className="noRecord">
										<h6 className="m-0">No Record Found</h6>
									</div>
								)}	
								<h4 className="section-title mb-3 ml-1">Reviews Details</h4>
								{/*----------------review section start----------------*/}						
								<div className="detailsReview">
								{currentItems.length ? (
									<div className="detailsPart">
										<div className="reviewPart">
											
											<div className="row">
												<div className="col-md-12">
												{currentItems.map((val) => {
												return <>
													<div className="reviewMedia">
														<div className="rMedia">
															<img src={val.profile_picture} alt="Avatar" />
														</div>
														<div className="rText rating-star-disable">
															<h6>{val.item_name}</h6>
															
															<ReactStars count={5} key={val.avg_rating} value={val.avg_rating} size={24} 
												activeColor="#3498DB" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/>,
															<p className="pAmount">${val.price}.00</p>
															<p className="pTime">{val.dates}</p>
														</div>
														<div className="clearfix"></div>
														<div className="rFeedback">
														{val.message}.
														</div>
													</div>
												</>;
												})}
												</div>
											</div>
										</div>		
										<div className="pagination">
											<div className="paginationLeft">
												<ReactPaginate
													breakLabel="..."
													nextLabel="next"
													onPageChange={handlePageClick}
													pageRangeDisplayed={2}
													pageCount={pageCount}
													previousLabel="prev"
													nextClassName={""}
													renderOnZeroPageCount={null}
													activeClassName={"active"}
													previousClassName={""}
												/>
											</div>
											<div className="showRight">
												<div className="form-group mySelect"><small>show</small>
													<select className="form-control" onChange={handleShowChange}>
														{lis} 
													</select>
												</div>
											</div>
										</div>		
									</div>
								) : (
									<div className="noRecord">
										<h6 className="m-0">No Record Found</h6>
									</div>
								)}	
								</div>
		{/*-------------- review section end--------------------*/}	
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Userprofiledetails;
