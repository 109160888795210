import React from "react";
import { Link } from 'react-router-dom';
import { GridOutline, ListOutline, Location } from 'react-ionicons'

const About = () => {
	return <>
		<section className="page-title">
			<div className="container">
				<div className="row">
					<h1>Appliances</h1>
					<div className="breadcrumb"><Link to="/">Home  </Link>/ Appliances</div>
				</div>
			</div>
		</section>
		<section>
			<div className="products-section-innerpage">
				<div className="container">
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 categoris-column">
						    <h2 className="section-title mb-4"></h2>
						</div>
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 products-column">
							<div className="d-flex justify-content-between mb-4 listing-header">
								Showing results  1
								<div className="btn-group d-none-mob list-grid">
									<Link to="#" id="grid"><GridOutline className="md hydrated" /></Link>
									<Link to="#" id="list"><ListOutline className="md hydrated" /></Link>
								</div>
							</div>
							<div id="products" className="grid-container row view-group">
								<div className="col-md-4 item">
								   <div className="product-card">
										<div className="product-image">
										   <Link to="../item/Ferrari.html"><img src="../../snapsells-userfiles-mobilehub-523275799.s3.amazonaws.com/upload/items/item_1621332176_1.jpg" alt="Ferrari" /></Link>
										</div>
										<div className="product-info">
											<p className="product-price">$ 25</p>
											<h3 className="product-title"><Link to="../item/Ferrari.html">Ferrari</Link></h3>
											<ul>
												<li>Electronics,</li>
												<li>Phone,</li>
												<li>Apple</li>
											</ul>
											<p className="location d-flex"><Location className="md hydrated" />Mohali, Punjab, India</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default About;
