import React, { useState, useEffect, Component, setState } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useParams, useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Star, StarOutline, Heart, HeartOutline, ShareSocialOutline, Location, TimeOutline, TrendingUpSharp } from 'react-ionicons';
import { Carousel } from 'react-carousel-minimal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import MapContainer from "../MapContainer";
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import useGeoLocation from "../../hooks/useGeoLocation";
import useGetItemPrice from "../../hooks/useGetItemPrice";
import Loginmodal from '../Loginmodal';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import Autocomplete from "react-google-autocomplete";

import {FacebookShareButton,FacebookIcon, WhatsappShareButton, WhatsappIcon, InstapaperShareButton, InstapaperIcon, TwitterShareButton,TwitterIcon, generateShareIcon,EmailShareButton, EmailIcon} from "react-share";
import { Helmet } from "react-helmet";
import { Popper } from 'react-popper';
import ReactStars from "react-rating-stars-component";
import Instagram from 'react-share-icons/lib/Instagram';
import InstagramShareButton from 'react-share-icons/lib/Instagram';
import InstagramIcon from 'react-share-icons/lib/Instagram';
import Stories from 'react-insta-stories';

function Itemdetails(props) {
	
	const navigate = useNavigate();
	/*useEffect(()=> {
		//document.body.classList.add('disable_load_content');
	}, []);*/
	let [userContent, setUserContent] = useState(props.userContent);
	
	useEffect(()=> {
		props.chgUserContent(userContent);
	}, [userContent]);
	const [loginPopupShow, setLoginPopupShow] = useState(false);
	const [registerShow, setRegisterShow] = useState(false);
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	
	let languageContent = props.languageContent;
	
	//const getitempr = getItemPrice();
	
	const getLocation = useGeoLocation();
	const [useLocation, setUseLocation] = useState({lat: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LATITUDE, lng: process.env.REACT_APP_GOOGLE_MAP_DEFAULT_LONGITUDE});
	useEffect(()=> {
		if(getLocation.status==200){
			const newUserPos = { 
				lat: getLocation.coordinates.lat,
				lng: getLocation.coordinates.lng,
			};
			setUseLocation(newUserPos);
		}
	}, [getLocation]);
	const [meetingState, meetingSetState] = React.useState([]);
	const [useAddress, setUseAddress] = useState();
	useEffect(()=> {
		fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+useLocation.lat+','+useLocation.lng+'&key='+process.env.REACT_APP_GOOGLE_MAP_API_KEY)
			.then((response) => response.json())
			.then((responseJson) => {
				setUseAddress(responseJson.results[0].formatted_address);
				meetingSetState({ ...meetingState, ['meeting_location']: responseJson.results[0].formatted_address});
		})
	}, [useLocation]);

	const [isOpen, setisOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	
	const { slug } = useParams();
	let expSlug     = slug.split("-");
	let index       = (expSlug.length)-1;
	let ItemId      = expSlug[index];
	//console.log("slug-data:-"+slug);
	let currentPath = window.location.href;
	//console.log("current-path"+currentPath); // get full path 
	
	let countrydata = (props.countryId!='') ? props.countryId: 0;
	//console.log("country_id:-"+countrydata);
	const [sellerId, setSellerId] = useState([]);
	const [detailItems, setDetailItems] = useState([]);
	const [itemPrice, setItemPrice] = useState([]);  
	const [itemSymbol, setItemSymbol] = useState([]);  
	const [itemCountryId, setItemcountryId] = useState([]);  
	const [itemImage, setItemImage] = useState([]);  
	const [itemImageId, setItemImageId] = useState([]);  
	const [itemDescription, setItemDescription] = useState([]);  
	const [itemTweetDescription, setItemTweetDescription] = useState([]);  
	const [itemName, setItemName] = useState([]);  
	const [categoryName, setCategoryName] = useState([]);  
	const [itemLocation, setItemLocation] = useState([]);  
	const [itemLatitude, setItemLatitude] = useState([]);  
	const [itemLongitude, setItemLongitude] = useState([]);  
	const [itemCreateDate, setCreateDate] = useState([]);  
	const [likeClass, setLkClass]   = useState([]); 
	const [favsClass, setFavClass] = useState([]);  
	const [totalLike, setTotalLike] = useState([]); 
	const [itemRating, setItemRating] = useState(); 
	const [itemReview, setItemReview] = useState([]); 
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/item-details/${loggedInUserId}/${slug}`).then((response)=>{		

			//console.log(response.data[0].user_id);
			if(response.data == 2)
			{
				navigate(`/`);
			}
			else
			{
				// console.log(response.data);
				setDetailItems(response.data);
				setSellerId(response.data[0].user_id);
				setItemPrice(response.data[0].price);
				setItemSymbol(response.data[0].currency_symbol);
				setItemcountryId(response.data[0].item_country_id);
				setItemLocation(response.data[0].location);
				setItemLatitude(response.data[0].latitude);
				setItemLongitude(response.data[0].longitude);
				setLkClass(response.data[0].likeclass);
				setFavClass(response.data[0].favouriteclass);
				setTotalLike(response.data[0].totallike);
				setItemImage(response.data[0].image);
				setItemImageId(response.data[0].id);
				setItemDescription(response.data[0].description);
				setItemTweetDescription(response.data[0].tweetdescription);
				//setItemRating(response.data[0].rating);
				//setItemReview(response.data[0].message);
				setItemName(response.data[0].name);
				setCategoryName(response.data[0].categoryname);
				setCreateDate(dateFormat(response.data[0].create_date,"dd/mm/yyyy h:i:s"));
			}
		});
		});
	}, [slug]);
	
	const itemdata  = [{image: ""}];
	{detailItems.map((val,i) =>
		itemdata[i] = 
		  { image: [process.env.REACT_APP_SERVER_API_URL +`images/items/${val.id}/${val.image}`]
		  }
	)};
	
	//------when any buyer see the item-----/${sellerId}
	
		let ownerid  = sellerId;
		useEffect(()=> {
			if(loggedInUserId!=null){
			if(sellerId!=''){
				axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.post(`/api/item-view-by-buyer/${loggedInUserId}/${ItemId}/${sellerId}`).then((response)=>{		
					localStorage.setItem("view_item_id",response.data.itemId);
					localStorage.setItem("view_seller_id",response.data.sellerId);
				});
				});
			}
			}
		}, [sellerId]);
	
	//-----------------
	//console.log(itemdata);
	const [reportItemPopupShow, setReportItemPopupShow] = useState(false);
	const [reviewPopupShow, setReviewPopupShow] = useState(false);
	
	const [itemReportFormErrors, setItemReportFormErrors] = useState([]);
	
	// --  item report start--------
	let isitemRptSubmit = false;
	const [itemRptState, itemRptSetState] = React.useState({
			item_report: ""
		});
	// const onReportItemModalClose = ()=>{	
	function onReportItemModalClose() {
		itemRptSetState({ ...itemRptState, ['item_report']: ''});
		setReportItemPopupShow(false);
	}	
	const handleItemRptChange = ({ target })=>{
		itemRptSetState({ ...itemRptState, [target.name]: target.value});
	};
	//For Ip address
	const [useIp, setUseIp] = useState();
	useEffect(() => {
		fetch('https://ipapi.co/json/').then((response) => response.json()).then((responseJson) => {
			setUseIp(responseJson.ip);
			itemRptSetState({ ...itemRptState, ['ip_address']: useIp});
		})
	}, [useIp]);	
	//console.log(itemRptState);
	const [isLoadingRpt, setisLoadingRpt] = useState(false);
	const onItemRptSubmit = (e) => {
			e.preventDefault();
			itemRptValidate(itemRptState);
			if(isitemRptSubmit){
				setisLoadingRpt(true);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/itemReport/${loggedInUserId}/${ItemId}`, itemRptState).then(response => {
						if(response.data.status==200){
							setReportItemPopupShow(false);
							toast('Success');
						}else{
							toast('Somethig wrong, Please try again.');
						}
						setisLoadingRpt(false);
					});
				});
	        } 
		};
		const itemRptValidate = (values) => {
			const errors = {};
			
			if (!values.item_report) {
				isitemRptSubmit = false;
				errors.item_report = "Report is required!";
			}else{
				isitemRptSubmit = true;
			}
			
			setItemReportFormErrors(errors);
			return isitemRptSubmit;
		}	
	//---- item report end --------	
	
	//-----  arrange meeting start-----
		useEffect(()=> {
			if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/get-sender-buyer-name/${loggedInUserId}/${ItemId}`).then((response)=>{
				meetingSetState(response.data.meetingdata);
			});
			});
			}
		}, []);
	
	//console.log(meetingState);
	// --  meeting report start--------
	const [meetingFormErrors, setMeetingFormErrors] = useState([]);
	const [meetingPopupShow, setMeetingPopupShow] = useState(false);
	let ismeetingSubmit = false;
	
	const handleMeetingChange = ({ target })=>{
		meetingSetState({ ...meetingState, [target.name]: target.value});
	};
	//console.log(meetingFormErrors);
	
	const handlePlaceChange = (place)=>{
		setUseLocation({ ...useLocation, ['lat']: place.geometry.location.lat(), ['lng']: place.geometry.location.lng()});
	};
	
	const [isLoadingMeeting, setisLoadingMeeting] = useState(false);	
	const onMeetingSubmit = (e) => {
		const mData = new FormData();
		mData.append("meeting_date", meetingState.meeting_date);
		mData.append("meeting_time", meetingState.meeting_time);
		mData.append("seller_name", meetingState.seller_name);
		mData.append("buyer_name", meetingState.buyer_name);
		mData.append("ip_address", useIp);
		//mData.append("meeting_location", useAddress);
		mData.append("meeting_location", meetingState.meeting_location);
		mData.append("latitude", useLocation.lat);
		mData.append("longitude", useLocation.lng);
		
		//console.log(meetingState);
			e.preventDefault();
			meetingValidate(meetingState);
			if(ismeetingSubmit){
				setisLoadingMeeting(true);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/meeting-arrange/${loggedInUserId}/${ItemId}`, mData).then(response => {
						//console.log(response.data);
						if(response.data.status==200){
							//console.log(response);
						const errors = {};
						errors.meetingError = <div className="alert alert-success alert-dismissible fade show" role="alert">
							{response.data.message}
						</div>;
						toast(response.data.message);
						setMeetingFormErrors(errors);
							//setMeetingPopupShow(false);
						}else{
							toast('Meeting not saved, Please try again');
						}
						setisLoadingMeeting(false);
					});
				});
	        }
		};
		const meetingValidate = (values) => {
			const errors = {};
			
			if (!values.meeting_date) {
				ismeetingSubmit = false;
				errors.date = "Date is required!";
			}else if (!values.meeting_time) {
				ismeetingSubmit = false;
				errors.time = "Time is required!";
			}else if (!values.meeting_location) {
				ismeetingSubmit = false;
				errors.meeting_location = "Meeting location is required!";
			}else{
				ismeetingSubmit = true;
			}
			
			setMeetingFormErrors(errors);
			return ismeetingSubmit;
		}
	
	//------ arrange meeting end-----
	//-------- like and fabour--------------
	
	
    const [ownItem, setOwnItem] = useState([]);	
    const [userImage, setUserImage] = useState([]);	
    const [itemUserId, setItemUserId] = useState([]);
	const [followStatus, setFollowStatus] = useState([]);
	const [avgRatingSeller, setAvgRatingSeller] = useState();
	const [avgRatingItem, setAvgRatingItem] = useState([]);
	const [memberSince, setMemberSince] = useState([]);
	const [isReviewed, setIsReviewed] = useState(); 
	const [userRating, setUserRating] = useState();
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/items-like-favourite-class/${loggedInUserId}/${ItemId}`).then((response)=>{		
			setLkClass(response.data.likeClass);
			setFavClass(response.data.favouriteClass);
			setTotalLike(response.data.totallike);
			setOwnItem(response.data.ownItem);
			setUserImage(response.data.followUserDetails);
			setMemberSince(response.data.memberSince);
			setItemUserId(response.data.item_user_id);
			setFollowStatus(response.data.followunfollow);
			setAvgRatingSeller(response.data.avg_rating_seller);
			setAvgRatingItem(response.data.avg_rating_item);
			setIsReviewed(response.data.isReviewed);
			setUserRating(response.data.user_rating);
		    
			//document.body.classList.remove('disable_load_content');
			
		});
		});
	}, [itemRating,props.userContent]);
	
	//console.log(avgRatingSeller+'##'+avgRatingItem);
	//----- after on click------------------
	
	const likeMeClick = value => () => {
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/edit-item-like/${loggedInUserId}/${id}`).then((response)=>{	//console.log(response.data.likeclass);
				setLkClass(response.data.likeclass);
				setTotalLike(response.data.totallike);
			});
		});
	};
	
	const favouriteMeClick = value => () => {
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/edit-item-favourite/${loggedInUserId}/${id}`).then((response)=>{
				setFavClass(response.data.favouriteClass);
			});
		 });
	};
	
	// ----- facebook share--------

	//const faceBookShare= value => () => {
		//let id = value;
		/*axios.get(`/sanctum/csrf-cookie`).then(response => {

	/*const faceBookShare= value => () => {
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {

			axios.get(`/api/follow/${loggedInUserId}/${itemUserId}`).then((response)=>{
				//console.log(response.data.followunfollow);
				setFollowStatus(response.data.followunfollow);
			});

		 });*/
	//};

	//------ item follow--- (check the item for user id has)------
	const followUnfollow = value => () => {
		let id = value;
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/follow/${loggedInUserId}/${itemUserId}`).then((response)=>{
				//console.log(response.data.followunfollow);
				setFollowStatus(response.data.followunfollow);
			});
		 });
	};
	//console.log(followStatus);
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  //-------- social media popup------
	const [socialMediaPopupShow, setSocialMediaPopupShow] = useState(false);
	
	//const emailShareUrl 	= process.env.REACT_APP_FRONT_URL+`item/`+slug;
	const emailShareUrl 	= currentPath;
	//console.log("emailShareUrl:-"+emailShareUrl);
	const emailShareDesc 	= 'Hi...'+`${"\n\n"}`+`${itemDescription}.....`+`${"\n\n"}`;
	
	//console.log(itemDescription.substring(0,180));
	
	const shareUrl  = "http://54.204.141.235/admin/public/images/items/"+`${itemImageId}/${itemImage}`; 
	
	const ShareUrlFb  = process.env.REACT_APP_FRONT_URL+`admin/public/images/items/${itemImageId}/${itemImage}`;
	
	const facebookShareData  = ShareUrlFb+`${"\n"}`+`${itemDescription}`;
	
	
	const imgShareUrlTw  = "https://snapsells.com/admin/public/images/items/"+`${itemImageId}/${itemImage}`;
	const ShareUrlTw  = process.env.REACT_APP_FRONT_URL+`item/${slug}`;
	
	
	let itemDltSocial  = `${itemName}`+' | '+`${itemDescription}`;
	const sharePageUrl  = "http://54.204.141.235/item/Ferrari-id-1";
	let facebook_path = "https://facebook.com/sharer/sharer.php?u="+ `${shareUrl}`;
	
    let imagePath = process.env.REACT_APP_FRONT_URL+`admin/public/images/items/${itemImageId}/${itemImage}`;
    let hashtag  = "#camperstribe";
	
	const shareFb = [
	    {
		url: "https://github.com"
		
		},
	];
	
	let emailSubject = 'image sending';
	let emailShareBody  = 'hello how are you';
	//---- rating and review-------
	const [ratingReview, setRatingReview] = useState([]); 
	const ratingChanged = (newRating) => {
		setRatingReview(newRating);
		 };
	
	const [ratingFormErrors, setRatingFormErrors] = useState([]);
	let isratingSubmit = false;
	const [ratingState, ratingSetState] = React.useState([]);
	const handleRatingChange = ({ target })=>{
			ratingSetState({ ...ratingState, [target.name]: target.value});
			//console.log(ratingState);
		};
	const [isLoadingRat, setisLoadingRat] = useState(false);	
	const onRatingSubmit = (e) => {
			e.preventDefault();
			//console.log("onsubmit "+ratingReview);
			ratingValidate(ratingState);
			if(isratingSubmit){
				setisLoadingRat(true);
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/item-rating-store/${loggedInUserId}/${ItemId}/${ratingReview}`, ratingState).then(response => {
						//console.log(response.data);
						if(response.data.status==200){
							setReviewPopupShow(false);
							setItemRating(response.data.rating);
							//console.log(response);
						const errors = {};
						errors.ratingError = <div className="alert alert-success alert-dismissible fade show" role="alert">
							{response.data.message}
						</div>;
						toast(response.data.message);
						setRatingFormErrors(errors);
							//setMeetingPopupShow(false);
						}else if(response.data.status==400){
							//console.log(response.data.message);
							const errors = {};
							errors.ratingError = <div className="alert alert-warning alert-dismissible fade show" role="alert">
								{response.data.message}
							</div>;
							setRatingFormErrors(errors);
						}else if(response.data.status==600){
							const errors = {};
							errors.date = response.data.errors.ratingmessage;
							setRatingFormErrors(errors);
						}
						//setisLoadingRat(false);
					});
				});
	        } 
		};
		const ratingValidate = (values) => {
			const errors = {};
			
			if (!values.ratingmessage) {
				isratingSubmit = false;
				errors.ratingmessage = "Message is required!";
			}else{
				isratingSubmit = true;
			}
			setRatingFormErrors(errors);
			return isratingSubmit;
		}
	
	const buttonRef = useState();
	const disableButton = () =>{
	  buttonRef.current.disabled = true; // this disables the button
	 }
  //------------count the seconds--------------------
	const [sendNotiData, setSendNotiData] = React.useState({
		login_user: loggedInUserId,
		item_id: ItemId
	});
	
	useEffect(()=>{
		var timesRun = 0;
		var interval = setInterval(function(){
			timesRun += 1;
			if(timesRun === 10){
				//console.log("time_interval:"+timesRun);
				//do whatever here...
				// if(loggedInUserId!=null){
				if(loggedInUserId!=null && loggedInUserId!= sellerId){
				axios.get(`/sanctum/csrf-cookie`).then(response => {
					axios.post(`/api/send-view-item-notification`, sendNotiData).then(response => {
						//console.log(response.data);
					});
				});
				}
				//do whatever here...
				clearInterval(interval);
			}
		}, 1000); 
	}, []);
  //-------------------------------------------------
	const likeClassStyle = {padding: '4px', background:'#fff', borderRadius: '50%', marginRight: '10px', fill:'orange', width:'28px', height:'28px'}
	const unLikeClassStyle = {color: 'orange', padding: '4px', background:'#fff', borderRadius: '50%', marginRight: '10px', width:'28px', height:'28px'}
	const favourClassStyle ={padding: '4px', background:'#fff', borderRadius: '50%', marginRight: '10px', fill:'orange', width:'28px', height:'28px'}
	const unFavourClassStyle = {color: 'orange', padding: '4px', background:'#fff', borderRadius: '50%', marginRight: '10px', width:'28px', height:'28px'}
	//const timeIcon =   {height: '17px', width: '17px', color: '#363d45', fill: '#363d45',marginRight:'5px'}
	
	const [textAreaHeight, setTextAreaHeight] = useState(100);
	const textAreaStyle: CSSProperties = {
		height: textAreaHeight,
	};
	//---tooltip css---
	const LightTooltip = withStyles(theme => ({
	  tooltip: {
		backgroundColor: theme.palette.common.white,
		color: "green",
		boxShadow: theme.shadows[1],
		fontSize: 11
	  }
	}))(Tooltip);

	console.log(userImage,'userImageseller_profile_picture')
	
	
	return <>
	
	
	<Helmet>
	{/*<meta charset="utf-8" />
	<meta http-equiv="X-UA-Compatible" content="IE=edge" />
	<meta name="csrf_token" content="" />
     <meta property="type" content="website" />
     <meta property="url" content={ShareUrlTw} />
     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
     <meta name="msapplication-TileColor" content="#ffffff" />
     <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
     <meta name="theme-color" content="#ffffff" />
     <meta name="_token" content="" />
     <meta name="robots" content="noodp" />
     <meta property="title" content={itemName} />
     <meta property="quote" content={itemName} />
     <meta name="description" content={itemDescription} />
     <meta property="image" content={ShareUrlFb} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={itemName} />
     <meta property="og:quote" content={itemName} />
     <meta property="og:hashtag" content={hashtag} />
     <meta property="og:image" content={ShareUrlFb} />
     <meta content="image/*" property="og:image:type" />
     <meta property="og:url" content={ShareUrlTw} />
     <meta property="og:site_name" content="CampersTribe" />
<meta property="og:description" content={itemDescription} />*/} 
	<meta property="og:title" content={itemName} />
	<meta property="og:url" content={emailShareUrl} />
	<meta property="og:type" content="hello"/>
	<meta property="og:description" content={itemDescription} />
	<meta property="og:image" content={ShareUrlFb}/>
	</Helmet>
	
	{/*<title>{itemName}</title>
      <meta charset="utf-8"/>
	  <meta name="keywords" content={itemDescription}/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content=""/>
      <meta name="robots" content="noodp"/>
      <meta property="title" content={itemName} data-react-helmet="true"/>
      <meta property="quote" content={itemName} data-react-helmet="true"/>
      <meta name="description" content={itemDescription} data-react-helmet="true"/>
      <meta property="image" content={imagePath} data-react-helmet="true"/>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={itemName} />
      <meta property="og:quote" content={itemName} />
	  <meta property="og:hashtag" content={itemDescription} />
      <meta property="og:image" content={imagePath} data-react-helmet="true"/>
      <meta property="og:image:secure_url" content={imagePath} data-react-helmet="true"/>
      <meta content="image/*" property="og:image:type" data-react-helmet="true"/>
      <meta property="og:site_name" content="CampersTribe"/>
	<meta property="og:description" content={itemDescription}/>*/}
	  
	  {/*<meta property="og:image" content={imagePath} /> 
	  <meta property="og:title" content={itemName} />*/} 
	
	{/*<meta name="twitter:card" content="summary_large_image"/>
	  <meta name="twitter:title" content="Parade of Fans for Houston’s Funeral"/>
	  <meta name="twitter:description" content="NEWARK - The guest list and parade of limousines with celebrities emerging from them seemed more suited to a red carpet event in Hollywood or New York than than a gritty stretch of Sussex Avenue near the former site of the James M. Baxter Terrace public housing project here."/>
	<meta name="twitter:image" content={imgShareUrlTw}/>*/}
		<section className="page-title">
			<div className="container">
				<div className="row">
					<h1>Item Details</h1>
					<div className="breadcrumb"><Link to="">{languageContent.home}</Link>/<Link className="ml5" to="">Item </Link>/ Item Details</div>
				</div>
			</div>
		</section>
		<section>
		<ToastContainer />
			<div className="products-section-innerpage item_details_parent">
				<div className="container cDetailsContainer ">
					<div className="row">
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 catDetailsLeft">
							<div className="myCaptionSlider">
								<div style={{ textAlign: "center" }}>
									<div style={{
									padding: "0"
									}}>
									<Carousel
										key={new Date().getTime()}
										data={itemdata}
										time={2000}
										width="850px"
										height="500px"
										captionStyle={captionStyle}
										radius="10px"
										slideNumber={true}
										slideNumberStyle={slideNumberStyle}
										captionPosition="bottom"
										automatic={true}
										dots={true}
										pauseIconColor="white"
										pauseIconSize="40px"
										slideBackgroundColor="darkgrey"
										slideImageFit="cover"
										thumbnails={true}
										thumbnailWidth="100px"
										style={{
											textAlign: "center",
											maxWidth: "850px",
											maxHeight: "500px",
											margin: "40px auto",
										}}
									/>
									</div>
								</div>
							</div>
							{localStorage.getItem("loggedin_user_id") === null ? (
							<>
							<div className="d-flex mobile_detail_img_box">
								<span className="bdrAdjust">
									<HeartOutline onClick={() => setLoginPopupShow(true)}/> 
									<label className="LikeItemText">Like This Item</label>
								</span>
								<div className="middle_logo_div">
									<img src={userImage.seller_profile_picture} alt="Avatar" /> 
								</div>
								<span className="bdrAdjust" onClick={() => setLoginPopupShow(true)}>
									<StarOutline/> 
									<label className="FavItemText">Add As Favourite</label>
								</span>
							</div>
							<div className="text-center mobile_middle_logo_title">
								<h4>{userImage.seller_name}</h4>
								<p>Member Since {memberSince}</p>
							</div>
							<div className="priceShare d-flex justify-content-between mobile_price p-3">
								<p className="text-dark">{itemSymbol}{itemPrice}</p>
								<div className="counter_svg">
									<span className="bdrAdjust">
									<HeartOutline style={unLikeClassStyle} onClick={() => setLoginPopupShow(true)} />
									{totalLike} Likes
									</span>
								</div>
							</div>
							<div className="mobileView mobileDesc">
							{itemDescription}.
							<hr></hr>
							</div>
							<div className="priceShare desktopView">
										<h6>{languageContent.price}</h6>
										<p>{itemSymbol}{itemPrice}</p>
							</div>
							</>
							) : (
							<>
							<div className="d-flex mobile_detail_img_box">							
								<span value={ItemId} onClick={() => likeMeClick(`${ItemId}`)} className="bdrAdjust">
											{likeClass==1 ? <Heart style={likeClassStyle}/> :<HeartOutline style={unLikeClassStyle}/> } 
											{likeClass==1 ? <label className="LikeItemText">Remove From Like</label> :<label className="LikeItemText">Like This Item</label> }
								</span>
								<div className="middle_logo_div">
									<img src={userImage.seller_profile_picture} alt="Avatar" /> 
								</div>
								<span className="bdrAdjust" value={ItemId} onClick={() => favouriteMeClick(`${ItemId}`)}>
										{favsClass==1 ? <Star style={favourClassStyle}/> : <StarOutline style={unFavourClassStyle}/>} 
										{favsClass==1 ? <label className="FavItemTextRem">Remove From Favourite</label> : <label className="FavItemText">Add As Favourite</label>}
								</span>
							</div>
							<div className="text-center mobile_middle_logo_title">
								<h4>{userImage.seller_name}</h4>
								<p>Member Since {memberSince}</p>
							</div>
							<div className="priceShare d-flex justify-content-between mobile_price p-3">
								<p className="text-dark">{itemSymbol}{itemPrice}</p>
								<div className="counter_svg">
									<span value={ItemId} onClick={() => likeMeClick(`${ItemId}`)} className="bdrAdjust">
										{likeClass==1 ? <Heart style={likeClassStyle}/> :<HeartOutline style={unLikeClassStyle}/> }
									</span> {totalLike} Likes
								</div>
							</div>
							<div className="mobileView mobileDesc">
							{itemDescription}.
							<hr></hr>
							</div>				
							</>
							)}
							<div className="locationMobile mobileView">
								<div className="d-flex justify-content-between ">
									<div className="addItemMapPart" >
										<MapContainer useZoom={8} isEditable={0} useLocation={{ lat: itemLatitude, lng: itemLongitude}} chgUseLocation={useLocation => setUseLocation(useLocation)}/>
									</div>
									<div className="mobileShare">
										<span className="shareText">Share with</span>
										<LightTooltip  title="Share With Facebook">
											<FacebookShareButton 
											url={ShareUrlFb}
												content={itemDescription}
											quote={"halo this is my quote"}
											>
											<FacebookIcon size={32} round/>
											</FacebookShareButton>
										</LightTooltip>&nbsp;&nbsp;
										<LightTooltip  title="Share With Email">
											<EmailShareButton
												url={emailShareUrl}
												subject={itemName} 
												body={emailShareDesc} 
												className="socialMediaButton">
												<EmailIcon size={32} round/>
											</EmailShareButton>
										</LightTooltip>								
									</div>
								</div>
								<div className="locationSeperator">{itemLocation}</div>
							</div>
							{ownItem == ''? (
							<div className="mobileView">
								<div className="mobileReport"><TrendingUpSharp /><span onClick={() => setReportItemPopupShow(true)}>Report as inappropriate</span></div>
								<div className="meetingMessage">
									<div className="d-flex">
										<div className="w-50" onClick={() => setMeetingPopupShow(true)}>
											<div className="meetingBtnMobile">
												Arrange Meeting
											</div>
										</div>
										<div className="w-50">
										<Link to={"/chat/"+`${sellerId}`+"/"+`${ItemId}`}>
											<div className="messageBtnMobile">
												Send Message
											</div>
										</Link>
										</div>
									</div>
								</div>
							</div>
							) : ''}
							<div className="mobileView mt-3 itemDatails">
								<div className="d-flex justify-content-between px-2 mb-2">
									<div className="d-flex rating-star-disable">
										<span className="avgStarRating">Avg. Star Rating</span>
										<ReactStars count={5} key={avgRatingItem} value={avgRatingItem} size={14} 
										activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}  disabled="disabled"/>
									</div>
									{ownItem == ''? (
									<div className="switchCheckbox">
										<div className="form-check form-switch mobile_text_center radioSwitchCheck">
											<label className="form-check-label followRadio" for="flexSwitchCheckChecked">Follow / Un-follow</label>
											<input className="form-check-input" type="checkbox" id="is_sponsered" name="is_sponsered" checked={followStatus==='follow' ? '':'checked'} onChange={followUnfollow(`${ItemId}`)}/>
										</div>
									</div>
									) : ''}
								</div>
							</div>
							
							<div className="mobileView mt-3 viewWriteReview">
								<div className="d-flex">
									<div className="w-50 px-2"><Link to={"/reviewitem/"+`${ItemId}`}><button className="btn btn-info btn-block btn-round w-100 px-0 mb-2" id="btn">View All Reviews</button></Link></div>
									<div className="w-50 px-2">
										{localStorage.getItem("loggedin_user_id") === null ? (
											<button className="btn btn-info btn-block btn-round w-100 px-0 mb-2" onClick={() => setLoginPopupShow(true)}>Write a Review</button>
										) : (
											<button className="btn btn-info btn-block btn-round w-100 px-0 mb-2" id="btn" value={ItemId} onClick={() => setReviewPopupShow(true)}>Write a Review</button>
										)}										
									</div>
								</div>
							</div>
							
							{reviewPopupShow ? (
							<Modal className="arrange_meeting_parent" show={reviewPopupShow} onHide={() => setReviewPopupShow(false)}>
								{/* <div className="top_logo_for_mobile text-center">
									<img src={process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`} className="mobile_top_logo" alt="" srcset="" />
								</div> */}
								<Modal.Header closeButton>
									<Modal.Title className="w-100 text-center">Review Item</Modal.Title>
								</Modal.Header>
								<Modal.Body>
								
									{ isReviewed=='0' ? (
										<div className="mt-3 text-center">
										
										<form id="ratingForm" method="post" onSubmit={onRatingSubmit}>
										{ratingFormErrors.ratingError}
											<div className="">
												<div className="mobile_middle_logo_title color000">Give Star Rating</div>
												<div className="text-center d-flex justify-content-center">
												<ReactStars count={5}  onChange={ratingChanged} size={24} 
												activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/>
												</div>
											</div>
											<div className="">
												<div className="text-left">
													<label className="form-label">Message</label>
													<textarea name="ratingmessage" style={textAreaStyle} className="form-control"  onChange={handleRatingChange}></textarea>
												</div>
												<div className="errorMesg">{ratingFormErrors.ratingmessage}</div>
											</div>
											<div className="knowBrand">
												
													<div className="submitBtn w-100">
														<button type="submit" className="btn btn-primary w-100" disabled={isLoadingRat}>
															{isLoadingRat ? <div className="btnloader"></div> :languageContent.submit }
														</button>
													</div>
												
											</div>
										</form>
										</div>
										) : (
										<div className="knowDesc rating-star-disable">
											<h5>You already rated this item</h5>
										  <ReactStars count={5} key={userRating} value={userRating} size={24} 
												activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}  disabled="disabled"/>
											
										</div>
										)
										}
								</Modal.Body>
							</Modal>
							) : (
								null
							)}
							{/* <img src={userImage.seller_profile_picture} alt="Avatar" /> */}
							
							{/* <img src={userImage.seller_profile_picture} alt="Avatar" /> */}
							<div className="detailsCat desktopView">
								<div className="detailsPart">
									<div className="loaclPickup timeIcon"><Location/> {itemLocation}</div>
									<div className="detailsName">Details</div>
								</div>
								<div className="knowBrand">
									<div>Item Name</div>
									<div>{itemName}</div>
								</div>
								<div className="knowBrand">
									<div>Category Name</div>
									<div>{categoryName}</div>
								</div>
								<div className="knowBrand">
									<div>Price</div>
									<div>{itemSymbol}{itemPrice}</div>
									{/*<div>${useGetItemPrice(countrydata,itemPrice,itemCountryId)}</div>*/}
								</div>
								<div className="knowDesc ">
									<h6>{languageContent.description}</h6>
									<div>{itemDescription}.</div>
								</div>
							{ loggedInUserId!=null && loggedInUserId!= sellerId ? (
							<div>
							{ isReviewed=='0' ? (
							<div>
							
							<form id="ratingForm" method="post" onSubmit={onRatingSubmit}>
							
								<div className="knowDesc">
									<h6>{languageContent.review}</h6>
									<div></div>
								</div>
							{ratingFormErrors.ratingError}
								<div className="knowBrand">
									<div>Rating</div>
									<div><ReactStars count={5}  onChange={ratingChanged} size={24} 
									activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/>,</div>
                                </div>
								<div className="knowBrand">
									<div>{languageContent.messages}</div>
									<div><textarea name="ratingmessage" style={textAreaStyle} className="form-control"  onChange={handleRatingChange}></textarea></div>
									<div className="errorMesg">{ratingFormErrors.ratingmessage}</div>
								</div>
								<div className="knowBrand">
									<div>
										<div className="submitBtn">
											<button type="submit" className="btn btn-primary" disabled={isLoadingRat}>
												{isLoadingRat ? <div className="btnloader"></div> :languageContent.submit }
											</button>
										</div>
									</div>
								</div>
							</form>
							</div>
							) : (
                            <div className="knowDesc rating-star-disable">
								<h5>You already rated this item</h5>
							  <ReactStars count={5} key={userRating} value={userRating} size={24} 
									activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}  disabled="disabled"/>
								
                            </div>
							)
                            }
							 </div>): '' }
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-3 col-12 catDetailsRight">
						{localStorage.getItem("loggedin_user_id") === null ? (
						<Loginmodal languageContent={languageContent} loginPopupShow={loginPopupShow} registerShow={registerShow} onLoginPopupCloseModal={() => setLoginPopupShow(false)} onRegisterPopupCloseModal={() => setRegisterShow(false)} chgUserContent={userContent => setUserContent(userContent)}/>
						) : (
							null
						)}
							<div className="cardBdr desktopView">
								<div className="listDetails">
									{localStorage.getItem("loggedin_user_id") === null ? (
									<div className="likeShare">
										<span className="bdrAdjust">
											<HeartOutline onClick={() => setLoginPopupShow(true)}/> {totalLike} Likes
										</span>
										{/*<span className="bdrAdjust">
											<ShareSocialOutline onClick={() => setLoginPopupShow(true)}/>
										</span>*/}
										<span className="bdrAdjust" onClick={() => setLoginPopupShow(true)}>
											<StarOutline/>
										</span>
									</div>
									) : (
									<div className="likeShare">
										<span value={ItemId} onClick={() => likeMeClick(`${ItemId}`)} className="bdrAdjust">
											{likeClass==1 ? <Heart style={likeClassStyle}/> :<HeartOutline style={unLikeClassStyle}/> } {totalLike} Likes
										</span>
										{/*<span className="bdrAdjust"><ShareSocialOutline onClick={() => setSocialMediaPopupShow(true)}/></span>*/}
										<span className="bdrAdjust" value={ItemId} onClick={() => favouriteMeClick(`${ItemId}`)}>
										{favsClass==1 ? <Star style={favourClassStyle}/> : <StarOutline style={unFavourClassStyle}/>}</span>
									</div>
									)}
									<div className="priceShare">
										<h6>{languageContent.price}</h6>
										<p>{itemSymbol}{itemPrice}</p>
									</div>
									
									{/*<a title="send to Facebook" 
									  href="http://www.facebook.com/sharer.php?s="+`{"100"}`+"&p[title]="+`${itemName}`+"&p[summary]="+itemDescription+"&p[url]="+`${emailShareUrl}`+"&p[images][0]="+`${ShareUrlFb}`
									  target="_blank">
									  <span>
										<img width="14" height="14" src="'icons/fb.gif" alt="Facebook" /> Facebook 
									  </span>
									</a>*/}
									
									
									<LightTooltip  title="Share With Facebook">
									<FacebookShareButton 
									url={ShareUrlFb}
										content={itemDescription}
									quote={"halo this is my quote"}
									>
									<FacebookIcon size={32} round/>
									</FacebookShareButton></LightTooltip>&nbsp;&nbsp;
									<LightTooltip  title="Share With Email">
									<EmailShareButton
										url={emailShareUrl}
										subject={itemName} 
										body={emailShareDesc} 
										className="socialMediaButton">
										<EmailIcon size={32} round/>
									</EmailShareButton></LightTooltip>
								</div>
								<div className="timeDetails">
									<div className="timeIcon"><Location/><small>{itemLocation}</small></div>
									<div className="timeIcon"><TimeOutline/><small>{itemCreateDate}</small></div>
									{/*<div><TimeOutline className="timeIcon" /><small>02/02/2022 at 12:00 PM</small></div>*/}
								</div>
							</div>
							{ownItem == ''? (
							<div className="seller desktopView">
								<h5>Seller Information</h5>
								<div className="media">
									<div className="media_img">
										<img src={userImage.seller_profile_picture || process.env.REACT_APP_FRONT_URL + `images/smile.png`} alt="Avatar" />
									</div>
									<div className="media_body rating-star-disable">
										<h6>{userImage.seller_name}</h6>
										     <ReactStars className="" key={avgRatingSeller} count={5} value={avgRatingSeller} size={24} 
									activeColor="#3498db" isHalf={true} emptyIcon={<i className="far fa-star"></i>} halfIcon={<i className="fa fa-star-half-alt"></i>} fullIcon={<i className="fa fa-star"></i>}/>
											{/*<p><Star /><Star /><Star /><Star /><Star /></p>*/}
										<p>Member Since {memberSince}</p>
									</div>
								</div>
								<div className="mesgBtn">
								{followStatus == "follow" ? (
									<div><button className="btn btn-info btn-block btn-round w-100 mb-2" id="btn" value={ItemId} onClick={followUnfollow(`${ItemId}`)}>Follow</button></div>
								) : (
									<div><button className="btn btn-info btn-block btn-round w-100 mb-2" id="btn" value={ItemId} onClick={followUnfollow(`${ItemId}`)}>Unfollow</button></div>
									
								)}
									<div><button className="btn btn-info btn-block btn-round w-100 mb-2" onClick={() => setReportItemPopupShow(true)}>Report Item</button></div>
									{reportItemPopupShow ? (
									<Modal show={reportItemPopupShow} onHide={e => onReportItemModalClose(e)} className="item_report_parent">
											{/* <div className="top_logo_for_mobile text-center">
												<img src={process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`} className="mobile_top_logo" alt="" srcset="" />
											</div> */}
											<Modal.Header closeButton>
												<Modal.Title className="w-100 text-center">Item Report</Modal.Title>
											</Modal.Header>
											<Modal.Body>
											{itemReportFormErrors.itemReportError}
												<form id="itemRptForm" method="post" onSubmit={onItemRptSubmit}>
													
													<div className="form-group">
															
															<select className="form-control custom_country_select" id="item_report" value={itemRptState.item_report} name="item_report" onChange={handleItemRptChange}>
															
																<option value=''>-Select Report For Reason-</option>													
																<option value="1">Product Already Sold</option>
																<option value="2">Seller Not Responding</option>
																<option value="3">Ad is Dublicate</option>
																<option value="4">Offensive Content</option>
																<option value="5">Block Seller</option>
																
															</select>
															<div className="errorMesg">{itemReportFormErrors.item_report}</div>
													</div>
													
													
													<div className="btn btn-info btn-block btn-round submit mobile_login_btn_div">
													   <button type="submit" className="btn btn-primary mobile_login_btn" disabled={isLoadingRpt}>
														{isLoadingRpt ? <div className="btnloader"></div> : "Submit"}
													   </button>
													</div>
												</form>
											</Modal.Body>
									</Modal>
									) : (
										null
									)}
									<div>
									{localStorage.getItem("loggedin_user_id") === null ? (
										<button className="btn btn-info btn-block btn-round w-100" onClick={() => setLoginPopupShow(true)}>Send Message</button>
									) : (
										<Link to={"/chat/"+`${sellerId}`+"/"+`${ItemId}`} className="btn btn-info btn-block btn-round w-100">Send Message</Link>
									)}	
									</div>
									<div><button className="btn btn-secondary btn-block btn-round w-100" onClick={() => setMeetingPopupShow(true)}>Arrange Meeting</button></div>

										{meetingPopupShow ? (
										<Modal className="arrange_meeting_parent" show={meetingPopupShow} onHide={() => setMeetingPopupShow(false)}>
										<div className="top_logo_for_mobile text-center">
													<img src={process.env.REACT_APP_FRONT_URL + `images/SnapSells.png`} className="mobile_top_logo" alt="" srcset="" />
											</div>
											<Modal.Header closeButton>
												<Modal.Title className="w-100 text-center">Arrange Meeting</Modal.Title>
											</Modal.Header>
											<Modal.Body>
											{meetingFormErrors.meetingError}
												<form id="meetingArgForm" method="post" onSubmit={onMeetingSubmit}>
													<div className="row">
														<div className="col-md-6 col-6">
															<div className="form-group">
																<label>Date</label>
																<input type="date" name="meeting_date" id="meeting_date" DatePicker className="form-control mobile_input" placeholderText="Select a Date" onChange={handleMeetingChange}/>
																<div className="errorMesg">{meetingFormErrors.date}</div>
															</div>
														</div>
														<div className="col-md-6 col-6">
															<div className="form-group">
																<label>Time</label>
																<input type="time" className="form-control mobile_input" name="meeting_time"  id="meeting_time" placeholder="Time" onChange={handleMeetingChange}/>
																<div className="errorMesg">{meetingFormErrors.time}</div>
															</div>
														</div>
													</div>
													<div className="form-group mt-2">
														<label>Seller Name</label>
														<input type="text" className="form-control mobile_input" name="seller_name" value={meetingState.seller_name}  id="seller_name" placeholder="Seller Name" disabled/>
													</div>
													<div className="form-group mt-2">
														<label>Buyer Name</label>
														<input type="text" className="form-control mobile_input mobile_input" name="buyer_name"  id="buyer_name"  value={meetingState.buyer_name}placeholder="Buyer Name" disabled/>
													</div>
													<div className="form-group mt-2">
														<label>Meeting Location</label>
														{useAddress ?(
														<>
														{/*<input type="hidden" className="form-control" placeholder="User location" name="meeting_location" onChange={handleMeetingChange} id="meeting_location"/>*/}
																						<Autocomplete
																						  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
																						  onPlaceSelected={handlePlaceChange}
																						  className="form-control a mobile_input"
																						  options={{
																							types: ["geocode", "establishment"],
																						  }}
																								  defaultValue=""
																						/>
															</>
															
														) : (
														<>
																						<Autocomplete
																						  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
																						  onPlaceSelected={handlePlaceChange}
																						  className="form-control b"
																						  defaultValue=""
																						/>
															{useAddress ?(
																<input type="hidden" onChange={handleMeetingChange} name="meeting_location" className="form-control" placeholder="User location" value={useAddress} disabled/>
															) : (
																<input type="hidden" onChange={handleMeetingChange} name="meeting_location" className="form-control" placeholder="User location" value="" disabled/>
															)}
															</>
														)}
														<div className="errorMesg">{meetingFormErrors.meeting_location}</div>
													</div>
													<div className="form-group mt-2">
													   <input type="text" onChange={handleMeetingChange} name="meeting_location" className="form-control" placeholder="User location" value={useAddress} disabled/>
													</div>
													<div className="addItemMapPart" >
														<MapContainer useZoom={10} useLocation={useLocation} chgUseLocation={useLocation => setUseLocation(useLocation)}/>
													</div>
													{Object.keys(meetingFormErrors).length <= 0 ? (
														null
													) : (
														<div className="errorMesg">Please fill all required input.</div>
													)}
													<div className="submitBtn"><button type="submit" className="btn mobile_login_btn w-100" disabled={isLoadingMeeting}>{isLoadingMeeting ? <div className="btnloader"></div> : "Submit"}</button></div>
												</form>
											</Modal.Body>
										</Modal>
										) : (
											null
										)}
										{/*---- social media pop up start----  */}
											{socialMediaPopupShow ? (
											<Modal show={socialMediaPopupShow} onHide={() => setSocialMediaPopupShow(false)}>
												<div className="knowBrand">
												<div>
												{/*<ShareButton {...shareButtonProps}>
												  <FaFacebook />
												</ShareButton>*/}
												{/*<Popper className="socialMediaPopper" open={true} transition>*/}
												<FacebookShareButton url={ShareUrlFb} quote={itemDescription} 
									 className="socialMediaButton">
													{/*<FacebookShareButton
													url={shareUrlFb}
													quote={"test sharing"}
													description ={"test gffgfgf for the long description"}
													image={shareUrl}
													imageURL={shareUrl}
													text={"short description"}
													longtext ={"test for the long description"}
													>*/}
													<FacebookIcon size={40} round/>
												</FacebookShareButton>
												{/*</Popper>*/}
												&nbsp;
												{/*<InstagramShareButton url={shareUrl} title={itemName}>
													<InstagramIcon url={shareUrl} size={20} round/>
												</InstagramShareButton>*/}
												{/*<div className="App">
												<Stories
													stories={story}
													defaultInterval={1500}
													width={432}
													height={768}
												/>
												</div>*/}
												&nbsp;
												
												<TwitterShareButton   url={ShareUrlTw} title={itemName +' :  '+itemTweetDescription} imageURL={imgShareUrlTw}>
													<TwitterIcon size={40} round/>
														{/*<a className="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></a>*/}
												</TwitterShareButton>
												 
												{/*<Instagram className="shares-instagram"/>*/}
													{/*<WhatsappShareButton url={ShareUrlTw} title={itemName} description={itemDescription}>
													 <WhatsappIcon size={40} round/>
															</WhatsappShareButton>*/}
												<InstapaperShareButton url={shareUrl}>
													 <InstapaperIcon size={40} round/>
												</InstapaperShareButton>
												
												</div>
												</div>
											</Modal>
											) : (
												null
											)}
										{/*----social media end--*/}
									</div>
							</div>
							) : ''}
							<div className="locationOn desktopView">
								<h5>Location</h5>
								<div className="mapPart">
									<div className="addItemMapPart" >
										<MapContainer useZoom={8} isEditable={0} useLocation={{ lat: itemLatitude, lng: itemLongitude}} chgUseLocation={useLocation => setUseLocation(useLocation)}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
	</>;
	
}

export default Itemdetails;
